import React from 'react';




export default class UniformImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    };
  }

  getAspectRatio = (width, height) => {
    const ratio = (width / height).toFixed(2); // Get a decimal value

    if (ratio === "1.00") return "Square (1:1)";
    if (ratio > 1 && ratio < 1.4) return "Standard Landscape (4:3)";
    if (ratio > 1.4 && ratio < 1.8) return "DSLR Landscape (3:2)";
    if (ratio >= 1.8 && ratio <= 2.2) return "Widescreen (16:9)";
    if (ratio > 2.2) return "Ultrawide (21:9)";
    if (ratio < 1 && ratio > 0.7) return "Standard Portrait (2:3)";
    if (ratio <= 0.7 && ratio > 0.5) return "Vertical Video (9:16)";
    if (ratio <= 0.5) return "Extreme Portrait";

    return "Unknown Ratio";
  }

  handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;

    if (naturalWidth > naturalHeight) {
      this.setState({ width: "auto", height: parseInt(this.props.standardSize) });
    } else if (naturalHeight > naturalWidth) {
        this.setState({ width: parseInt(this.props.standardSize), height: "auto" });
    } else {
      // Square image: Set width (or height) to standard
      this.setState({ width: parseInt(this.props.standardSize)*2, height: "auto" });
    }
  };


  render() {


      return (
        <img
          src={this.props.src}
          alt={this.props.alt}
          onLoad={this.handleImageLoad}
          style={{ width: this.state.width, height: this.state.height, objectFit: "contain" }}
        />

      )
    }



}
