import React from 'react';

import {
  Card,
  Button,
  Row, Col,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import styled from 'styled-components'
import { useTable } from "react-table";
import Select from 'react-select';


import {
  MobileHeader,
  ContentWrapper
} from 'common/AdminComponents.js';

import {
  get_mc_user_list,
  add_remove_support_member,
  get_league_for_manage,
  update_league_for_manage,
} from 'utils/api.js';


import 'common/McModal.css';
import CsvDownloader from 'react-csv-downloader';
import FontAwesome from 'react-fontawesome';


const Styles = styled.div`

  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 0px solid black;
  }
  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :nth-child(even) {
          background-color: #f6f6f6;
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 0px solid black;
      border-right: 0px solid black;
      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }

`

export default class Support extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error:'',
      loading: false,
      fName:'',
      lName:'',
      email:'',
      leagueName:'',
      supportInd: '',
      userList: null,
      userListCols: [],
      leagueDetails: {},
      showLeagueDetails: false,
      leagueid: '',
      clubStatusChangeModal: false,
    };


  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {


  }

  getMCUserList = () => {
    this.setState({
      loading: true,
      error:'',
      supportInd: '',
    });
    get_mc_user_list(this.state.email, this.state.fName, this.state.lName, this.state.leagueName, '',
    ({success, userList, error}) => {
      if (!success){
        this.setState({
          loading:false,
          error
        });
        return;
      }
      // build result columns
      this.handleSearchResults(userList);

    }, (error) => {
      console.log(error);
    });
  }

  handleSearchResults = (userList) => {
    let userListCols = [];
    if (userList && userList.length >0){
      let cols = Object.getOwnPropertyNames(userList[0]);
      userListCols = cols.filter(a => ['user_id','usr_support_ind','usr_commissioner_ind'].indexOf(a) < 0).map(a => {

        if (a === "league_name") {
          return {
            'id': 'league_name',
            'Header': a,
            'accessor': d => <a href={"/commissioner/"+d.league_id}  >{d.league_name}</a>,
            'className': '',

          }
        }
        if (a === "league_id") {
          return {
            'id': 'league_id',
            'Header': "Action",
            'accessor': d => {
              let action = '';
              if (!d.league_id){
                action =  <div className="" > - </div>
              }else if (d.league_status === 'inactive'){
                action =  <div className="" >Inactive League</div>
              }else if (d.usr_commissioner_ind === null){
                action =  <div className="renderComponent" onClick={()=>{this.joinQuitLeague(d.league_id, d.user_id, 'A')}} >Join League</div>
              }else if (d.usr_support_ind){
                action =  <div className="renderComponent" style={{color: 'red'}} onClick={()=>{this.joinQuitLeague(d.league_id, d.user_id, 'D')}} >Quit League </div>
              } else if (d.usr_commissioner_ind){
                action =  <div className="" >Already Commissioner</div>
              }else if (!d.usr_commissioner_ind){
                action =  <div className="renderComponent" onClick={()=>{this.joinQuitLeague(d.league_id, d.user_id, 'A')}} >Set Commissioner</div>
              }
              return <div>
                <div className="renderComponent"  onClick={()=>{this.getLeagueDetails(d.league_id)}} > Manage </div>
                {action}
              </div>

            },
            'className': 'text-nowrap',

          }
        }

        //Boolean handling
        if (typeof userList[0][a] == "boolean") {
          return {
            'id': a,
            'Header': a,
            'accessor': a,
            'className': '',
            Cell: ({ cell: { value } }) => value ? "Yes" : "No"
          }
        }


        let dateformat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
        let className = '';
        //alert(reportResult[0][a]);
        if(userList[0][a] && userList[0][a].toString().match(dateformat)){
          className = 'text-nowrap'
        }
        return {
          'id': a,
          'Header': a,
          'accessor': a,
          'className': className,
        }
      });
    }

    this.setState({
      loading: false,
      userListCols,
      userList,
    });
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  joinQuitLeague = (league_id, user_id, action) => {
    this.setState({
      loading: true,
      success: null,
      message: null,
    });
    add_remove_support_member(league_id, user_id, action,
    ({success, message}) => {
      this.setState({
        loading: false,
        success,
        message,
      });
      if (success && this.state.supportInd === ''){
        this.getMCUserList();
      }else if (success && this.state.supportInd === 'true'){
        this.getQuitLeagues();
      }
    }, (error) => {
      console.log(error);
      this.setState({
        loading: false,
        message:error,
      });
    });
  }

  resetSearchForm = () =>{
    this.setState({
      error:'',
      loading: false,
      fName:'',
      lName:'',
      email:'',
      leagueName:'',
      supportInd:'',
      message:'',
      success: null,
      leagueid: '',
    });
  }

  getQuitLeagues = () => {
    this.setState({
      error:'',
      supportInd: 'true',
    });
    get_mc_user_list('','','','', 'true',
    ({success, userList, error}) => {
      if (!success){
        this.setState({
          loading:false,
          error
        });
        return;
      }
      // build result columns
      this.handleSearchResults(userList);

    }, (error) => {
      console.log(error);
    });
  }

  backToSearchResult = () =>{
    this.setState({showLeagueDetails:false});
  }
  getLeagueDetails = (league_id) => {
    this.setState({
      leagueid: league_id,
      error:'',
      showLeagueDetails: true
    });
    get_league_for_manage(league_id,
    ({success, leagueDetails, error}) => {
      if (!success){
        this.setState({
          loading:false,
          error
        });
        return;
      }
      this.setState({leagueDetails});

    }, (error) => {
      console.log(error);
    });
  }


  addTransaction = ()=>{
    
    this.updateLeagueDetails(this.state.leagueid, 'clubStatusChangeAmount',
      this.state.leagueDetails.clubStatusChangeAmount, this.state.leagueDetails.clubStatusChangeDetails);
  }

  setLeagueProps = (event) =>{

    let leagueDetails = this.state.leagueDetails;
    leagueDetails[event.target.name] =  event.target.value;
    if (event.target.name === 'clubStatus'){
      leagueDetails['clubStatusChangeAmount'] = 0;
      leagueDetails['clubStatusChangeDetails'] = ''
      this.toggleClubStatusChangeModal();
      //return;
    }
    this.setState({leagueDetails});
    if (['leagueCreditAdjust','clubStatusChangeAmount','clubStatusChangeDetails'].indexOf(event.target.name) <0){
      this.updateLeagueDetails(this.state.leagueid, event.target.name, event.target.value);
    }
  }

  setLeagueProperties = (name, value) =>{
    let leagueDetails = this.state.leagueDetails;
    leagueDetails[name] =  value;
    this.setState({leagueDetails});
    this.updateLeagueDetails(this.state.leagueid, name, value);
  }

  updateLeagueDetails = (league_id, fld, fladVal, details) => {
    update_league_for_manage(league_id, fld, fladVal, details,
    ({success, leagueDetails, error}) => {
      if (!success){
        this.setState({
          loading:false,
          error
        });
        return;
      }
      if (fld === 'clubStatusChangeAmount'){
        this.setState({clubStatusChangeModal: false});
      }
      this.getLeagueDetails(league_id);

    }, (error) => {
      console.log(error);
    });
  }

  toggleClubStatusChangeModal = ()=>{
    this.setState({clubStatusChangeModal: !this.state.clubStatusChangeModal});
  }

  render() {
    /*this.state.userListCols.push(
    {
      id: 'league',
      Header: "Action",
      accessor: "league_id",
      Cell: ActionJoinLeagueCell,
      className: 'text-nowrap'
    });*/

    return (
      <div>
        <MobileHeader to="Support"/>
        <ContentWrapper>
          <Card className="pb-4">
            <Title />
            {!this.state.showLeagueDetails &&
            <div>
              <div>
                <SearchForm {...this.state} handleChange={this.handleChange}
                  getMCUserList={this.getMCUserList} resetSearchForm ={this.resetSearchForm}
                  getQuitLeagues={this.getQuitLeagues}/>
              </div>
              {(this.state.success && this.state.message) &&
                <div className="text-danger font-weight-bold text-center my-2">
                  <span >{this.state.message}</span>
                </div>
              }
              {(!this.state.success && this.state.message) &&
                <div className="text-danger font-weight-bold text-center my-2">
                  <span >{this.state.message}</span>
                </div>
              }
              {(this.state.userList && this.state.userList.length > 0) &&
              <div>
                <ReactCustomTable columns={this.state.userListCols} data={this.state.userList} joinLeague={this.joinLeague}/>
              </div>
              }
              {(this.state.userList && this.state.userList.length === 0) &&
                <div className="text-danger font-weight-bold text-center my-2">
                  <span >{'No records found'}</span>
                </div>
              }
            </div>
            }
            {this.state.showLeagueDetails &&
              <LeagueManageForm {...this.state.leagueDetails} backToSearchResult={this.backToSearchResult}
              setLeagueProps={this.setLeagueProps} setLeagueProperties={this.setLeagueProperties}
              updateLeagueDetails={this.updateLeagueDetails}/>
            }
            <Modal isOpen={this.state.clubStatusChangeModal}  autoFocus={true} className="" style={{minWidth:'750px'}}>
              <ModalHeader toggle={this.toggleClubStatusChangeModal}>Club</ModalHeader>
              <ModalBody>
                <Row className="my-3">
                  <Col  className="mx-3">
                    <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
                      Amount
                    </Row>
                    <Row>
                      <Input type="number"  value={this.state.leagueDetails.clubStatusChangeAmount} name="clubStatusChangeAmount"
                        onChange={(x)=>{ this.setLeagueProps(x) }}>

                      </Input>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="mx-3">
                    <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
                      Details
                    </Row>
                    <Row>
                      <Input type="textarea"  value={this.state.leagueDetails.clubStatusChangeDetails} name="clubStatusChangeDetails"
                        onChange={(x)=>{ this.setLeagueProps(x) }}>

                      </Input>
                    </Row>
                  </Col>
                </Row>


              </ModalBody>
              <ModalFooter>
                  <div className="pb-2 d-flex">
                    {this.state.loading ?
                      <Button color="primary" disabled block><FontAwesome name="spinner" spin /></Button>
                      :<Button className="nextButton" color="primary" size="md" onClick={this.addTransaction} block> {'Add Transaction'} </Button>
                    }
                    <Button className="nextButton ml-3" color="primary" size="md" onClick={this.toggleClubStatusChangeModal} outline> {'Cancel'} </Button>

                  </div>
              </ModalFooter>
            </Modal>
            </Card>
        </ContentWrapper>
      </div>
    );
  }


}

const Title = (props) =>
  <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>Support</h2>

const SearchForm = (props) => {

  return (
    <div className="ml-3">
        <Row className="mb-3">
          <Col xs="10" md="5" className="mx-3">
            <Row className="contentTitle pb-2">
              First Name
            </Row>
            <Row>
              <Input placeholder="User First name" value={props.fName}
                name="fName" onChange={props.handleChange} maxLength="30"/>
            </Row>
          </Col>
          <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">
            <Row className="contentTitle pb-2">
              Last Name
            </Row>
            <Row>
              <Input placeholder="User Last name" value={props.lName}
                name="lName" onChange={props.handleChange} maxLength="30"/>
            </Row>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs="10" md="5" className="mx-3">
            <Row className="contentTitle pb-2">
              Email
            </Row>
            <Row>
              <Input placeholder="User Email" value={props.email}
                name="email" onChange={props.handleChange} />
            </Row>
          </Col>
          <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">
            <Row className="contentTitle pb-2">
              League Name
            </Row>
            <Row>
              <Input placeholder="League Name" value={props.leagueName}
                name="leagueName" onChange={props.handleChange} />
            </Row>
          </Col>
        </Row>


        <Row className="mb-2">
          <Col xs="10" md="6" className="mx-0">
            <div className="pb-2 text-secondary" style={{fontSize: '0.8rem'}}>
              {'Wildcard search with "*" before and/or after search criteria'}
            </div>
          </Col>
        </Row>
        {props.error &&
          <Row className="mb-3">
            <Col xs="10" md="6" className="mx-3">
              <Row className="pb-2" style={{color:'red'}}>
                {props.error}
              </Row>
            </Col>
          </Row>
        }

        <Row className="mb-3">
          <Col xs="4" md="2" className="mx-0">
            <div className="pb-2 d-flex">
              {props.loading ?
                <Button color="primary" disabled block><FontAwesome name="spinner" spin /></Button>
                :<Button className="nextButton" color="primary" size="md" onClick={props.getMCUserList} block> {'Search'} </Button>
              }
              <Button className="nextButton ml-3" color="primary" size="md" onClick={props.resetSearchForm} outline> {'Reset'} </Button>

            </div>
          </Col>
          <Col className="mx-0">
            <div className="pb-2 d-flex justify-content-end mr-3">
              <Button className="nextButton ml-3" color="success" size="md" onClick={props.getQuitLeagues} > {'Quit Leagues'} </Button>
            </div>
          </Col>
        </Row>

    </div>
  )
}

const LeagueManageForm = (props) => {
  const clubStatusOptions = ['charity','expired','new','paid','perpetual','promotional','trial','unlimited'].map(c => (
    <option value={c}>{c.toUpperCase()}</option>
  ));

  let ledgerCols = [];

  let cols = [{col: 'transDate',nm:'Transaction Date'},{col: 'amount',nm:'Amount'},
              {col: 'eventName',nm:'Event'},{col: 'userName',nm:'User'},{col: 'userEmail',nm:'User Email'},
              {col: 'mcAdminAdjust',nm:'mcAdminAdjust',hide: true}]
  ledgerCols = cols.map(a => {
    let className = 'text-nowrap';
    if (a.col === 'amount'){
      return {
        'id': a.col,
        'Header': a.nm,
        'accessor': d => <div className="" style={{width:'100px', textAlign:'end'}}>$ {d.amount}</div>,
        'className': className,
      }
    }
    return {
      'id': a.col,
      'Header': a.nm,
      'accessor': a.col,
      'className': className,
       isVisible: (a.hide ? false: true),
    }
  });

  const leagueOwnerOptions = props.members ? props.members.map((m)=>{
    return {value:m.userId, label:m.name + ' (' + m.email + ')' + (m.owner?' (O) ':'') + (!m.owner && m.commissioner?' (C) ':'')}
  }) : [];
  let leagueOwner = leagueOwnerOptions.filter(x => x.value === props.leagueOwner);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%', // Sets the container to full width
    }),
    control: (provided) => ({
      ...provided,
      width: '100%', // Sets the dropdown to full width
    }),
  };


  return (
    <div className="ml-3">
      <Row noGutters className="pt-0 d-flex justify-content-center ml-2">
        <Col className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <span>
              <h3 style={{fontWeight:"bolder"}}>{props.leagueName}</h3>

              <div style={{color:"#aaa", fontWeight:"200", fontSize: "0.9rem"}}>
                {'Commissioner ' + props.commissionerName}
              </div>
              <a href={"mailto:"+props.leagueOwnerEmail}>
                <span style={{whiteSpace: "nowrap", fontSize: "0.85rem"}}>
                  <FontAwesome name="envelope" className="mr-2" />
                  <span>{props.commissionerEmail}</span>
                </span>
              </a>
            </span>

          </div>
          <div className="d-flex justify-content-end mr-3">
              <Button outline size="sm" color="primary" className="my-2" onClick={props.backToSearchResult} style={{height:'40px'}}>
              Back
              </Button>
          </div>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs="10" md="5" className="mx-3">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            League Status
          </Row>
          <Row>
            <Input type="select"  value={props.leagueStatus} name="leagueStatus" onChange={(x)=>{ props.setLeagueProps(x) }}>
              <option value={'ACTIVE'}>{'ACTIVE'}</option>
              <option value={'INACTIVE'}>{'INACTIVE'}</option>
            </Input>
          </Row>
        </Col>
        <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            Banner Ads
          </Row>
          <Row>
            <Input type="select"  value={props.leagueBannerType} name="leagueBannerType"
              onChange={(x)=>{ props.setLeagueProps(x) }}>
              <option value={'ALL_ADS'}>{'ALL_ADS'}</option>
              <option value={'LEAGUE_ADS'}>{'LEAGUE_ADS'}</option>
              <option value={'GLOBAL_ADS'}>{'GLOBAL_ADS'}</option>
              <option value={'NO_ADS'}>{'NO_ADS'}</option>
            </Input>
          </Row>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs="10" md="5" className="mx-3">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            League Owner
          </Row>
          <Row>
            <Select className="no-border my-0 " isClearable isSearchable options={leagueOwnerOptions}
              styles={customStyles}
              value={leagueOwner}
              placeholder = {"League Owner"}
              onChange={(selectedOption) => {
                  console.log(selectedOption);
                  if (selectedOption && selectedOption.value && selectedOption.value !== props.leagueOwner){
                    props.setLeagueProperties('leagueOwner', selectedOption.value);
                  }

                }
            } />
          </Row>
        </Col>
        <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">

        </Col>
      </Row>
      {props.club &&
      <>
      <Row className="mt-4">
        <Col className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <span>
              <h3 style={{fontWeight:"bolder"}}>Club - {props.club}</h3>
            </span>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="10" md="5" className="mx-3">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            Golf Club
          </Row>
          <Row>
            <Input type="select"  value={props.golfClub} name="golfClub" onChange={(x)=>{ props.setLeagueProps(x) }}>
              <option value={'TRUE'}>{'TRUE'}</option>
              <option value={'FALSE'}>{'FALSE'}</option>
            </Input>
          </Row>
        </Col>
        <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            Status
          </Row>
          <Row>
            <Input type="select"  value={props.clubStatus} name="clubStatus" onChange={(x)=>{ props.setLeagueProps(x) }}>
              {clubStatusOptions}
            </Input>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="10" md="5" className="mx-3">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            Cost
          </Row>
          <Row>
            <Input placeholder="Cost" value={props.clubCost} type="number"
              name="clubCost" onChange={props.setLeagueProps}/>
          </Row>
        </Col>
        <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            Discount
          </Row>
          <Row>
            <Input placeholder="Discount" value={props.clubDiscount} type="number"
              name="clubDiscount" onChange={props.setLeagueProps} />
          </Row>
        </Col>
      </Row>
      </>
      }
      {!props.club &&
      <>
      <Row className="my-2">
        <Col xs="10" md="5" className="mx-3">
          <Row className="contentTitle pb-2" style={{fontSize: '1em'}}>
            Credit Balance
          </Row>
          <Row>
            <Col xs="3">
              <div className="text-nowrap mt-2">
              $ {props.leagueCreditBalance}
              </div>
            </Col>
            <Col xs="6">
              <div className="d-flex">
                <Input  value={props.leagueCreditAdjust} type="number" name="leagueCreditAdjust" onChange={(x)=>{ props.setLeagueProps(x) }}>
                </Input>
                <Button className="nextButton ml-2" color="primary" size="sm" onClick={()=>{props.updateLeagueDetails(props.leagueid,'leagueCreditAdjust',props.leagueCreditAdjust) }} block> {'Adjust'} </Button>
              </div>
            </Col>
            <Col xs="3">
            </Col>
          </Row>
        </Col>
        <Col xs="10" md="5" className="mx-3 mt-3 mt-md-0">

        </Col>
      </Row>
      {(props.leagueLedger && props.leagueLedger.length > 0) &&
      <div>
        <ReactCustomTable columns={ledgerCols} data={props.leagueLedger}/>
      </div>
      }
      </>
      }







    </div>
  )
}

const ActionJoinLeagueCell = ({
    value: initialValue,
    row: { index },
    column: { league_id },
    joinLeague, // This is a custom function that we supplied to our table instance
  }) => {

    const [value, setValue] = React.useState(initialValue)

    const onClick = e => {
      joinLeague(initialValue, index);
    }

    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    if (!value){return '';}
    return <div className="renderComponent" value={value} onClick={onClick} >Join League </div>;

  }


const ReactCustomTable = ({ columns, data, joinLeague }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
      joinLeague,
      initialState: {
        hiddenColumns: columns
          .filter(column => column.isVisible === false)
          .map(column => column.accessor), // Hides columns where isVisible is false
      },
    },

    );

    return (
      <Styles>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead style={{backgroundColor: "gray", "color":"white", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ "fontSize": "0.7rem",}}>
            {rows.map((row, i) => {
              prepareRow(row);
              // Apply background color conditionally based on a value in the row
              const backgroundColor = row.values.mcAdminAdjust === true ? 'antiquewhite' : '';

              return (
                <tr {...row.getRowProps({
                  style: { backgroundColor }
                })}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.className ? cell.column.className : cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      </div>
      <div className="pagination">

      </div>
    </Styles>
    );
  };
