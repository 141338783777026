import React from 'react';

import {
  Button, ButtonGroup,
  Card,
  Table,
  Modal, ModalHeader, ModalFooter,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col, Input
} from 'reactstrap';

import {
  MobileHeader,
  ContentWrapper
} from 'common/AdminComponents.js';

import { CURRENT_SEASON } from 'utils/constants';

import GolferModal from 'common/GolferModal.js';
import AddTournamentsModal from 'pages/admin/AddTournamentsModal.js'
import FontAwesome from 'react-fontawesome';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import {
  get_all_events,
  get_sr_tournaments,
  schedule_event,
  unschedule_event,
  fetch_field,
  import_event_field,
  reset_field_tiers,
  set_golfer_tier,
  open_event,
  close_event,
  fetch_alternate_field,
  mark_alternate,
  compare_event_field,
  fix_missing_field,
  update_pga_leaderboards,
  event_update_field,
  event_update_golfer_score_card,
  update_golfer_metrics,
} from 'utils/api.js';

import 'pages/admin/Admin.css'
import 'common/McModal.css';

export default class Tournaments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      spevents: [],
      fieldid: null,
      alternatefieldid: null,
      compareEventId: null,
      actionsOpen: [],
      filter:'LIVE',
      season:CURRENT_SEASON(),
      tour:'all',
      addEventOpenModal: false,
      srIds: null,
      selectedRound: 1,
    };

    this.toggleActions = this.toggleActions.bind(this);
    this.fetchTournamentsEvents = this.fetchTournamentsEvents.bind(this);
    this.fetchSRTournamentsEvents = this.fetchSRTournamentsEvents.bind(this);
    this.updateEventRow = this.updateEventRow.bind(this);
    this.scheduleEvent = this.scheduleEvent.bind(this);
    this.unscheduleEvent = this.unscheduleEvent.bind(this);
    this.setFieldId = this.setFieldId.bind(this);
    this.setCompareEventId = this.setCompareEventId.bind(this);
    this.setAlternateFieldId = this.setAlternateFieldId.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.toggleSeasonDropdown = this.toggleSeasonDropdown.bind(this);
    this.setSeason = this.setSeason.bind(this);
    this.openAddEventModal = this.openAddEventModal.bind(this);

    this.updatePgaLeaderboards = this.updatePgaLeaderboards.bind(this);
    this.updateEventField = this.updateEventField.bind(this);
    this.updateEventGolferScoreCard = this.updateEventGolferScoreCard.bind(this);
    this.changeSelectedRound = this.changeSelectedRound.bind(this);
  }

  toggleActions(eventid) {
    let actionsOpen = this.state.actionsOpen;
    actionsOpen[eventid] = !actionsOpen[eventid];
    this.setState({actionsOpen: actionsOpen});
  }

  setSeason(season){
    this.setState({season,});
    this.fetchTournamentsEvents(season);
    this.fetchSRTournamentsEvents(season);
  }

  componentDidMount() {
    this.fetchTournamentsEvents(this.state.season);
    this.fetchSRTournamentsEvents(this.state.season);
  }

  fetchTournamentsEvents(season, tour) {
    if (tour === undefined || !tour){tour = this.state.tour;}
    get_all_events(season, tour,
    ({events}) => {
      let actionsOpen = this.state.actionsOpen;
      events.forEach((event) => {
        actionsOpen[event.id] = false;
      });
      this.setState({
        events, actionsOpen,
      });
    }, (error) => {
      console.log(error);
    });
  }

  fetchSRTournamentsEvents(season , tour) {
    if (tour === undefined || !tour){tour = this.state.tour;}
    get_sr_tournaments(season, tour,
    ({events}) => {
      let actionsOpen = this.state.actionsOpen;
      events.forEach((event) => {
        actionsOpen[event.id] = false;
      });
      this.setState({
        spevents:events, actionsOpen,
      });
    }, (error) => {
      console.log(error);
    });
  }

  openAddEventModal(srIds){
    this.setState({
      addEventOpenModal: !this.state.addEventOpenModal, srIds,
    });
  }

  updateEventRow(event) {
    let events = this.state.events;

    const index = events.findIndex((e) => { return e.id === event.id });
    events[index]=event;

    this.setState({ events });
  }

  scheduleEvent(eventid) {
    schedule_event(eventid,
    ({event}) => {
      this.updateEventRow(event);
    }, (error) => {
      console.log(error);
    });
  }

  unscheduleEvent(eventid) {
    unschedule_event(eventid,
    ({event}) => {
      this.updateEventRow(event);
    }, (error) => {
      console.log(error);
    });
  }

  setFieldId(fieldid) {
    this.setState({fieldid});
  }

  setCompareEventId(compareEventId) {
    this.setState({compareEventId});
  }

  setAlternateFieldId(alternatefieldid) {
    this.setState({alternatefieldid});
  }

  closeEvent(eventid) {
    close_event(eventid,
    ({success, error, event}) => {
      if (!success) {
        if (error) {
          console.log(error);
          alert(error);
        }
        return;
      }

      this.updateEventRow(event);
    }, (error) => {
      console.log(error);
    });
  }

  updatePgaLeaderboards() {
    update_pga_leaderboards(this.state.tour,
    ({success, error,}) => {
      if(success){
        alert('Done');
      }
    }, (error) => {
      console.log(error);
    });
  }

  updateGolferMetrics = () => {
    update_golfer_metrics(this.state.tour,
    ({success, error,}) => {
      if(success){
        alert('Done');
      }
    }, (error) => {
      console.log(error);
    });
  }

  updateEventField(eventid) {
    event_update_field(eventid,
    ({success, error,}) => {
      if(success){
        alert('Done');
      }
    }, (error) => {
      console.log(error);
    });
  }

  updateEventGolferScoreCard(eventid) {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className='react-confirm-alert'>
              <div className='react-confirm-alert-body'>
                <h1>Score Update for a specific round</h1>
                <Row style={{}}>
                  <Col className="d-flex">
                    <span style={{lineHeight:"2rem", }}> Round: </span>
                    <Input type="select" value={this.state.selectedRound}  className="ml-2"
                      name={eventid} id="selectedRound"
                      onChange={this.changeSelectedRound}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Input>
                  </Col>
                </Row>
                <div className="react-confirm-alert-button-group">
                  <button
                    onClick={() => {
                      event_update_golfer_score_card(eventid, this.state.selectedRound,
                      ({success, error,}) => {
                        if(success){
                          alert('Done');
                        }
                      }, (error) => {
                        console.log(error);
                      });
                      onClose();
                    }}
                  >
                    Submit
                  </button>
                  <button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

        );
      }
    });

  }


  changeSelectedRound(event){
    let round = parseInt(event.target.value);
    //alert(round + ' - ' + event.target.name);
    this.setState({selectedRound:round});
    this.updateEventGolferScoreCard(event.target.name);
  }


  handleFilter(filter) {
    if (this.state.filter === filter) {
      return;
    }

    this.setState({filter});
  }

  toggleSeasonDropdown() {
    this.setState({
      seasonDropdownOpen: !this.state.seasonDropdownOpen
    });
  }

  toggleTourDropdown = () => {
    this.setState({
      tourDropdownOpen: !this.state.tourDropdownOpen
    });
  }

  setTour = (tour) => {
    this.setState({tour,});
    this.fetchTournamentsEvents(this.state.season, tour);
    this.fetchSRTournamentsEvents(this.state.season, tour);
  }

  render() {

    const filterEvents = (events, filter) => {
      let filterFunc = () => false;
      if (filter === 'LIVE'){
        filterFunc = (event) => ['round1','round2','round3','round4'].indexOf(event.status) >-1;
      }else if (filter === 'OPEN'){
        filterFunc = (event) => event.status === 'open';
      }else if (filter === 'SCHEDULED'){
        filterFunc = (event) => event.status === 'scheduled';
      }else if (filter === 'UNSCHEDULED'){
        filterFunc = (event) => event.status === 'unscheduled';
      }else if (filter === 'CLOSED'){
        filterFunc = (event) => event.status === 'closed';
      }else if (filter === 'MISSING'){
        events = !!this.state.spevents ? this.state.spevents : [];
        filterFunc = (event) => event.mc_status === 'missing';
      }else if (filter === 'MISMATCH'){
        events = !!this.state.spevents ? this.state.spevents : [];
        filterFunc = (event) => event.mc_status === 'mismatch';
      }

      return events.filter(filterFunc);
    }

    let filteredEvents = !!this.state.events ? filterEvents(this.state.events, this.state.filter) : [];

    const filterButton = (text, currFilter) => {
      const active = this.state.filter === currFilter;

      let fltrField = !!this.state.events ? filterEvents(this.state.events, currFilter) : [];

      const textWithCount = text + ' (' + fltrField.length + ')';

      return <Button color="primary" outline onClick={() => this.handleFilter(currFilter)} active={active} key={currFilter}>
        <span className="text-uppercase text-nowrap" style={{fontSize: ".7em"}}>{textWithCount}</span>
      </Button>
    }

    const header =
      <thead>
        <tr style={{backgroundColor: "#f6f6f6", color: "#aaa", "fontSize": "0.7rem", textTransform: "uppercase"}}>
          <th>Tournament</th>
          <th>Tour</th>
          {(this.state.filter !== 'MISMATCH' && this.state.filter !== 'MISSING' ) &&
            <th className="text-center">Status</th>
          }
          {this.state.filter === 'MISMATCH' && <th className="text-center">Reason (MC | SR)</th>}
          <th className="text-center">Action</th>
        </tr>
      </thead>

    const rows = filteredEvents.map((event, index) => {
      const tournament =
        <td>
            <div style={{color: event.status !== 'unscheduled' ? "#555" : "#ccc", fontWeight:700}} className="text-nowrap">
              {event.name}
            </div>
            <div style={{color: event.status !== 'unscheduled' ? "#aaa" : "#ccc", fontSize: ".75em"}}>
              {event.dates} &bull; {event.course}
            </div>
        </td>

      const status =
        <td style={{color: "#555"}} className="pt-3 text-center">
          {{
            'unscheduled': 'Unscheduled',
            'scheduled': 'Scheduled',
            'open': 'Open',
            'round1': 'Round 1',
            'round2': 'Round 2',
            'round3': 'Round 3',
            'round4': 'Round 4',
            'closed': 'Closed',
          }[event.status]}
        </td>


      let action = null;

      let scheduleEvent = event.status === 'unscheduled' &&
                          <DropdownItem onClick={()=>{this.scheduleEvent(event.id)}}>Schedule</DropdownItem>
      let unscheduleEvent = event.status === 'scheduled' &&
                          <DropdownItem onClick={()=>{this.unscheduleEvent(event.id)}}>Unschedule</DropdownItem>
      let manageFld   =   event.status === 'scheduled' &&
                          <DropdownItem onClick={()=>{this.setFieldId(event.id)}}>Manage Field</DropdownItem>
      let manageAlt   =  ['open', 'round1', 'round2', 'round3', 'round4'].indexOf(event.status) !== -1 &&
                          <DropdownItem onClick={()=>{this.setAlternateFieldId(event.id)}}>Manage Alternate</DropdownItem>
      let close       =  event.closable &&
                          <DropdownItem onClick={()=>{this.closeEvent(event.id)}}>Close</DropdownItem>
      let compareFld  =  (event.status !== 'missing' && event.status !== 'mismatch') &&
                          <DropdownItem onClick={()=>{this.setCompareEventId(event.id)}}>Compare Field</DropdownItem>

      let fix       =  (event.status === 'missing' || event.status === 'mismatch') &&
                          <DropdownItem onClick={()=>{this.openAddEventModal(event.id)}}>Fix</DropdownItem>

      let updateEventField =  (event.status === 'closed') &&
                          <DropdownItem onClick={()=>{this.updateEventField(event.id)}}>Update Earnings</DropdownItem>

      let updateEventGolferScoreCard       =  ['closed', 'round1', 'round2', 'round3', 'round4'].indexOf(event.status) !== -1 &&
                         <DropdownItem onClick={()=>{this.updateEventGolferScoreCard(event.id)}}>Update Score Card</DropdownItem>


      let actionOpen = (this.state.actionsOpen[event.id]);
      action =
        <td className="text-center">
          <Dropdown isOpen={actionOpen} toggle={()=>{this.toggleActions(event.id)}}>
            <DropdownToggle caret color="secondary" disabled={!(scheduleEvent || unscheduleEvent || manageFld || manageAlt || close || compareFld || fix)}>
              Actions
            </DropdownToggle>
            <DropdownMenu positionFixed={true}>
              {scheduleEvent}
              {unscheduleEvent}
              {manageFld}
              {manageAlt}
              {compareFld}
              {close}
              {fix}
              {updateEventField}
              {updateEventGolferScoreCard}
            </DropdownMenu>
          </Dropdown>
        </td>

      return (
        <tr key={index}>
          {tournament}
          <td className="text-left" >{event.tour.toUpperCase()}</td>
          {(this.state.filter !== 'MISMATCH' && this.state.filter !== 'MISSING' ) && status}
          {this.state.filter === 'MISMATCH' &&
           <td className="text-center" style={{fontSize: ".75em"}}>{event.ms_reason}</td>
          }
          {action}
        </tr>
      )
    });

    const tours = ['all','pga','lpga','champ','liv'].map((tour) =>
      <DropdownItem key={tour} onClick={() => this.setTour(tour)}>
        {tour.toUpperCase()}
      </DropdownItem>
    )

    const seasons = [(CURRENT_SEASON()-1),CURRENT_SEASON(),(CURRENT_SEASON()+1)].reverse().map((season) =>
      <DropdownItem key={season} onClick={() => this.setSeason(season)}>
        {season}
      </DropdownItem>
    )

    return (
      <div>
        <MobileHeader to="Tournaments"/>
        <ContentWrapper>
          <Card className="pb-4">
            <Title />
            <AddTournamentsModal tour={this.state.tour} srseason={this.state.season} mcseason={this.state.season}
            srIds={this.state.srIds} modal={this.state.addEventOpenModal}
            openAddEventModal={this.openAddEventModal} setSeason={this.setSeason}/>
            <div className="d-flex justify-content-end align-items-end flex-wrap px-3 pb-2">
              <Button color="primary" onClick={() => this.updatePgaLeaderboards()} >
                <span className="text-uppercase text-nowrap" style={{fontSize: ".7em"}}>{'UPDATE Leaderboard'}</span>
              </Button>
              <Button color="primary" onClick={() => this.updateGolferMetrics()} className="ml-2">
                <span className="text-uppercase text-nowrap" style={{fontSize: ".7em"}}>{'UPDATE Golfer Metrics'}</span>
              </Button>


              <span className="px-2 mb-1" style={{color:'#007bff'}}>Tour:</span>
              <Dropdown isOpen={this.state.tourDropdownOpen} toggle={this.toggleTourDropdown} size="sm"
              className="tournamentSeason_dropdown">
                <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                  {this.state.tour.toUpperCase()}
                </DropdownToggle>
                <DropdownMenu>
                  {tours}
                </DropdownMenu>
              </Dropdown>

              <span className="px-2 mb-1" style={{color:'#007bff'}}>Season:</span>
              <Dropdown isOpen={this.state.seasonDropdownOpen} toggle={this.toggleSeasonDropdown} size="sm"
              className="tournamentSeason_dropdown">
                <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
                  {this.state.season}
                </DropdownToggle>
                <DropdownMenu>
                  {seasons}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-wrap px-2 pb-2">
              <ButtonGroup className="pt-2" style={{overflowX: 'auto'}}>
                {filterButton('LIVE', 'LIVE')}
                {filterButton('OPEN', 'OPEN')}
                {filterButton('SCHEDULED', 'SCHEDULED')}
                {filterButton('UNSCHEDULED', 'UNSCHEDULED')}
                {filterButton('CLOSED', 'CLOSED')}
                {filterButton('MISMATCH', 'MISMATCH')}
                {filterButton('MISSING', 'MISSING')}
              </ButtonGroup>
            </div>
            <Table responsive>
              {header}
              <tbody>
                {rows}
              </tbody>
            </Table>
            <CheckFieldModal fieldid={this.state.fieldid} setFieldId={this.setFieldId} updateEventRow={this.updateEventRow} />
            <AlternateModal alternatefieldid={this.state.alternatefieldid} setAlternateFieldId={this.setAlternateFieldId} />
            <CheckFieldCompareModal eventid={this.state.compareEventId} setCompareEventId={this.setCompareEventId} updateEventRow={this.updateEventRow}/>
          </Card>
        </ContentWrapper>
      </div>
    );
  }
}

const Title = (props) =>
  <h2 className="text-center p-2 pt-4" style={{color:"#212121"}}>Tournaments Console</h2>

class CheckFieldModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: [],
      golferModalOpen: false,
      golferModalGolferId: null,
    }

    this.fetchField = this.fetchField.bind(this);
    this.importEventField = this.importEventField.bind(this);
    this.resetFieldTiers = this.resetFieldTiers.bind(this);
    this.setGolferTier = this.setGolferTier.bind(this);
    this.openEvent = this.openEvent.bind(this);
    this.toggleGolferModal = this.toggleGolferModal.bind(this);
    this.showGolferDetails = this.showGolferDetails.bind(this);
  }

  fetchField(eventid) {
    if (!eventid) {
      return;
    }

    fetch_field(eventid,
    ({field}) => {
      this.setState({ field });
    }, (error) => {
      console.log(error);
    });
  }

  importEventField() {
    if (!this.props.fieldid) {
      return;
    }

    import_event_field(this.props.fieldid,
    ({success, error, field}) => {
      if (!success) {
        if (error) {
          console.log(error);
          alert(error);
        }
        return;
      }

      this.setState({ field });
    }, (error) => {
      console.log(error);
    });
  }

  resetFieldTiers() {
    if (!this.props.fieldid) {
      return;
    }

    reset_field_tiers(this.props.fieldid,
    ({success, field}) => {
      if (!success) {
        return;
      }

      this.setState({ field });
    }, (error) => {
      console.log(error);
    });
  }

  setGolferTier(eventfieldid, tier) {
    set_golfer_tier(eventfieldid, tier,
    ({success, fieldrow, error}) => {
      if (!success) {
        if (error) {
          console.log(error);
          alert(error);
        }
        return;
      }

      let field = this.state.field;

      const index = field.findIndex((e) => { return e.id === fieldrow.id });
      field[index]=fieldrow;

      this.setState({ field });
    }, (error) => {
      console.log(error);
    });
  }

  openEvent() {
    if (!this.props.fieldid) {
      return;
    }

    open_event(this.props.fieldid,
    ({success, error, event}) => {
      if (!success) {
        if (error) {
          console.log(error);
          alert(error);
        }
        return;
      }

      this.props.setFieldId(null);
      this.props.updateEventRow(event);
    }, (error) => {
      console.log(error);
    });
  }

  toggleGolferModal() {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }

  showGolferDetails(golferid) {
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }

  componentDidMount() {
    this.fetchField(this.props.fieldid);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchField(nextProps.fieldid);
  }

  render() {
    const toggleModal = () => {this.props.setFieldId(null)};

    const header =
      <thead>
        <tr style={{backgroundColor: "#f6f6f6", color: "#aaa", "fontSize": "0.7rem", textTransform: "uppercase"}}>
          <th>Golfer</th>
          <th className="text-nowrap">World Rank</th>
          <th>Tier</th>
        </tr>
      </thead>

    let sortedField = this.state.field
    sortedField.sort((a,b) => {
      if (a.tier !== b.tier) {
        return a.tier - b.tier;
      }

      const anull = a.worldrank === null;
      const bnull = b.worldrank === null;

      if (anull && bnull) {
        const a2null = a.secondary_rank === null;
        const b2null = b.secondary_rank === null;

        if (a2null && b2null) {
          return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
        } else if (a2null) {
          return 1;
        } else if (b2null) {
          return -1;
        }

        return a.secondary_rank - b.secondary_rank;
      } else if (anull) {
        return 1;
      } else if (bnull) {
        return -1;
      }
      return a.worldrank - b.worldrank;
    })

    const rows = sortedField.map((golfer, index) => {
      const worldrank = golfer.worldrank !== null
        ? golfer.worldrank
        : golfer.secondary_rank !== null ? `(${golfer.secondary_rank})` : null;
      return <tr style={{fontSize: ".85em"}} key={golfer.id}>
        <td>
          <span onClick={() => this.showGolferDetails(golfer.golfer_id)}>
            {golfer.name}
          </span>
        </td>
        <td>{worldrank}</td>
        <td>
          <ButtonGroup size="sm">
            {['A','B','C','D'].map((tier, index) => {
              return <Button active={golfer.tier === index} color="success" key={tier} style={{fontSize: ".7em"}} onClick={()=>{
                if (index !== golfer.tier) { this.setGolferTier(golfer.id, index) }}}>
                {tier}
              </Button>
            })}
          </ButtonGroup>
        </td>
      </tr>
    });

    let tierCounts = [0,0,0,0];
    this.state.field.forEach((golfer) => {
      tierCounts[golfer.tier]++;
    });

    return <Modal isOpen={!!this.props.fieldid} toggle={toggleModal} className="editModal">
      <ModalHeader toggle={toggleModal} className="mcModalHeader">Manage Field</ModalHeader>
      <div>
        <div className="d-flex justify-content-around m-3">
          <Button outline color="primary" onClick={this.importEventField}>
            {this.state.field.length > 0 ? 'Re-Import Field' : 'Import Field'}
          </Button>
          <Button outline color="primary" disabled={!this.state.field} onClick={this.resetFieldTiers}>
            Reset Tiers (10/20/30/40)
          </Button>
        </div>
        <div className="fieldModalHeader d-flex justify-content-around py-2">
          <div>
            <div>{this.state.field.length}</div>
            <div className="subtitle">Golfers Total</div>
          </div>
          <div className="d-flex justify-content-around">
            {['A', 'B', 'C', 'D'].map((tier, index)=>{
              return  <div key={tier} className="mx-3">
                        <div>{tierCounts[index]}</div>
                        <div className="subtitle">{tier}</div>
                      </div>

            })}
          </div>
        </div>
        <Table responsive>
          {header}
          <tbody>
            {rows}
          </tbody>
        </Table>
      </div>
      <ModalFooter>
        <Button color="primary" onClick={this.openEvent} disabled={this.state.field.length === 0}>
          Open Event
        </Button>
      </ModalFooter>
      <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
    </Modal>
  }
}

class AlternateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: [],
    }

    this.fetchAlternateField = this.fetchAlternateField.bind(this);
    this.markAlternate = this.markAlternate.bind(this);
  }

  fetchAlternateField(eventid) {
    if (!eventid) {
      return;
    }

    fetch_alternate_field(eventid,
    ({field}) => {
      this.setState({ field });
    }, (error) => {
      console.log(error);
    });
  }

  markAlternate(eventfieldid, alternate, secAlternate) {
    mark_alternate(eventfieldid, alternate, secAlternate,
    ({success, fieldrow, error}) => {
      if (!success) {
        if (error) {
          console.log(error);
          alert(error);
        }
        return;
      }

      let field = this.state.field;

      const index = field.findIndex((e) => { return e.id === fieldrow.id });
      field[index]=fieldrow;

      this.setState({ field });
    }, (error) => {
      console.log(error);
    });
  }

  componentDidMount() {
    this.fetchAlternateField(this.props.alternatefieldid);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchAlternateField(nextProps.alternatefieldid);
  }

  render() {
    const toggleModal = () => {this.props.setAlternateFieldId(null)};

    const header =
      <thead>
        <tr style={{backgroundColor: "#f6f6f6", color: "#aaa", "fontSize": "0.7rem", textTransform: "uppercase"}}>
          <th>Golfer</th>
          <th>Status</th>
        </tr>
      </thead>

    let sortedField = this.state.field
    sortedField.sort((a,b) => {
      return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
    })

    const rows = sortedField.map((golfer, index) => {
      return <tr style={{fontSize: ".85em"}} key={golfer.id}>
        <td>{golfer.name}</td>
        <td>
          <ButtonGroup size="sm">
            <Button  color={!golfer.alternate && !golfer.secAlternate ? "warning":"success"}  key={'field'} style={{fontSize: ".7em"}} onClick={()=>{
                if (golfer.alternate || golfer.secAlternate) { this.markAlternate(golfer.id, false, false) }}}>
                In Field
            </Button>
            <Button color={golfer.alternate && !golfer.secAlternate ? "warning":"success"} key={'alternate'} style={{fontSize: ".7em"}} onClick={()=>{
                if (!golfer.alternate) { this.markAlternate(golfer.id, true, false) }}}>
                Alternate
            </Button>
            <Button color={!golfer.alternate && golfer.secAlternate ? "warning":"success"} key={'secAlternate'} style={{fontSize: ".7em"}} onClick={()=>{
                if (!golfer.secAlternate) { this.markAlternate(golfer.id, false, true) }}}>
                Second Alt
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    });

    return <Modal isOpen={!!this.props.alternatefieldid} toggle={toggleModal} className="editModal">
      <ModalHeader toggle={toggleModal} className="mcModalHeader">Manage Alternates</ModalHeader>
      <div>
        <Table responsive>
          {header}
          <tbody>
            {rows}
          </tbody>
        </Table>
      </div>
    </Modal>
  }
}

class CheckFieldCompareModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      eventid: null,
      field: [],
      golferModalOpen: false,
      golferModalGolferId: null,
      filter:0,
    }

    this.fetchFieldCompare = this.fetchFieldCompare.bind(this);
    this.toggleGolferModal = this.toggleGolferModal.bind(this);
    this.showGolferDetails = this.showGolferDetails.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.fixMissingField = this.fixMissingField.bind(this);
  }

  fetchFieldCompare(eventid) {
    if (!eventid) {
      return;
    }
    this.setState({eventid,field:[],filter:0,loading:true,});

    compare_event_field(eventid,
    ({field}) => {
      this.setState({ field, loading:false, });
    }, (error) => {
      this.setState({ loading:false, });
      console.log(error);
    });
  }

  fixMissingField(eventid){
    fix_missing_field(eventid,
    ({field}) => {
      this.setState({ field });
    }, (error) => {
      console.log(error);
    });
  }

  toggleGolferModal() {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }

  showGolferDetails(golferid) {
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }

  componentDidMount() {
    this.fetchFieldCompare(this.props.eventid);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchFieldCompare(nextProps.eventid);
  }

  handleFilter(filter) {
    if (this.state.filter === filter) {
      return;
    }

    this.setState({filter});
  }


  render() {
    const toggleModal = () => {this.props.setCompareEventId(null)};

    const filterPayouts = (fields, filter) => {
      let filterFunc = () => false;
      filterFunc = (field) => field.dataSts === filter;
      return fields.filter(filterFunc);
    }

    const header =
      <thead>
        <tr style={{backgroundColor: "#f6f6f6", color: "#aaa", "fontSize": "0.7rem", textTransform: "uppercase"}}>
          <th>Golfer</th>
          <th>Tier</th>
          <th></th>
          <th style={{textAlign:'right'}}>R1</th>
          <th style={{textAlign:'right'}}>R2</th>
          <th style={{textAlign:'right'}}>R3</th>
          <th style={{textAlign:'right'}}>R4</th>
          {/*<th style={{textAlign:'center',rightPadding:'5px'}}>THRU</th>*/}
        </tr>
      </thead>

    let filteredField = !!this.state.field ? filterPayouts(this.state.field, this.state.filter) : [];

    const filterButton = (text, currFilter) => {
      const active = this.state.filter === currFilter;

      let fltrField = !!this.state.field ? filterPayouts(this.state.field, currFilter) : [];

      const textWithCount = text + ' (' + fltrField.length + ')';

      return <Button color="primary" outline onClick={() => this.handleFilter(currFilter)} active={active} key={currFilter}>
        <span className="text-uppercase text-nowrap" style={{fontSize: ".7em"}}>{textWithCount}</span>
      </Button>
    }

    const rows = filteredField.map((golfer, index) => {
      let mcId = golfer.golferid + 'mc';
      let spId = golfer.golferid + 'sp';
      return(
        <>
          <tr style={{fontSize: ".85em"}} key={mcId}>
            <td rowSpan="2" style={{verticalAlign:"middle"}}>
              <span onClick={() => this.showGolferDetails(golfer.golferid)}>
                {golfer.golfername}
              </span>
            </td>
            <td rowSpan="2" style={{verticalAlign:"middle"}}>{golfer.tier}</td>
            <td className="sportradarlogo"></td>
            <td style={{textAlign:'right'}}>{golfer.sr_ef_r1}</td>
            <td style={{textAlign:'right'}}>{golfer.sr_ef_r2}</td>
            <td style={{textAlign:'right'}}>{golfer.sr_ef_r3}</td>
            <td style={{textAlign:'right'}}>{golfer.sr_ef_r4}</td>
            {/*<td style={{textAlign:'center',rightPadding:'5px'}}>{golfer.sr_ef_thru}</td>*/}
          </tr>
          <tr style={{fontSize: ".85em"}} key={spId}>
            <td className="mclogo"></td>
            <td style={{textAlign:'right'}}>{golfer.mc_ef_r1}</td>
            <td style={{textAlign:'right'}}>{golfer.mc_ef_r2}</td>
            <td style={{textAlign:'right'}}>{golfer.mc_ef_r3}</td>
            <td style={{textAlign:'right'}}>{golfer.mc_ef_r4}</td>
            {/*<td style={{textAlign:'center',rightPadding:'5px'}}>{golfer.mc_ef_thru}</td>*/}
          </tr>
        </>)
    });


    return <Modal isOpen={!!this.props.eventid} toggle={toggleModal} className="editModal">
      <ModalHeader toggle={toggleModal} className="mcModalHeader">Compare Field</ModalHeader>
      {(!this.state.field && this.state.loading === false) &&
        <div className="fieldModalHeader d-flex justify-content-around py-2">
          <div>
            <div>No data returned</div>
          </div>
        </div>
      }
      {!!this.state.field &&
        <div>
          <LoadingModal loading={this.state.loading} />
          <div className="fieldModalHeader d-flex justify-content-around py-2">
            <div>
              <div >{this.state.field.length} Golfers Total</div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-wrap px-2 pb-2">
            <ButtonGroup className="pt-2" style={{overflowX: 'auto'}}>
              {filterButton('Missing', 0)}
              {filterButton('Withdraw', 2)}
              {/*filterButton('MisMatch', 1)*/}
            </ButtonGroup>
          </div>
          {(this.state.filter < 2 && filteredField.length> 0) &&
            <div className="d-flex justify-content-end mx-3 my-1">
              <Button size="sm" color="success" onClick={() => this.fixMissingField(this.state.eventid)}>
                Fix Field
              </Button>
            </div>
          }
          <Table responsive className="fieldCompareTable">
            {header}
            <tbody>
              {rows}
            </tbody>
          </Table>
        </div>
       }
      <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
    </Modal>
  }
}

class LoadingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    }

  }

  render() {

    return (
      <Modal isOpen={this.props.loading} className="editModal">
        <div className="golferModal text-center py-2">
          <FontAwesome name="spinner" size="4x" spin />
        </div>
      </Modal>
    )
  }

}
