import React from 'react';
import Select from 'react-select';

import {
  Card,
  Input,Button,
} from 'reactstrap';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import FontAwesome from 'react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';


import 'pages/commissioner/BannerAds.css';

import LoadingModal from 'common/LoadingModal.js';
import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import CsvDownloader from 'react-csv-downloader';

import {
  get_bannerAds, upload_bannerAds,set_app_config,delete_bannerAds,update_bannerAdProps, get_user_banner_clicks,
} from 'utils/api.js';

export default class BannerAds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      imageData: null,
      fileExtension: null,
      banner: null,
      bannerList: [],
      appConfigList: [],
      loading: false,
      error: null,
      configGrp: props.configGrp||'LEAGUE_BANNER',
    };

    this.toggle = this.toggle.bind(this);
    this.getBannerAds = this.getBannerAds.bind(this);
    this.deleteBannerAds = this.deleteBannerAds.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.setAppConfigList = this.setAppConfigList.bind(this);
    this.setAppConfigVal = this.setAppConfigVal.bind(this);
    this.setToggleState = this.setToggleState.bind(this);
    this.setLoadingModal = this.setLoadingModal.bind(this);
    this.setBannerAdProps = this.setBannerAdProps.bind(this);
    this.updateBannerAdProps = this.updateBannerAdProps.bind(this);
    this.getUserBannerClicks = this.getUserBannerClicks.bind(this);
  }

  componentDidMount() {
    this.getBannerAds();
    this.getUserBannerClicks();
  }

  componentWillReceiveProps(nextProps) {

  }

  setLoadingModal(loading){
    this.setState({loading});
  }

  toggle(event) {
    this.setState({
      modal: !this.state.modal,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }

  getBannerAds(){
    get_bannerAds(null, this.props.activeLeague, this.state.configGrp,'ALL',
    ({success, banner, bannerList}) => {
      this.setState({
        banner,
        bannerList,
      });
    }, (error) => {
      console.log(error);
    });
  }

  deleteBannerAds(bannerid){
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setLoadingModal(true);
            delete_bannerAds(null, this.props.activeLeague, this.state.configGrp, bannerid,
              ({success, banner, bannerList, error}) => {
                this.setLoadingModal(false);
                if (success) {
                  console.log(success);
                  this.setState({
                    banner,
                    bannerList,
                  });
                } else {
                  console.log(error);
                }
            }, (error) => {
              this.setLoadingModal(false);
              console.log(error);
            });
          }
        },
        {
          label: 'Cancel',
          onClick: () => { }
        }
      ]
    });

  }


  handleInputChange(event) {
    console.log('handleInputChange');
    event.preventDefault();
    this.setState({error:null});

    var x = this;
    let reader = new FileReader();
    var fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();
    if (event.target.files[0].size > 300000){
      confirmAlert({
        title: 'Alert',
        message: 'File excceeds allowable file size of 300KB.',
        buttons: [
          {
            label: 'OK',
            onClick: () => {
            }
          }
        ]
      });

      return;
    }
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
        console.log(reader.result);
        x.setState({
          imageData: reader.result,
          fileExtension: fileExtension,
        });

        x.handleFormSubmit(event);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };

  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.setLoadingModal(true);
    upload_bannerAds(null, this.props.activeLeague, this.state.configGrp, this.state.imageData.replace('data:image/png;base64,','').replace('data:image/jpeg;base64,',''),
    this.state.fileExtension,
    ({success, banner, bannerList, error}) => {
      if (success) {
        console.log(success);
        this.setState({
          banner,
          bannerList,
        });
      } else {
        console.log(error);
      }
      this.setLoadingModal(false);
    }, (error) => {
      console.log(error);
      this.setLoadingModal(false);
    });

  }

  setAppConfigList() {
    this.state.appConfigList.map((appConfig, index) =>
      this.setAppConfigVal(appConfig.id,appConfig.config_value)
    );
  }

  setAppConfigVal(config_id, config_value) {
    set_app_config(config_id, config_value,
    ({msg}) => {
      //this.getAppConfigList('LB_ADV_BANNER_RGLR_LEAGUE');
    }, (error) => {
      console.log(error);
    });
  }

  setToggleState(stateVal, config_id){
    let banner = this.state.banner;
    banner.show_banner = stateVal
    this.setState({ banner: banner });
    this.setAppConfigVal(banner.id,stateVal);
  }

  setBannerAdProps(bannerid, bannerprop, bannerpropvalue){
    let bannerList = this.state.bannerList;
    let bannerDetails = bannerList.filter(x => x.id === bannerid)[0];
    bannerDetails[bannerprop] = bannerpropvalue;
    this.setState({ bannerList: bannerList });


  }

  updateBannerAdProps(bannerid, bannerprop, bannerpropvalue){

    if (!bannerpropvalue){
      let bannerList = this.state.bannerList;
      let bannerDetails = bannerList.filter(x => x.id === bannerid)[0];
      bannerpropvalue = bannerDetails[bannerprop];
    }
    if (bannerprop === 'click_url'){
      bannerpropvalue = bannerpropvalue.toLowerCase()
      let pattern = '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
        '[a-zA-Z]{2,13})' + // extension
        '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
        '|localhost)' + // OR localhost
        '(\\:\\d{1,5})?' + // port
        '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
        '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
        '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
      let regex = new RegExp(pattern);
      let isValid = regex.test(bannerpropvalue);

      if (!isValid) {
        confirmAlert({
          title: 'Alert',
          message: 'Please enter a valid URL.',
          buttons: [
            {
              label: 'OK',
              onClick: () => {
              }
            }
          ]
        });

        return ;
      }

    }
    this.setLoadingModal(true);
    update_bannerAdProps(null, this.props.activeLeague, this.state.configGrp, bannerid, bannerprop, bannerpropvalue,
    ({success, banner, bannerList, error}) => {
      this.setLoadingModal(false);
      if (success) {
        console.log(success);
        this.setState({
          banner,
          bannerList,
        });
      } else {
        this.setLoadingModal(false);
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  getUserBannerClicks(){

      get_user_banner_clicks(this.props.activeLeague,
      ({success, userClicks}) => {
        this.setState({bannerClickData:userClicks});

      }, (error) => {
        console.log(error);
      });
  }

  render() {
    const bannerClickCSVExpCols = [
      {
        id: 'banner_name',
        displayName: 'Banner Name',
      },{
        id: 'click_date_str',
        displayName: 'Click Date',
      },{
        id: 'url',
        displayName: 'URL',
      },{
        id: 'username',
        displayName: 'User Name',
      },{
        id: 'first_name',
        displayName: 'First Name',
      },{
        id: 'last_name',
        displayName: 'Last Name',
      },
    ];

    let appConfig = this.state.appConfigList.filter(x => x.config_name === 'BANNER_SHOW')[0];
    let csvFileNm = this.state.bannerClickData && this.state.bannerClickData.length > 0 ? this.state.bannerClickData[0].league_name + ' - User Click Report' : 'User Click Report';

    return (
      <div >

        <MobileHeader to="Banner Ads" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
            {'Banner Ads'}
          </div>

          <Card className="p-3 mb-4">
            <div className="d-md-flex justify-content-start" >
              <div className="" style={{width:'80%'}}>
                <div className="d-flex justify-content-start">
                  <ToggleRowAppConfig setToggleState={this.setToggleState} banner={this.state.banner}
                    configFldDesc={'Show Banner'} configName={'BANNER_SHOW'}
                    toggleOnVal={'Y'} toggleOffVal={'N'}/>

                  <div className=" ml-3">
                    <div style={{paddingTop:'8px'}}>
                      <div className="file btn btn-sm btn-primary file_upload_div d-flex">
                        <FontAwesome name="upload" />
                        &nbsp;&nbsp;Upload <input type="file" name="image" accept="image/png, image/jpeg"
                        className="file_upload_div_input" onChange={this.handleInputChange}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="mt-n1 ml-2 " style={{fontSize: '0.75em', color: 'rgb(136, 136, 136)'}}>
                    Target Dimension: 2000 x 220. Maximum File Size: 300KB.
                  </div>
                </div>
              </div>

              <div className="d-flex mt-3 ml-2">
                <div className="text-nowrap pt-1">User Click Report:</div>
                <CsvDownloader
                  filename={csvFileNm}
                  separator=","
                  wrapColumnChar='"'
                  columns={bannerClickCSVExpCols}
                  datas={this.state.bannerClickData}>

                    <Button size="sm" color="success" outline className="ml-2 ml-md-none text-nowrap"
                      style={{minWidth:'60px'}}>
                      <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                    </Button>

                </CsvDownloader>
              </div>
            </div>

            {(this.state.banner && this.state.banner.show_banner === 'Y') &&
            <>
            <BannerListTable bannerList={this.state.bannerList} filter={'A'} updateBannerAdProps={this.updateBannerAdProps}
              deleteBannerAds={this.deleteBannerAds} setBannerAdProps={this.setBannerAdProps}/>
            <BannerListTable bannerList={this.state.bannerList} filter={'I'} updateBannerAdProps={this.updateBannerAdProps}
              deleteBannerAds={this.deleteBannerAds} setBannerAdProps={this.setBannerAdProps}/>
            </>
            }
          </Card>
        </ContentWrapper>

        <LoadingModal isLoading={this.state.loading} />
      </div>
    )
  }
}

const ToggleRowAppConfig = (props) => {

    let appConfigVal = (props.banner && props.banner.show_banner===props.toggleOnVal?true:false);

    return (

      <div className="d-flex justify-content-start  p-2">
          <div className="pt-1 mr-2 text-nowrap">
            {props.configFldDesc}
          </div>
          <div className="text-left">
            <Bootstrap2Toggle
              onClick={(state)=>{if (props.banner && props.banner.id) {props.setToggleState(state?props.toggleOnVal:props.toggleOffVal,props.banner.id)}}}
              on={'YES'}
              off={'NO'}
              size="sm"
              offstyle="danger"
              onstyle="success"
              active={appConfigVal}
            />
          </div>
      </div>

    )
  }

const BannerListTable = (props) => {

  if (props.bannerList.filter(x => x.status === props.filter).length === 0){return '';}

  const bannerPlaceOptions = [{value:'LEAGUE_HOME',label:'Home'},{value:'LEADERBOARD',label:'Leaderboard'},{value:'EVENT',label:'Event'}];


  let rows = props.bannerList.filter(x => x.status === props.filter).map((bn, index) => {
    let baseURL = 'https://media.majorschallenge.com/leaderboardAdvBanner/';

    let imgURL = baseURL + bn.image_url;
    let tm = new Date().getTime();

    const bannerStyle = {backgroundImage: 'url(' + imgURL + ')'};
    bannerStyle['minHeight'] = (bn.height || 70) + 'px';

    bannerStyle['backgroundSize'] = 'cover';

    let listStyle = {borderWidth:'1px', borderColor:'#28a745'};
    if (props.filter === 'I'){
      listStyle = {borderWidth:'1px', borderColor:'#dc3545'};
    }
    //let imgColSpanCls = "pt-1 px-1 text-center col-sm-12";
    let bannerPlaces = bannerPlaceOptions.filter(x => bn.place.indexOf(x.value)>-1);
    return (
        <div className="pt-2 list-group-item px-0 " style={listStyle}>
          <div className="pt-1 px-4 ">
              {props.filter==='I' &&
              <div className="d-flex py-1 justify-content-end">
                  <div className=" btn btn-sm btn-success" onClick={() => {props.updateBannerAdProps(bn.id,'status',(props.filter==='A'?'I':'A'))}}>
                    {'Enable'}
                  </div>
                  <div className=" btn btn-sm btn-danger ml-2" onClick={() => {props.deleteBannerAds(bn.id)}}>
                    {'Delete' }
                  </div>
              </div>
              }
              <div className="" >
                  <div  className="text-center" style={{padding:"5px"}}>
                    {/*<div className="d-sm-none leaderboardRowPromoBannerMobile"
                      style={bannerStyle}>
                    </div>
                    <div className="d-none d-sm-block leaderboardRowPromoBannerDesktop"
                      style={bannerStyle}>
                    </div>*/}
                    <img src={imgURL} width="100%" style={{maxHeight:'150px'}} alt="Banner"/>
                  </div>
                  {props.filter==='A' &&
                  <>
                  <div className="d-md-flex py-1 justify-content-start">
                    <div className="my-2 mr-2" style={{minWidth:'80px'}}>Name</div>
                    <div className="d-md-flex py-1 justify-content-start">
                      <Input type="text" name='name' value={bn.name} style={{minWidth:'300px'}}
                        onChange={(event) => {props.setBannerAdProps(bn.id,'name', event.target.value)}} className="form-control" />
                      <div className="d-flex justify-content-start mt-2 mt-md-0">
                        <div className=" btn btn-sm btn-primary ml-md-2 mt-1" style={{height:'30px'}} onClick={() => {props.updateBannerAdProps(bn.id,'name')}}>
                          {'Update' }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-md-flex py-1 justify-content-start">
                    <div className="my-2 mr-2" style={{minWidth:'80px'}}>URL</div>
                    <div className="d-md-flex py-1 justify-content-start">
                      <Input type="text" name='url' value={bn.click_url} style={{minWidth:'300px'}}
                        onChange={(event) => {props.setBannerAdProps(bn.id,'click_url', event.target.value)}} />
                      <div className="d-flex justify-content-start mt-2 mt-md-0">
                        <div className="btn btn-sm btn-primary ml-md-2 mr-2 mt-1" style={{height:'30px'}} onClick={() => {props.updateBannerAdProps(bn.id,'click_url')}}>
                          {'Update' }
                        </div>
                        <div className="btn btn-sm btn-danger mt-1" style={{height:'30px'}} onClick={() => {props.updateBannerAdProps(bn.id,'status',(props.filter==='A'?'I':'A'))}}>
                          {'Disable'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex py-0 justify-content-start">
                    <div className="d-flex py-1 col-sm-6 ml-n3">
                      <div className="my-2 mr-2">Display in</div>
                      <div className="col-sm-10">
                        <Select className="no-border my-0 " isClearable isSearchable options={bannerPlaceOptions}
                        value={bannerPlaces}
                        placeholder = {"Places"}
                        isMulti
                        onChange={(selectedOption) => {
                            console.log(selectedOption);
                            let selectedPlaces = selectedOption.map(({ value }) => (value));
                            console.log(selectedPlaces);
                            props.setBannerAdProps(bn.id,'place', selectedPlaces);
                            props.updateBannerAdProps(bn.id,'place', selectedPlaces);

                          }
                        } />
                      </div>
                    </div>
                  </div>


                  </>
                  }
                </div>
          </div>
        </div>
    )
    });



  return (
    <div className="pt-3 " >

      {props.filter === 'A' &&
      <div className="pb-2 card-header card" style={{fontWeight: 600, color:'white', backgroundColor:'#28a745'}}>{'Active Banners'}</div>
      }
      {props.filter === 'I' &&
      <div className="pb-2 card-header card" style={{fontWeight: 600, color:'white', backgroundColor:'#dc3545'}}>{'Inactive Banners'}</div>
      }
      <div className="list-group">
      {rows}
      </div>
    </div>
  )
}
