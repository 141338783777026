import React from 'react';
import {
  /*Badge,*/
  Button,
  ButtonGroup,
  Card,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import EventRulesModal from 'pages/user/EventRulesModal';
import LeagueBalanceWarning from 'common/LeagueBalanceWarning';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import FlashMessage from 'react-flash-message';
import "utils/flashMessage.css";

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import {
  get_leaguesettings,
  set_leaguesettings,
  set_leaguestatus,
  create_piggy_club,
} from 'utils/api.js';

export default class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {loading: true,},
      multiplierOptions: ["0.10", "0.25", "0.50", "0.75", "1.00", "Custom"],
      feeOptions: [5,25,50,100,200, "Custom"],
      teams: 10,
      showEstimator: false,
      origLeagueName: "",
      origWinningPercentages: [],
      origWinningFlat: [],
      message: null,
      success: null,
      unsavedData: false,
      origSettings: {},
    };

    this.fetchLeagueSettings = this.fetchLeagueSettings.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenClubRadio = this.handleOpenClubRadio.bind(this);
    this.handleAllowWebCommunication = this.handleAllowWebCommunication.bind(this);
    this.handleAllowPublicAccess = this.handleAllowPublicAccess.bind(this);
    this.handleInviteRadio = this.handleInviteRadio.bind(this);
    this.handleVariableRadio = this.handleVariableRadio.bind(this);
    this.handleMultiplierChange = this.handleMultiplierChange.bind(this);
    this.handleCustomMultiplier = this.handleCustomMultiplier.bind(this);
    this.handleFeeChange = this.handleFeeChange.bind(this);
    this.handleCustomFee = this.handleCustomFee.bind(this);
    this.handleTeams = this.handleTeams.bind(this);
    this.handlePercWinningsChange = this.handlePercWinningsChange.bind(this);
    this.addWinningPercPlace = this.addWinningPercPlace.bind(this);
    this.removeWinningPercPlace = this.removeWinningPercPlace.bind(this);
    this.handleFlatWinningsChange = this.handleFlatWinningsChange.bind(this);
    this.addWinningFlatPlace = this.addWinningFlatPlace.bind(this);
    this.removeWinningFlatPlace = this.removeWinningFlatPlace.bind(this);
    this.handleNoPayoutPrizesChange = this.handleNoPayoutPrizesChange.bind(this);
    this.handlePayoutsRadio = this.handlePayoutsRadio.bind(this);
    this.handleTieRadio = this.handleTieRadio.bind(this);
    this.handleDisplayMoneyLeaders = this.handleDisplayMoneyLeaders.bind(this);
    this.toggleEstimator = this.toggleEstimator.bind(this);
    this.handleWithdrawAltRuleRadio = this.handleWithdrawAltRuleRadio.bind(this);
    this.handleNoBestGolfersScore = this.handleNoBestGolfersScore.bind(this);

    this.stringIsFloat = this.stringIsFloat.bind(this);

    this.terminateLeague = this.terminateLeague.bind(this);
    this.handleCreateStaffLeague = this.handleCreateStaffLeague.bind(this);

    this.handleAddlContstRuleTxt = this.handleAddlContstRuleTxt.bind(this);
    this.handleLeagueSlugPwd = this.handleLeagueSlugPwd.bind(this);

    this.webUrlCopyConfirmation = this.webUrlCopyConfirmation.bind(this);

    this.detectUnsavedData = this.detectUnsavedData.bind(this);
  }

  componentDidMount() {
    // TODO check if the Commissioner component knows we're on the members page, if not use callback

    // if (this.props.activeLeague !== paramLeague) {
    //   this.props.selectLeague(paramLeague);
    // }

    this.fetchLeagueSettings(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    // let paramLeague = parseInt(this.props.match.params.league, 10);

    // TODO check if the Commissioner component knows we're on the members page, if not use callback
    // if (this.props.activeLeague !== paramLeague) {
    //   this.props.selectLeague(paramLeague);
    // }

    if (this.props.activeLeague === nextProps.activeLeague) {
      return;
    }

    this.fetchLeagueSettings(nextProps.activeLeague);
  }

  fetchLeagueSettings(league) {
    if (!league) {
      return;
    }
    get_leaguesettings(league,
    ({success, settings, error}) => {
      if (success) {

        // Handle options logic
        settings.customMultiplier = "";
        settings.customFee = "";

        if (settings.variableMultiplier === undefined) {
          settings.variableMultiplier = "Custom";
        } else if (!this.state.multiplierOptions.includes( settings.variableMultiplier )) {
          settings.customMultiplier = settings.variableMultiplier;
          settings.variableMultiplier = "Custom";
        }

        if (settings.classicFee === undefined) {
          settings.classicFee = "Custom";
        } else if (this.state.feeOptions.includes( parseInt(settings.classicFee, 10) )) {
          settings.classicFee = parseInt(settings.classicFee, 10);
        } else {
          settings.customFee = settings.classicFee;
          settings.classicFee = "Custom";
        }

        if (settings.maxEntries === 0) {
          settings.maxEntries = "";
        }

        if (settings.maxUserEntries === 0) {
          settings.maxUserEntries = "";
        }

        if (settings.maxLoss === '0.00') {
          settings.maxLoss = "";
        }

        this.setState({
          settings,
          origLeagueName: settings.leagueName,
          origWinningPercentages: settings.winningPercentages.slice(),
          origWinningFlat: settings.winningFlat.slice(),
          success: null,
          message: null,
          origSettings: JSON.parse(JSON.stringify(settings)),
          unsavedData: false,
        });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  handleSave(event) {
    let settings = this.state.settings;

    const nMaxEntries = settings.maxEntries !== '' ? settings.maxEntries : 0;
    const oMaxEntries = this.state.origSettings.maxEntries !== '' ? this.state.origSettings.maxEntries : 0;
    if (nMaxEntries > 0 & oMaxEntries >= 0 && nMaxEntries !== oMaxEntries && nMaxEntries < 5){
      confirmAlert({
        title: 'Alert',
        message: 'You have chosen to limit the total entries allowed. This will cap your golf pool at ' + nMaxEntries + ' teams total. Perhaps you instead meant to limit the number of entries per user? See setting for “Maximum teams allowed per user”',
        buttons: [
          {
            label: 'Save Anyway',
            onClick: () => {
              this.saveLeaguesettings(event);

            }
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      })
    }else{
      this.saveLeaguesettings(event);
    }




  }

  saveLeaguesettings = (event) => {
    let settings = this.state.settings;

    let poolFactor;
    if (settings.variablePool) {
      poolFactor = settings.variableMultiplier === "Custom" ? settings.customMultiplier : settings.variableMultiplier;
    } else {
      poolFactor = settings.classicFee === "Custom" ? settings.customFee : settings.classicFee;
    }

    const winningPercentages = settings.winningPercentages.filter(perc => perc !== '');
    let winningFlat = settings.winningFlat.filter(flat => flat !== '');

    if (settings.usePrizeWinnings){
      winningFlat = [];
      for (let i=settings.noPayoutPrizes;i>0;i--){
        winningFlat.push(i+'.00');
      }
    }

    set_leaguesettings(
      this.props.activeLeague,
      settings.leagueName,
      settings.openMembership,
      settings.allowInvites,
      settings.maxEntries !== '' ? settings.maxEntries : 0,
      settings.maxUserEntries !== '' ? settings.maxUserEntries : 0,
      settings.maxLoss !== '' ? settings.maxLoss : 0.0,
      settings.variablePool,
      poolFactor,
      winningPercentages,
      winningFlat,
      (settings.usePrizeWinnings?true:settings.useFlatWinnings),
      settings.tieBreakRule,
      settings.allowWebCommunication,
      settings.allowGenCommunication,
      settings.addlContstRuleTxt,
      (settings.usePrizeWinnings?settings.noPayoutPrizes:0),
      settings.displayMoneyLeaders,
      settings.allowPublicAccess,
      settings.leagueSlugPwd,
      settings.withdrawAltRule,
      settings.noBestGolfersScore,
      settings.poolType,
      settings.seasonLongFee,
      settings.motd,
      settings.dailyLeaderboard,
      settings.isPrivate,
      ({success, error})=>{
        if (success) {
          this.setState({success: true,
            message: "",
            origSettings: JSON.parse(JSON.stringify(settings)),

          });
          this.detectUnsavedData();
        } else {
          this.setState({success: false, message: error});
        }
      },
      (error)=>{
        this.setState({success: false, message: "An unexpected error occurred while saving the league settings. If the problem persists, please contact Majors Challenge."});
      }
    )


  }

  handleChange(event) {
    if ((event.target.name === "maxEntries" || event.target.name === "maxUserEntries" || event.target.name === "maxLoss")
      && event.target.value === "0") {
      event.target.value = "";
    }

    let settings = this.state.settings;
    if (settings[event.target.name] === event.target.value) {
      return;
    }
    settings[event.target.name] = event.target.value;

    this.setState({settings,});

    this.detectUnsavedData();
  }


  handleWithdrawAltRuleRadio(event) {
    let settings = this.state.settings;
    settings.withdrawAltRule = event.target.id;
    this.setState({settings});

    this.detectUnsavedData();
  }


  handleOpenClubRadio(event) {
    let openMembership = event.target.id === "openMembership";

    let settings = this.state.settings;
    if (settings.openMembership === openMembership) {
      return;
    }
    settings.openMembership = openMembership;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleAllowWebCommunication(allowWebCommunication){
    let settings = this.state.settings;
    if (settings.allowWebCommunication === allowWebCommunication) {
      return;
    }
    settings.allowWebCommunication = allowWebCommunication;
    this.setState({settings});
    setTimeout(() => {this.saveLeaguesettings();}, 1000);
    //this.detectUnsavedData();
  }

  handleAllowGenCommunication = (allowGenCommunication) => {
    let settings = this.state.settings;
    if (settings.allowGenCommunication === allowGenCommunication) {
      return;
    }
    settings.allowGenCommunication = allowGenCommunication;
    this.setState({settings});
    setTimeout(() => {this.saveLeaguesettings();}, 1000);
    //this.detectUnsavedData();
  }

  handleDailyLeaderboard = (daily) => {
    let settings = this.state.settings;
    if (settings.dailyLeaderboard === daily) {
      return;
    }
    settings.dailyLeaderboard = daily;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleAllowPublicAccess(allowPublicAccess){
    let settings = this.state.settings;
    if (settings.allowPublicAccess === allowPublicAccess) {
      return;
    }
    //when set to public the commish cannot set League Access to Invite Only.
    if (!settings.isPrivate && !allowPublicAccess){
      return;
    }
    settings.allowPublicAccess = allowPublicAccess;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleIsPrivateAccess = (isPrivate)=>{
    let settings = this.state.settings;
    if (settings.isPrivate === isPrivate) {
      return;
    }
    settings.isPrivate = isPrivate;
    if (!isPrivate){
      //If the commish chooses Public value then automatically
      //set League Access to OPEN (allow_public_access_ind = true). Since public leagues
      //cannot be password protected, either disable entry here or hide it.
      settings.allowPublicAccess = true;
      settings.leagueSlugPwd = '';
    }
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleDisplayMoneyLeaders(displayMoneyLeaders){
    let settings = this.state.settings;
    if (settings.displayMoneyLeaders === displayMoneyLeaders) {
      return;
    }
    settings.displayMoneyLeaders = displayMoneyLeaders;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleInviteRadio(event) {
    let allowInvites = event.target.id === "memberInvite";

    let settings = this.state.settings;
    if (settings.allowInvites === allowInvites) {
      return;
    }
    settings.allowInvites = allowInvites;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleVariableRadio(event) {
    let settings = this.state.settings;

    if (event.target.id === "variablePool"){
      settings.variablePool = true;
      settings.poolType = 'variable';
      settings.seasonLongFee = '';
    }else if (event.target.id === "classicPool"){
      settings.variablePool = false;
      settings.poolType = 'classic';
      settings.seasonLongFee = '';
      settings.classicFee = 5;
    }else if (event.target.id === "seasonLongPool"){
      settings.variablePool = false;
      settings.poolType = 'seasonLong';
      settings.seasonLongFee = '';
      settings.classicFee = 'Custom';
      settings.customFee = '0.00';
      settings.useFlatWinnings = true;
      settings.maxUserEntries = 1;
    }

    this.setState({settings});

    this.detectUnsavedData();
  }

  handleMultiplierChange(variableMultiplier) {
    let settings = this.state.settings;
    if (settings.variableMultiplier === variableMultiplier) {
      return;
    }
    settings.variableMultiplier = variableMultiplier;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleCustomMultiplier(event) {
    let customMultiplier = event.target.value;

    if (!this.stringIsFloat(customMultiplier)) {
      return;
    }

    let settings = this.state.settings;
    if (settings.customMultiplier === customMultiplier) {
      return;
    }

    settings.customMultiplier = customMultiplier;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleFeeChange(classicFee) {
    let settings = this.state.settings;
    if (settings.classicFee === classicFee) {
      return;
    }
    settings.classicFee = classicFee;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleCustomFee(event) {
    let customFee = event.target.value;

    if (!this.stringIsFloat(customFee) || customFee === 'e') {
      return;
    }

    let settings = this.state.settings;

    if (settings.poolType === 'classic'){
      if (settings.customFee === customFee) {
        return;
      }
      settings.customFee = customFee;

    }else if (settings.poolType === 'seasonLong'){
      if (settings.seasonLongFee === customFee) {
        return;
      }
      settings.seasonLongFee = customFee;
    }
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleTeams(event) {
    let value = parseInt(event.target.value, 10);
    if (this.state.teams === value) {
      return;
    }
    this.setState({teams: value});

    this.detectUnsavedData();
  }

  handlePercWinningsChange(event) {
    let index = event.target.name;
    let value = event.target.value;

    if (!this.stringIsFloat(value)) {
      event.target.value = this.state.winningPercentages;
      return;
    }

    let settings = this.state.settings;

    if (settings.winningPercentages[index] === value) {
      return;
    } else {
      settings.winningPercentages[index] = value;
    }
    this.setState({settings});

    this.detectUnsavedData();
  }

  addWinningPercPlace(event) {
    let settings = this.state.settings;
    settings.winningPercentages.push("");
    this.setState({settings});

    this.detectUnsavedData();
  }

  removeWinningPercPlace(index) {
    let settings = this.state.settings;
    settings.winningPercentages.splice(index, 1);
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleFlatWinningsChange(event) {
    let index = event.target.name;
    let value = event.target.value;

    if (!this.stringIsFloat(value)) {
      event.target.value = this.state.winningFlat;
      return;
    }

    let settings = this.state.settings;

    if (settings.winningFlat[index] === value) {
      return;
    } else {
      settings.winningFlat[index] = value;
    }
    this.setState({settings});

    this.detectUnsavedData();
  }

  addWinningFlatPlace(event) {
    let settings = this.state.settings;
    settings.winningFlat.push("");
    this.setState({settings});

    this.detectUnsavedData();
  }

  removeWinningFlatPlace(index) {
    let settings = this.state.settings;
    settings.winningFlat.splice(index, 1);
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleNoPayoutPrizesChange(event) {
    let value = event.target.value;
    let settings = this.state.settings;
    settings.noPayoutPrizes = value
    this.setState({settings});

    this.detectUnsavedData();
  }

  handlePayoutsRadio(event) {
    let settings = this.state.settings;
    if (event.target.id === "prize"){
      settings.useFlatWinnings = false;
      settings.usePrizeWinnings = true;
      settings.noPayoutPrizes = 1;
      this.setState({settings});
      return;
    }
    settings.usePrizeWinnings = false;
    let useFlatWinnings = event.target.id === "flat";
    //if (settings.useFlatWinnings === useFlatWinnings) {
    //  return;
    //}
    settings.useFlatWinnings = useFlatWinnings;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleTieRadio(event) {
    let tieBreakRule = 'MAJORS';
    if (event.target.id === "splittiescut"){tieBreakRule='SPLIT_POINT_CUT';}
    else if (event.target.id === "splittiesnocut"){tieBreakRule='SPLIT_POINT_WITHOUT_CUT';}

    let settings = this.state.settings;
    if (settings.tieBreakRule === tieBreakRule) {
      return;
    }
    settings.tieBreakRule = tieBreakRule;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleAddlContstRuleTxt(event) {
    let addlContstRuleTxt = event.target.value;

    let settings = this.state.settings;
    if (settings.addlContstRuleTxt === addlContstRuleTxt) {
      return;
    }

    settings.addlContstRuleTxt = addlContstRuleTxt;
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleLeagueSlugPwd(event){
    let leagueSlugPwd = event.target.value;

    let settings = this.state.settings;
    if (settings.leagueSlugPwd === leagueSlugPwd) {
      return;
    }

    settings.leagueSlugPwd = leagueSlugPwd.trim();
    this.setState({settings});

    this.detectUnsavedData();
  }

  handleNoBestGolfersScore(event){
    //alert(event.target.value)
    let noBestGolfersScore = parseInt(event.target.value);
    //alert(noBestGolfersScore)
    let settings = this.state.settings;
    if (settings.noBestGolfersScore === noBestGolfersScore) {
      return;
    }
    settings.noBestGolfersScore = noBestGolfersScore;
    this.setState({settings});

    this.detectUnsavedData();
  }

  toggleEstimator(event) {
    let showEstimator = event.target.name === "showEstimator";
    if (showEstimator === this.state.showEstimator) {
      return;
    }
    this.setState({showEstimator});

    this.detectUnsavedData();
  }

  stringIsFloat(value) {
    if (value === "" ) {
      return true;
    }

    let endDot = false;
    if (value.slice(-1) === ".") {
      endDot = true;
      value = value.slice(0,-1);
    }

    if (endDot && value.indexOf('.') !== -1) {
      return false;
    }

    value = parseFloat(value);

    if (Number.isNaN(value)) {
      return false;
    }

    return true;
  }

  handleCreateStaffLeague(){
    this.setState({success: true, message: null});
    create_piggy_club(
      this.props.activeLeague,
      ({success, error, staffClubSlug, me})=>{
        //alert(staffClubSlug);
        if (success) {
          let settings = this.state.settings;
          settings.staffClubSlug = staffClubSlug;
          this.setState({success: true, 'settings':settings, message: "Staff League is created Sucessfully."});
          this.props.updateMe(me);
        } else {
          this.setState({success: false, message: error});
        }
      },
      (error)=>{
        this.setState({success: false, message: "An unexpected error occurred while creating Staff League. If the problem persists, please contact Majors Challenge."});
      }
    )

  }

  webUrlCopyConfirmation(){
    confirmAlert({
      title: 'Confirmation',
      message: 'League Web Address Copied to Clipboard',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
  }

  terminateLeague(){
    //this.props.updateMe({});
    confirmAlert({
      title: 'Alert',
      message: 'Terminating your league will deny access to all your members. Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            set_leaguestatus(this.props.activeLeague,'inactive',
            ({success, refresh, me, error}) => {
              if (success) {
                this.props.updateMe(me);
                this.props.history.push('/');
              } else {
                console.log(error);
              }
            }, (error) => {
              console.log(error);
            });
          }
        },
        {
          label: 'Cancel',
          onClick: () => { }
        }
      ]
    });


  }


  detectUnsavedData(){
    let origSettings = JSON.stringify(this.state.origSettings);
    let settings = JSON.stringify(this.state.settings);
    //console.log(origSettings);
    //console.log(settings);
    let unsavedData = (origSettings !== settings ? true : false);
    this.setState({unsavedData:unsavedData});
  }


  render() {
    let contentParams = {...this.state.settings}
    contentParams.multiplierOptions = this.state.multiplierOptions;
    contentParams.feeOptions = this.state.feeOptions;
    contentParams.teams = this.state.teams;
    contentParams.showEstimator = this.state.showEstimator;
    contentParams.origLeagueName = this.state.origLeagueName;
    contentParams.origWinningPercentages = this.state.origWinningPercentages;
    contentParams.origWinningFlat = this.state.origWinningFlat;
    contentParams.success = this.state.success;
    contentParams.message = this.state.message;

    contentParams.handleChange = this.handleChange;
    contentParams.handleOpenClubRadio = this.handleOpenClubRadio;
    contentParams.handleAllowWebCommunication = this.handleAllowWebCommunication;
    contentParams.handleAllowGenCommunication = this.handleAllowGenCommunication;
    contentParams.handleAllowPublicAccess = this.handleAllowPublicAccess;
    contentParams.handleIsPrivateAccess = this.handleIsPrivateAccess;
    contentParams.handleInviteRadio = this.handleInviteRadio;
    contentParams.handleVariableRadio = this.handleVariableRadio;
    contentParams.handleMultiplierChange = this.handleMultiplierChange;
    contentParams.handleCustomMultiplier = this.handleCustomMultiplier;
    contentParams.handleFeeChange = this.handleFeeChange;
    contentParams.handleCustomFee = this.handleCustomFee;
    contentParams.handleTeams = this.handleTeams;
    contentParams.handlePercWinningsChange = this.handlePercWinningsChange;
    contentParams.handleDisplayMoneyLeaders = this.handleDisplayMoneyLeaders;
    contentParams.addWinningPercPlace = this.addWinningPercPlace;
    contentParams.removeWinningPercPlace = this.removeWinningPercPlace;
    contentParams.handleFlatWinningsChange = this.handleFlatWinningsChange;
    contentParams.addWinningFlatPlace = this.addWinningFlatPlace;
    contentParams.removeWinningFlatPlace = this.removeWinningFlatPlace;
    contentParams.handleNoPayoutPrizesChange = this.handleNoPayoutPrizesChange;
    contentParams.handlePayoutsRadio = this.handlePayoutsRadio;
    contentParams.handleTieRadio = this.handleTieRadio;
    contentParams.toggleEstimator = this.toggleEstimator;
    contentParams.handleWithdrawAltRuleRadio = this.handleWithdrawAltRuleRadio;
    contentParams.handleNoBestGolfersScore = this.handleNoBestGolfersScore;
    contentParams.handleDailyLeaderboard = this.handleDailyLeaderboard;

    contentParams.handleSave = this.handleSave;

    contentParams.terminateLeague = this.terminateLeague;

    contentParams.handleCreateStaffLeague = this.handleCreateStaffLeague;

    contentParams.handleAddlContstRuleTxt = this.handleAddlContstRuleTxt;
    contentParams.handleLeagueSlugPwd = this.handleLeagueSlugPwd;

    contentParams.webUrlCopyConfirmation = this.webUrlCopyConfirmation;


    let flashMessageCls = (!!this.state.success ? "flash-message flash-success" : "flash-message flash-error");
    const charitySts = (this.props.selectedLeague && this.props.selectedLeague.league_charity_status)? this.props.selectedLeague.league_charity_status:'';

    const charityUnderReview = (['IN_REVIEW','REJECTED'].indexOf(charitySts)>-1)?true:false;

    contentParams.settingsType = this.props.settingsType;
    return (
      <div>
        {!!this.state.message &&
          <FlashMessage duration={3000} >
            <div className={flashMessageCls}>
              {!!this.state.message && this.state.message}
            </div>
          </FlashMessage>
        }
        <MobileHeader to={this.props.settingsType === 'GENERAL' ? 'League Settings' : 'Settings'} leagueid={this.props.activeLeague} />
        <ContentWrapper>
          {this.props.balancewarning && <LeagueBalanceWarning {...this.props} />}
          <SettingsHeader league={this.props.activeLeague} unsavedData={this.state.unsavedData} settingsType={this.props.settingsType}/>
          {charityUnderReview &&
            <Col sm="12" md="10" lg="10" style={{color: "white", padding: "0", backgroundColor:"red", fontWeight:'600'}} className="mt-2 pl-1 text-center">
              {charitySts==='REJECTED'?"Application Rejected":"Application Under Review"}
            </Col>
          }
          {!charityUnderReview &&
            <SettingsContent {...contentParams} />
          }
        </ContentWrapper>
      </div>
    );
  }
}

const SettingsHeader = (props) => (
  <div className="">
    <div className="d-none d-lg-block pb-4" style={{fontSize: "1.75em", fontWeight: 300}}>
      {props.settingsType === 'GENERAL' ? 'League Settings' : 'Settings'}
    </div>
    {props.settingsType === 'WEEKLY' &&
    <Col sm="12" md="9" lg="10" style={{color: "#999", padding: "0"}} className="pb-4">
      {"These are your league's default settings for all upcoming events. "}
      {"You can also override event-specific settings from the Weekly Tournaments page. "}
    </Col>
    }
    {props.unsavedData &&
      <Col sm="12" md="9" lg="7"
        style={{color: "white", padding: "0", backgroundColor:"red", fontWeight:'600'}}
        className="mt-2 pl-1 text-center mb-4">
        {"UNSAVED CHANGES: Scroll Down to Save"}
      </Col>
    }
  </div>
)

const SettingsContent = (props) => {
  if (props.loading) {
    return null;
  }

  return (
    <Col sm="12" md="9" lg="10">
      {props.settingsType === 'GENERAL' &&
      <>
      <LeagueName {...props} />
      <Divider />
      <LeagueMotd {...props} />
      {!props.clubSlug && <><Divider /><AllowPublicAccess {...props} /></>}
      <Divider /><IsPrivateAccess {...props} />
      <Divider />
      {props.clubSlug && <AllowOpenMembership {...props} />}
      {props.clubSlug && <Divider />}
      {props.clubSlug && <Notices {...props} />}
      {props.clubSlug && <Divider />}
      <AllowInvites {...props} />
      </>
      }
      {props.settingsType === 'WEEKLY' &&
      <>
      <MaxEntries {...props} />
      {props.poolType !== 'seasonLong' &&
      <>
      <Divider />
      <MaxUserEntries {...props} />
      </>
      }
      {props.poolType === 'variable' &&
        <>
        <Divider />
        <MaxLoss {...props} />
        </>
        }
        <Divider />
        <LeaderboardCalculationBestScorers {...props} />
        <Divider />
        <PointPool {...props} />
        <Divider />
        <WinningPlaces {...props} />
        <Divider />
        <ContestRules {...props} />
        <Divider />
        <WithdrawAltRule {...props} />
        <Divider />
        <TieBreaker {...props} />
      </>
      }

      <Divider />
      <SaveSettings {...props} />
    </Col>
  )
}

const Divider = () => <Row style={{borderTop:"1px solid #ccc"}} className="mt-3 mb-3" />

const LeagueName = (props) => {

  let valid = undefined;
  if (props.leagueName !== props.origLeagueName) {
    valid = 3 <= props.leagueName.length && props.leagueName.length <= 33;
  }

  return (
    <div>
      <Row className="contentTitle pb-2">
        League Name
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        The name must be between three and thirty letters long.
      </Row>
      <Row>
        <Input valid={valid} placeholder="Enter league name" value={props.leagueName} name="leagueName" onChange={props.handleChange} maxLength="33"/>
      </Row>
    </div>
  )
}

const LeagueMotd = (props) => {

  return (
    <div>
      <Row className="contentTitle pb-2">
        League Announcement
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        An empty message will hide the commissioner announcement on the League home page.
      </Row>
      <Row>
        <Input type="textarea" placeholder="Message to display on the League homepage." style={{fontSize:'.8rem'}}
        value={props.motd} name="motd"  onChange={props.handleChange} rows="6"/>
      </Row>
    </div>
  )
}

const AllowOpenMembership = (props) =>
  <div>
    <Row className="contentTitle">
      League Access {/*<Badge color="success" style={{marginLeft: "0.5rem"}}>Club Only</Badge>*/}
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      <p>Your league has a dedicated web address. Share this address in emails, newsletters, online posts or where ever else you communicate with your members.</p>
      <p>
        <span className="mr-2" style={{color: "#555",textTransform: 'lowercase'}}> {window.location.host + '/club/'+props.clubSlug} </span>
        <CopyToClipboard text={window.location.origin + '/club/'+props.clubSlug}>
          <Button color="primary" size="sm" outline><FontAwesome name="copy" /> Copy</Button>
        </CopyToClipboard>
      </p>
      Anyone visiting your web address is able to join your league.  You can disable public
      access below, in which case you'll need to individually invite members.  We recommend
      leaving the league open and monitoring your users in the Members section.
    </Row>
    <Row className="pt-2 pl-2" noGutters>
      <Col>
        <Input type="radio" name="membership" id="openMembership" className="mr-3" value={!!props.openMembership} checked={!!props.openMembership} onChange={props.handleOpenClubRadio} />
        <span style={{color:"#555"}}> Membership is open to anyone visiting your club address. </span>
      </Col>
    </Row>
    <Row className="pl-2" noGutters>
      <Col>
        <Input type="radio" name="membership" id="closedMembership" className="mr-3" value={!props.openMembership} checked={!props.openMembership} onChange={props.handleOpenClubRadio} />
        <span style={{color:"#555"}}> Memberships is closed. Requires manual invitations. </span>
      </Col>
    </Row>
  </div>


const Notices = (props) =>
    <div>
      <Row className="contentTitle">
        Notices
      </Row>
      <div class="d-flex flex-nowrap mt-3 ml-n3">
        <div className="ml-0 mt-1">
          {'Allow League Notices:'}
        </div>
        <div className="ml-2">
          <Bootstrap2Toggle
            onClick={(state)=>{props.handleAllowWebCommunication(state)}}
            on={'Yes'}
            off={'No'}
            size="sm"
            offstyle="secondary"
            onstyle="success"
            active={props.allowWebCommunication}/>
        </div>
      </div>
      <Row className="pb-0" style={{fontSize: ".8rem", color: "#999"}}>
        <p>
          When switched off, your members will not receive league-related emails from Majors Challenge. You'll be responsible for communicating roster reminders, leaderboard links, and event wrap-ups.
        </p>
      </Row>
      <div class="d-flex flex-nowrap mt-0 ml-n3">
        <div className="ml-0 mt-1">
          {'Allow General Notices:'}
        </div>
        <div className="ml-2">
          <Bootstrap2Toggle
            onClick={(state)=>{props.handleAllowGenCommunication(state)}}
            on={'Yes'}
            off={'No'}
            size="sm"
            offstyle="secondary"
            onstyle="success"
            active={props.allowGenCommunication}/>
        </div>
      </div>
      <Row className="pb-0" style={{fontSize: ".8rem", color: "#999"}}>
        <p className="mb-0">
          Occasionally, Majors Challenge sends emails to all users, featuring special contests, new features, and general information unrelated to league activities.
        </p>
      </Row>
      {(props.isItChildClub === false && props.leagueType !== 'CHARITY' && props.isGolfClub) &&
      <>
      <Divider />
      <div class="d-flex flex-nowrap mt-0 ml-n3">
        <div className="mb-1">
            <Button color={props.staffClubSlug?'secondary':'primary'} onClick={props.handleCreateStaffLeague} disabled={props.staffClubSlug}>Create Staff League</Button>
        </div>
      </div>
      <Row className="pb-0" style={{fontSize: ".8rem", color: "#999"}}>
        <p className="mb-0">
          Included in your subscription you can create an additional pool for the staff. This is a completely independent pool with a separate membership, entry and prize.
        </p>
        {props.staffClubSlug &&
        <p>
          <span className="mr-2" style={{color: "#555"}}> {window.location.host + '/club/'+props.staffClubSlug} </span>
          <CopyToClipboard text={window.location.origin + '/club/'+props.staffClubSlug}>
            <Button color="primary" size="sm" outline><FontAwesome name="copy" /> Copy</Button>
          </CopyToClipboard>
        </p>
        }
      </Row>
      </>
      }
  </div>



  const AllowInvites = (props) =>
    <div>
      <Row className="contentTitle">
        Allow Member Invitations
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        When enabled, members will see an Invite Friends button that can be used to invite others to join your golf pool. Invitations are tracked so you can see who vouched for the new member. When disabled, only commissioners are able to send invites.
      </Row>
      <Row className="pt-2 pl-2" noGutters>
        <Col>
          <Input type="radio" name="invites" id="memberInvite" className="mr-3" value={!!props.allowInvites} checked={!!props.allowInvites} onChange={props.handleInviteRadio} />
          <span style={{color:"#555"}}> All members may send invites </span>
        </Col>
      </Row>
      <Row className="pl-2" noGutters>
        <Col>
          <Input type="radio" name="invites" id="commissionerInvite" className="mr-3" value={!props.allowInvites} checked={!props.allowInvites} onChange={props.handleInviteRadio} />
          <span style={{color:"#555"}}> Only commissioners may send invites </span>
        </Col>
      </Row>
    </div>

const AllowPublicAccess = (props) =>{
  let slugClipTxt = window.location.origin + '/ileague/'+ props.leagueSlug ;
  //if (props.leagueSlugPwd){
  //  slugClipTxt =  slugClipTxt + ' / Password: ' + props.leagueSlugPwd;
  //}

  return (
  <div className="mt-2">
    <Row className="contentTitle">
      League Access
    </Row>
    <Row className="py-2" style={{fontSize: ".8rem", color: "#999"}}>
      Open leagues allow anyone to join your golf pool using a simple web link. Be aware there is no traceability on where a user came from with this feature. Open leagues are easy to socialize via text, email or posting online. Consider adding a password to restrict access.
    </Row>
    <Row className="pl-0 ml-n3 mt-2" noGutters style={{fontSize: ".9rem",}}>
      <Col>
        <span style={{color:"#555", fontWeight:500}}>League Access: </span>
        <Bootstrap2Toggle
          onClick={(state)=>{props.handleAllowPublicAccess(state)}}
          on={'Open'}
          off={'Invite Only'}
          offstyle="secondary"
          onstyle="success"
          active={props.allowPublicAccess}
          width="120px"/>
      </Col>
    </Row>

    {props.allowPublicAccess &&
      <>
        <Row className="pt-3 ml-n3 " noGutters style={{fontSize: ".9rem",}}>
          <Col>
          <p>
            <span className="mr-2" style={{color: "#555"}}>
              <span className="text-nowrap">
                <span style={{textDecoration:'',textTransform: 'lowercase'}}>{window.location.host + '/ileague/'+props.leagueSlug}</span>
                <CopyToClipboard text= {slugClipTxt}>
                  <Button color="primary" size="sm" outline className="ml-2 mt-n1"
                  onClick={()=>props.webUrlCopyConfirmation()}>
                  Copy</Button>
                </CopyToClipboard>
              </span>
            </span>
          </p>
          </Col>
        </Row>
        {props.isPrivate &&
        <Row className="pt-0 pb-0 ml-n3 mt-n2" noGutters style={{fontSize: ".9rem",}}>
          <Col xs="3" md="2" className="mt-1">
            <span style={{color:"#555", verticalAlign:"sub", fontWeight:500}}> Password: </span>
          </Col>
          <Col xs="6" md="4">
            <Input placeholder="optional" value={props.leagueSlugPwd} onChange={props.handleLeagueSlugPwd}  style={{maxWidth:'200px'}}/>
          </Col>
        </Row>
        }
      </>
    }

  </div>
  )

}

const IsPrivateAccess = (props) =>{


  return (
  <div className="mt-2">
    <Row className="contentTitle">
      Privacy Settings
    </Row>
    <Row className="py-1" style={{fontSize: ".8rem", color: "#999"}}>
      Setting your league to public will showcase your league in the list of public golf pools on Majors Challenge. Public leagues are open for anyone to join, and they cannot be password protected.
    </Row>
    <Row className="pl-0 ml-n3 mt-2" noGutters style={{fontSize: ".9rem",}}>
      <Col>
        <span style={{color:"#555", fontWeight:500}}>Privacy Type: </span>
        <Bootstrap2Toggle
          onClick={(state)=>{props.handleIsPrivateAccess(state)}}
          on={'Private'}
          off={'Public'}
          offstyle="success"
          onstyle="secondary"
          active={props.isPrivate}/>
      </Col>
    </Row>

  </div>
  )

}

const MaxEntries = (props) =>
  <div>
    <Row className="contentTitle">
      Maximum teams allowed in each tournament
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      Total number of entries allowed in each event. (0 or blank implies No Limit.)
    </Row>
    <Row>
      <Col sm="3" style={{padding: 0}}>
        <Input placeholder="No Limit" value={props.maxEntries} name="maxEntries" onChange={props.handleChange} type="number"/>
      </Col>
    </Row>
  </div>

const MaxUserEntries = (props) =>
  <div>
    <Row className="contentTitle">
      Maximum teams allowed per user
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      Total number of entries per member allowed in each event. (0 or blank implies No Limit.)
    </Row>
    <Row>
      <Col sm="3" style={{padding: 0}}>
        <Input placeholder="No Limit" value={props.maxUserEntries} name="maxUserEntries" onChange={props.handleChange} type="number"/>
      </Col>
    </Row>
  </div>

const MaxLoss = (props) =>
  <div>
    <Row className="contentTitle">
      Maximum loss per team
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      The maximum each entry can lose in a single event. (0 or blank implies No Limit; only applies to Variable point pools.)
    </Row>
    <Row>
      <Col sm="4" style={{padding: 0}}>
        <InputGroup>
          <Input placeholder="No Limit" value={props.maxLoss} name="maxLoss" onChange={props.handleChange} type="number"/>
        </InputGroup>
      </Col>
    </Row>
  </div>

const LeaderboardCalculationBestScorers = (props) => {

    return (
      <div>
        <Row className="contentTitle">
          Leaderboard Calculation
        </Row>
        <Row className="pb-2 pt-2" style={{fontSize: ".8rem", color: "#999"}}>
          A teams position on the leaderboard is based on the sum of its golfer scores. How many golfers would you like to count towards each team score? Picking fewer than 12 golfers will drop the worst scores.
        </Row>
        <Row className="pb-2 pt-2" style={{}}>
          <Col sm="12" md="9" style={{paddingLeft:'0px'}}>
            <Row className="pb-2">
              <Col className="d-flex flex-nowrap">
                <Input type="select" value={props.noBestGolfersScore} name="noBestGolfersScore" id="noBestGolfersScore"
                  className="mx-1" style={{height:"2em"}} onChange={props.handleNoBestGolfersScore}>
                  <option value="12">Use All 12 Golfers</option>
                  <option value="11">Best 11 Scores (drop worst)</option>
                  <option value="10">Best 10 Scores (drop 2 worst)</option>
                  <option value="9">Best 9 Scores (drop 3 worst)</option>
                  <option value="8">Best 8 Scores (drop 4 worst)</option>
                  <option value="7">Best 7 Scores (drop 5 worst)</option>
                </Input>
              </Col>
            </Row>
          </Col>
        </Row>
        <div class="d-flex flex-nowrap mt-3 ml-n3">
          <div className="ml-0">
            {'Show Daily Leaderboards:'}
          </div>
          <div className="ml-2">
            <Bootstrap2Toggle
              onClick={(state)=>{props.handleDailyLeaderboard(state)}}
              on={'Yes'}
              off={'No'}
              size="sm"
              offstyle="secondary"
              onstyle="success"
              active={props.dailyLeaderboard}/>
          </div>
        </div>
        <Row className="pb-0" style={{fontSize: ".8rem", color: "#999"}}>
          <p>
            Daily leaderboards calculate a new winner each round based on golfer scores for that day only. Existing settings for leaderboard calculation, alternates and tie breakers still apply. Only switch this on if you want to have daily competitions in addition to your regular contest.
          </p>
        </Row>
      </div>
    )
  }




const PointPool = (props) => {
  let validCustomMultiplier = undefined;
  let validMultiplierBorderWidth = "1px";
  let validCustomFee = undefined;
  let validFeeBorderWidth = "1px";

  if (props.customMultiplier !== "" || props.success === false) {
    validCustomMultiplier = props.customMultiplier > 0;
  }

  if (props.poolType === 'classic'){
    if (props.customFee !== "" || props.success === false) {
      validCustomFee = props.customFee > 0;
    }
  }else if (props.poolType === 'seasonLong'){
    if (props.seasonLongFee !== "" || props.success === false) {
      validCustomFee = props.seasonLongFee > 0;
    }
  }

  return (
    <div>
      <Row className="contentTitle">
        Pool Type
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        What are the stakes you're playing for and how should this be calculated into a winning purse?
      </Row>
      {props.leagueType === 'CHARITY' &&
      <Row className="pb-2" style={{fontSize: ".90rem", fontWeight:"500"}}>
        This league is set up for charity.
      </Row>
      }

      <Row className="pt-2 pb-2 pl-2">
        <Col>
          <Input type="radio" name="pointpool" id="classicPool" className="mr-3"
            value={props.poolType} checked={props.poolType==='classic'} onChange={props.handleVariableRadio} />
          <span style={{color:"#555"}}>{props.leagueType === 'CHARITY'?'Recommended Donation':'Entry Fee'}</span>
          <div style={{fontSize:".85em", color:"#999"}}>
            {props.leagueType === 'CHARITY'?'The recommended donation will pre-populate on the payments page':'Each entry owes the same amount towards the tournament purse.'}
          </div>
        </Col>
      </Row>
      { props.poolType==='classic' &&
        <div>
          <Row>
            <Col sm="12" md="8">
              <ButtonGroup className="pb-2 pl-2">
                {
                  props.feeOptions.map((item, index) => (
                    <Button className="poolOption" onClick={() => props.handleFeeChange(item)} active={props.classicFee === item} key={index}>
                      {item !== "Custom"}{item}
                    </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
            { props.classicFee === "Custom" &&
              <Row>
                <Col sm="6" className="pt-sm-2 pt-md-0 pb-2">
                  <Input type="number" value={props.customFee} valid={validCustomFee} className="ml-2" style={{borderWidth: validFeeBorderWidth, fontSize: ".8em"}} onChange={props.handleCustomFee} placeholder="e.g. 10"/>
                </Col>
              </Row>
            }
          {/*props.showEstimator ?
            <div>
              <Row>
                <Card body>
                  <ClassicEstimator {...props} />
                  <Row className="mt-3" style={{fontSize:".85rem", color:"#999"}}>
                    <Col>
                      This is an example payout structure with 3 winnings places. You may customize the number of winners, and their winning shares below.
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="hideEstimator" onClick={props.toggleEstimator}>
                  Hide the example payout structure
                </output>
              </Row>
            </div>
            :
            <Row>
              <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="showEstimator" onClick={props.toggleEstimator}>
                View an example payout structure
              </output>
            </Row>
          */}
        </div>
      }
      {props.leagueType !== 'CHARITY' &&
      <Row className="pt-2 pb-2 pl-2">
        <Col>
          <Input type="radio" name="pointpool" id="variablePool" className="mr-3"
           value={props.poolType} checked={props.poolType==='variable'}  onChange={props.handleVariableRadio} />
          <span style={{color:"#555"}}>Stroke Play</span>
          <div style={{fontSize:".85em", color:"#999"}}>
            {"Stroke play is a variable format similar to stroke play golf. If a team is 10 strokes off the lead then they owe 10 into the purse. Just like golf you don't know your loss until the tournament ends. This format can produce very large purses."}
          </div>
          {props.poolType==='variable' &&
          <div style={{fontSize:".85em", color:"#999", paddingTop:"10px"}}>
            How much per stroke would you like to play for?
          </div>
          }
        </Col>
      </Row>
      }
      {props.poolType==='variable' &&
        <div>
          <Row>
            <Col sm="12" md="8">
              <ButtonGroup className="pb-2">
                {
                  props.multiplierOptions.map((item, index) => (
                    <Button className="poolOption" onClick={() => props.handleMultiplierChange(item)} active={props.variableMultiplier === item} key={index}>
                      {item}
                    </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
            { props.variableMultiplier === "Custom" &&
              <Row>
                <Col sm="6" className="pt-sm-2 pt-md-0 pb-2">
                  <Input type="number" value={props.customMultiplier} valid={validCustomMultiplier} style={{borderWidth: validMultiplierBorderWidth, fontSize: ".8em"}} onChange={props.handleCustomMultiplier} placeholder="e.g. 2.0"/>
                </Col>
              </Row>
            }
          {props.showEstimator ?
            <div>
              <Row>
                <Card body>
                  <VariableEstimator {...props} />
                  <Row className="mt-3" style={{fontSize:".85rem", color:"#999"}}>
                    <Col>
                      This is an example payout structure with 3 winnings places. You may customize the number of winners, and their winning shares below.
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="hideEstimator" onClick={props.toggleEstimator}>
                  Hide the example payout structure
                </output>
              </Row>
            </div>
            :
            <Row className="pl-2">
              <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="showEstimator" onClick={props.toggleEstimator}>
                View an example payout structure
              </output>
            </Row>
          }
        </div>
      }
      {props.leagueType !== 'CHARITY' &&
      <Row className="pt-2 pb-2 pl-2">
        <Col>
          <Input type="radio" name="pointpool" id="seasonLongPool" className="mr-3"
            value={props.poolType} checked={props.poolType==='seasonLong'} onChange={props.handleVariableRadio} />
          <span style={{color:"#555"}}>One-Time Season Long Payment</span>
          <div style={{fontSize:".85em", color:"#999"}}>
            Collect once for entry into tournaments all season long. You'll need to determine your total collections and manage fixed payout amounts when choosing this option. Players are also limited to one entry per tournament.
          </div>
        </Col>
      </Row>
      }
      { props.poolType==='seasonLong' &&
        <div>
            <Row>
              <Col sm="6" className="pt-sm-2 pt-md-0 pb-2">
                <Input type="number" value={props.seasonLongFee} valid={validCustomFee} className="ml-2"
                 style={{borderWidth: validFeeBorderWidth, fontSize: ".8em"}} onChange={props.handleCustomFee} placeholder="e.g. 10"/>
              </Col>
            </Row>
        </div>
      }
    </div>
  )
}

const VariableEstimator = (props) => {
  let multiplier = props.variableMultiplier === "Custom" ? props.customMultiplier : props.variableMultiplier;
  let points = props.teams * multiplier;

  if (props.teams < 20) {
    points *= 15;
  } else if (props.teams < 50) {
    points *= 30;
  } else {
    points *= 60;
  }

  let pool = numeral(points).format('0,0[.]00');
  let loss = numeral(points/props.teams).format('0,0[.]00')
  let win1 = numeral(65 * points / 100).format('0,0[.]00');
  let win2 = numeral(25 * points / 100).format('0,0[.]00');
  let win3 = numeral(10 * points / 100).format('0,0[.]00');

  return (
    <div style={{color:"#555"}} className="estimator">
      <Row className="pb-3" style={{fontWeight: "500"}}>
        <Col> Estimated Projections per Event </Col>
      </Row>
      <TeamCount {...props} />
      <Row noGutters>
        <Col> Estimated point pool </Col>
        <Col> {pool} </Col>
      </Row>
      <Row noGutters>
        <Col> Average loss <span style={{color:"#999"}}>(per team)</span> </Col>
        <Col> {loss} </Col>
      </Row>
      <Row noGutters className="pt-3">
        <Col> {"1st Place"} </Col>
        <Col> {win1} <span style={{color:"#999"}}>(65%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"2nd Place"} </Col>
        <Col> {win2} <span style={{color:"#999"}}>(25%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"3rd Place"} </Col>
        <Col> {win3} <span style={{color:"#999"}}>(10%)</span> </Col>
      </Row>
    </div>
  )
}

/*const ClassicEstimator = (props) => {
  let fee = props.classicFee === "Custom" ? props.customFee : props.classicFee;
  let points = props.teams * fee;
  let pool = numeral(points).format('0,0[.]00');
  let win1 = numeral(65 * points / 100).format('0,0[.]00');
  let win2 = numeral(25 * points / 100).format('0,0[.]00');
  let win3 = numeral(10 * points / 100).format('0,0[.]00');

  return (
    <div style={{color:"#555"}} className="estimator">
      <Row className="pb-3" style={{fontSize: "1rem", fontWeight: "500"}}>
        <Col> Payout Structure </Col>
      </Row>
      <TeamCount {...props} />
      <Row noGutters className="pt-3">
        <Col> Point pool </Col>
        <Col> {pool} </Col>
      </Row>
      <Row noGutters className="pt-3">
        <Col> {"1st Place"} </Col>
        <Col> {win1} <span style={{color:"#999"}}>(65%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"2nd Place"} </Col>
        <Col> {win2} <span style={{color:"#999"}}>(25%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"3rd Place"} </Col>
        <Col> {win3} <span style={{color:"#999"}}>(10%)</span> </Col>
      </Row>
    </div>
  )
}*/

const TeamCount = (props) =>
  <Row style={{color:"#999"}}>
    <Col className="d-flex">
      <span style={{lineHeight:"2rem"}}> How many teams do you think you will have: </span>
      <Input type="select" value={props.teams} name="entryCount" id="exampleEntryCount" className="mx-1" style={{width:"5em", color:"#999", height:"2em"}} onChange={props.handleTeams}>
        <option>4</option>
        <option>6</option>
        <option>8</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>30</option>
        <option>40</option>
        <option>50</option>
        <option>75</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
        <option>300</option>
        <option>400</option>
        <option>500</option>
        <option>600</option>
      </Input>
    </Col>
  </Row>

const WinningPlaces = (props) => (
  <div>
    <Row className="contentTitle">
      Winning Places and Payouts
    </Row>
    {(props.poolType !== 'seasonLong' && props.leagueType !== 'CHARITY') &&
    <Row className="pt-2 pl-2" noGutters>
      <Col>
        <Input type="radio" name="winningPlaces" id="percentage" className="mr-3"
          value={!props.useFlatWinnings && !props.usePrizeWinnings} checked={!props.useFlatWinnings && !props.usePrizeWinnings}
          onChange={props.handlePayoutsRadio}/>
        <span style={{color:"#555"}}> Payout in Percentages</span>
      </Col>
    </Row>
    }
    {props.leagueType !== 'CHARITY' &&
    <Row className="pl-2" noGutters>
      <Col>
        <Input type="radio" name="winningPlaces" id="flat" className="mr-3" value={!!props.useFlatWinnings && !props.usePrizeWinnings}
          checked={!!props.useFlatWinnings && !props.usePrizeWinnings} onChange={props.handlePayoutsRadio} />
        <span style={{color:"#555"}}> Payout Fixed Amounts</span>
      </Col>
    </Row>
    }
    <Row className="pl-2 mb-2" noGutters>
      <Col>
        <Input type="radio" name="winningPlaces" id="prize" className="mr-3" value={!!props.usePrizeWinnings} checked={!!props.usePrizeWinnings} onChange={props.handlePayoutsRadio} />
        <span style={{color:"#555"}}> Payout in Prizes</span>
      </Col>
    </Row>
    {props.usePrizeWinnings? PrizeWinningPlaces(props) : props.useFlatWinnings ? FlatWinningPlaces(props) : PercWinningPlaces(props)}
    {props.leagueType !== 'CHARITY' &&
    <>
    <div className="d-flex flex-nowrap mt-3 ml-n3">
      <div className="ml-0 mt-1">
        {'Display Money Leaders on League Home:'}
      </div>
      <div className="ml-2">
        <Bootstrap2Toggle
          onClick={(state)=>{props.handleDisplayMoneyLeaders(state)}}
          on={'Yes'}
          off={'No'}
          size="sm"
          offstyle="secondary"
          onstyle="success"
          active={props.displayMoneyLeaders}/>
      </div>
    </div>
    <div class="d-flex flex-nowrap mt-0 ml-n3">
      <div style={{fontSize:".85em", color:"#999"}}>
        The money leaders list adds up total player winnings from every event in your season.
      </div>
    </div>
    </>
    }
  </div>
);

const FlatWinningPlaces = (props) => {
  let total = 0.0;

  for (var flat of props.winningFlat) {
    if (flat === "") {
      continue;
    }

    flat = ""+flat;
    if (flat.slice(-1) === ".") {
      flat = flat.slice(0,-1);
    }

    total += parseFloat(flat);
  }

  function removeEvent(index) {
    return (event) => {
      props.removeWinningFlatPlace(index);
      event.stopPropagation();
      event.preventDefault();
    }
  }

  let removable = props.winningFlat.length > 1;
  let rows = props.winningFlat.map((amount, index) =>
    <Row key={index} className="pt-1">
      <Col xs="10">
        <InputGroup>
          <InputGroupAddon style={{width: "2rem", lineHeight: "2rem"}} addonType="prepend">
            <div>{index+1}</div>
          </InputGroupAddon>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <Input placeholder="0" value={amount} className="text-center" name={index} onChange={props.handleFlatWinningsChange} type="number" />
        </InputGroup>
      </Col>
      {removable &&
        <Col xs="2">
          <Button className="mt-2 pt-0 pb-0 pl-1 pr-1" color="danger" onClick={ removeEvent(index) } >
            <FontAwesome name="times" />
          </Button>
        </Col>
      }
    </Row>
  )

  let unchanged = props.winningFlat.length === props.origWinningFlat.length &&
                  props.winningFlat.every((curr, index) => { return curr === props.origWinningFlat[ index ]});

  let valid = undefined;
  if (!unchanged) {
    valid = (props.useFlatWinnings?true:(total <= 100)) ;
  }
  let validBorderWidth = valid === undefined ?
                            "1px" :
                            valid === true ?
                              "2px" :
                              "3px";

  return (
    <div>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Fixed payouts award a fixed amount to winning places regardless of collections or how many teams have entered. Use this method to better control winnings for individual tournaments or to allocate collections when choosing one-time season long payment. Fixed payouts is also useful for promotional events where the Fixed Fee is set to $0.
      </Row>
      <Col sm="9" md="6" >
        <Row className="pb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">Total</InputGroupAddon>
            <Input value={ numeral(total).format('$0[.]000') } readOnly valid={valid} invalid={valid===false} style={{borderWidth: validBorderWidth}} />
          </InputGroup>
        </Row>
        {rows}
        <Row className="pt-1">
          <span onClick={props.addWinningFlatPlace} style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} >
            Add Winning Place
          </span>
        </Row>
      </Col>
    </div>
  )
}

const PercWinningPlaces = (props) => {
  let total = 0.0;

  for (var perc of props.winningPercentages) {
    if (perc === "") {
      continue;
    }

    perc = ""+perc;
    if (perc.slice(-1) === ".") {
      perc = perc.slice(0,-1);
    }

    total += parseFloat(perc);
  }

  function removeEvent(index) {
    return (event) => {
      props.removeWinningPercPlace(index);
      event.stopPropagation();
      event.preventDefault();
    }
  }

  let removable = props.winningPercentages.length > 1;
  let rows = props.winningPercentages.map((percent, index) =>
    <Row key={index} className="pt-1">
      <Col xs="10">
        <InputGroup>
          <InputGroupAddon style={{width: "2rem", lineHeight: "2rem"}} addonType="prepend">{index+1}</InputGroupAddon>
          <Input placeholder="0" value={percent} className="text-center" name={index} onChange={props.handlePercWinningsChange} type="number" />
          <InputGroupAddon addonType="append">%</InputGroupAddon>
        </InputGroup>
      </Col>
      {removable &&
        <Col xs="2">
          <Button className="mt-2 pt-0 pb-0 pl-1 pr-1" color="danger" onClick={ removeEvent(index) } >
            <FontAwesome name="times" />
          </Button>
        </Col>
      }
    </Row>
  )

  let unchanged = props.winningPercentages.length === props.origWinningPercentages.length &&
                  props.winningPercentages.every((curr, index) => { return curr === props.origWinningPercentages[ index ]});

  let valid = undefined;
  if (!unchanged) {
    valid = total <= 100;
  }
  let validBorderWidth = valid === undefined ?
                            "1px" :
                            valid === true ?
                              "2px" :
                              "3px";

  return (
    <div>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Percentage payouts award winning places a percentage of collections. It is not necessary to pay out 100% of the purse.
      </Row>
      <Col sm="9" md="6" >
        <Row className="pb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">Total</InputGroupAddon>
            <Input value={ numeral(total).format('0[.]000') } readOnly valid={valid} invalid={valid===false} style={{borderWidth: validBorderWidth}} />
            <InputGroupAddon addonType="append">%</InputGroupAddon>
          </InputGroup>
        </Row>
        {rows}
        <Row className="pt-1">
          <span onClick={props.addWinningPercPlace} style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} >
            Add Winning Place
          </span>
        </Row>
      </Col>
    </div>
  )
}

const PrizeWinningPlaces = (props) => {

  return (
    <div>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Use this option if you plan awarding prizes to the winners. The leaderboard will indicate "prize" instead of an amount. Describe your prize structure or any additional rules in the Contest Rules section below.
      </Row>
      <Col sm="9" md="6" >
        <Row className="pb-2">
          <Col className="d-flex">
            <span style={{lineHeight:"2rem"}}> Winning Places: </span>
            {/*<Input placeholder="0" value={props.noPayoutPrizes} className="text-center" name={index} onChange={props.handleNoPayoutPrizesChange} type="number" />*/}
            <Input type="select" value={props.noPayoutPrizes} name="noPayoutPrizes" id="noPayoutPrizes"
              className="mx-1" style={{width:"5em", color:"#999", height:"2em"}} onChange={props.handleNoPayoutPrizesChange}>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>27</option>
              <option>28</option>
              <option>29</option>
              <option>30</option>
            </Input>
          </Col>
        </Row>
      </Col>
    </div>
  )
}

const ContestRules = (props) =>{
  let eventRules = <span style={{fontSize: ".8rem",}}>Preview</span>
  let eventSettings = props;
  return (
    <div>
      <Row className="contentTitle">
        Contest Rules & Prizes (Optional)
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Use this section to optionally describe any specific rules for your golf pool. When provided, this text will display when a user clicks Event Rules from the leaderboard.
      </Row>
      <Row className="pt-2 ml-n3" noGutters>
        <Col>
          <Input type="textarea" rows={3} placeholder="Please enter Contest Rules." value={props.addlContstRuleTxt} onChange={props.handleAddlContstRuleTxt} />
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col className="d-flex justify-content-end">
          <EventRulesModal eventSettings={eventSettings} component={eventRules} />
        </Col>
      </Row>

    </div>
  )
}

const WithdrawAltRule = (props) =>
  <div>
    <Row className="contentTitle">
      Withdraw / Alternate Rule
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      Normally, golfers withdrawing after a tournament starts are treated as a CUT, while alternates are only assigned to golfers who do not play. You can change this behavior making it less penal below. Any golfer who is DQ is always treated as a CUT.
    </Row>
    <Row className="pt-2 pl-2" noGutters>
      <Col>
        <Input type="radio" name="withdrawAltRule" id="ALL_WD_CUT" className="mr-3" value={props.withdrawAltRule} checked={props.withdrawAltRule==='ALL_WD_CUT'} onChange={props.handleWithdrawAltRuleRadio} />
        <span style={{color:"#555"}}> Any golfer who withdraws during the tournament turns into a CUT.</span>
      </Col>
    </Row>
    <Row className="pl-2" noGutters>
      <Col>
        <Input type="radio" name="withdrawAltRule" id="USE_ALT_IF_WD" className="mr-3" value={!!props.withdrawAltRule} checked={props.withdrawAltRule==='USE_ALT_IF_WD'} onChange={props.handleWithdrawAltRuleRadio} />
        <span style={{color:"#555"}}> Substitute Alternate for golfers withdrawing during Round 1 or 2.</span>
      </Col>
    </Row>
  </div>


const TieBreaker = (props) =>
  <div>
    <Row className="contentTitle">
      Tie-breaker Rule
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      Rosters with tied scores may either use the Majors Challenge tiebreaker rule to attempt to break the tie, or they may split the winnings for the tied places.
    </Row>
    <Row className="pt-2 pl-2" noGutters>
      <Col>
        <Input type="radio" name="tieBreaker" id="tiebreak" className="mr-3" value={props.tieBreakRule} checked={props.tieBreakRule==='MAJORS'}  onChange={props.handleTieRadio} />
        <span style={{color:"#555"}}> Use Majors Challenge tiebreaker</span>
      </Col>
    </Row>
    <Row className="pl-2" noGutters>
      <Col>
        <Input type="radio" name="tieBreaker" id="splittiescut" className="mr-3" value={props.tieBreakRule} checked={props.tieBreakRule==='SPLIT_POINT_CUT'} onChange={props.handleTieRadio} />
        <span style={{color:"#555"}}> Split point pool across tied teams (with cuts)</span>
      </Col>
    </Row>
    <Row className="pl-2" noGutters>
      <Col>
        <Input type="radio" name="tieBreaker" id="splittiesnocut" className="mr-3" value={props.tieBreakRule} checked={props.tieBreakRule==='SPLIT_POINT_WITHOUT_CUT'} onChange={props.handleTieRadio} />
        <span style={{color:"#555"}}> Split point pool across tied teams (without cuts) </span>
      </Col>
    </Row>
    <Row className="pt-2" noGutters>
      <Col>
        <a href="/rules" target="_blank">Detailed Rules <FontAwesome name="external-link"/></a>
      </Col>
    </Row>
  </div>

const SaveSettings = (props) =>
  <div>
    <Row className="mb-3 align-items-center">
      <Col className="d-flex justify-content-start">
        <Button color="primary" onClick={props.handleSave}>Save Settings</Button>
      </Col>
      <Col className="d-flex justify-content-end">
        {props.settingsType === 'GENERAL' &&
        <span onClick={props.terminateLeague} style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} >
          Terminate League
        </span>
        }
      </Col>
    </Row>
    <Row style={{color: !!props.success ? "black" : "red"}} className="mb-4">
      {!!props.message && props.message}
    </Row>
  </div>
