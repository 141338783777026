import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';

import {
  ContentWrapper
} from 'common/ProfileComponents.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import {
  paypal_user_payment_order_create,
  paypal_user_payment_order_capture,
} from 'utils/api.js';



export default class PayPalPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      amount: props.amount,
      orderRes: null,
      fundingSource: null,
    }

  }
  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.amount !== this.props.amount){
      this.setState({amount: this.props.amount,});
    }

  }

  showConfirmAlert = async (amount) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Confirm',
        message: 'Your card will be charged $' + amount + '. Continue?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => resolve(true),
          },
          {
            label: 'No',
            onClick: () => resolve(false),
          },
        ],
      });
    });
  };

  handleCreateOrder = async ()=>{
    if (this.props.preValidation){
      let ret = this.props.preValidation();
      if (!ret){return;}
    }
    this.setState({loading: false, error:null, message: null, status:null});



    this.setState({loading: true});
    let res = await paypal_user_payment_order_create(this.state.amount, this.state.fundingSource, this.props.leagueId, this.props.leagueEventId, this.props.entryId);
    this.setState({orderRes: res.paymentRes});
    return res.paymentRes.transId;


  }

  handleCaptureOrder = async (orderId)=>{
    let res = await paypal_user_payment_order_capture(this.state.orderRes.userLedgerId, orderId, this.props.leagueId, this.props.leagueEventId, this.props.entryId);
    if (res.paymentRes.status === 'success'){
      this.props.setPaymentStatus('success', '$' + this.props.amount + ' is Successfully deposited to MC', res.paymentRes.userLedgerId);
    }else if (res.paymentRes.status === 'fail'){
      this.setState({error: 'Sorry, the transaction was declined.'});
    }else if (res.paymentRes.status === 'alreadypaid'){
      this.setState({error: 'No payment is needed. Entry is already paid.'});
    }
    return res.paymentRes.transId;
  }



  handleOnClick = (data) => {
    // fundingSource = "venmo"
    let fundingSource = data.fundingSource
    this.setState({fundingSource: fundingSource.toUpperCase()});
    //alert(fundingSource);
    // Use this value to determine what funding source was used to pay
    // Update your confirmation pages and notifications from "PayPal" to "Venmo"
    return false;
  }

  handlePayPalClick = async () => {
    // Show a confirmation message
    const userConfirmed = window.confirm(
      "Do you want to proceed with the payment of $10.00?"
    );
    return userConfirmed;
  };

  render() {

    return (
      <div>

          <PayPalButtons

          onInit = {(data, actions) => {
                    // Disable the buttons
                    actions.disable();


          }}
          onClick = {(data, actions)  => {
            //alert('click >>>>>');
            this.setState({fundingSource: data.fundingSource.toUpperCase()});
            return actions.resolve();
            // return false;
          }}
            createOrder={this.handleCreateOrder}
            onApprove={(data) => this.handleCaptureOrder(data.orderID)}
            style={{ layout: "vertical" }}
          />


      </div>
    )
  }
}



/*
const PayPalPay = (amount) => {
  return (
    <div>
      <h2>Complete your Payment</h2>
      <PayPalButtons
        style={{ layout: "vertical" }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: "100.00" // Replace with the actual amount
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
          });
        }}
        onError={(err) => {
          console.error("Error occurred in PayPal Buttons:", err);
          alert("An error occurred during the transaction. Please try again.");
        }}
      />
    </div>
  );
}*/
