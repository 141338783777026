import React from 'react';

import {
  Row, Col,
  Container,
  ListGroup
} from 'reactstrap';

import {
  Link,
  NavLink,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import Tournaments from 'pages/admin/Tournaments';
import Reports from 'pages/admin/Reports';
import SalesReports from 'pages/admin/SalesReports';
import MailchimpReports from 'pages/admin/MailchimpReports';
//import GlobalContent from 'pages/admin/GlobalContent';
import CreateClub from 'pages/admin/CreateClub';
import BannerAds from 'pages/admin/BannerAds';
import CharityLeague from 'pages/admin/CharityLeague';
import Support from 'pages/admin/Support';


export const DesktopAdmin = (props) => {
  return (
    <Container fluid>
      <AdminHeader />
      <div className="p-lg-3">
        <Row>
          <Col lg="3">
            <DesktopAdminNav {...props} />
          </Col>
          <Col lg="9">
            <Routes isDesktop={true} {...props} />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export const MobileAdmin = (props) => {
  return (
    <Routes isDesktop={false} {...props} />
  )
}

const Routes = (props) => {

  return (
    <Switch>
      {
        props.me.usr.is_admin === false &&
        <Redirect to={"/"} />
      }
      {
        props.me.usr.is_staff === true &&
        <Route path="/admin/tournaments" render={() => <Tournaments {...props} /> } />
      }
      {
        props.me.usr.is_staff === true &&
        <Route path="/admin/charities" render={() => <CharityLeague {...props} /> } />
      }
      {
        (props.me.usr.is_staff === true || props.me.usr.enbl_admin_reports === true ) &&
        <Route path="/admin/adminreports" render={() => <Reports type='admin' {...props} /> } />
      }
      {
        (props.me.usr.is_staff === true ) &&
        <Route path="/admin/mailchimpreports" render={() => <MailchimpReports type='mailchimp' {...props} /> } />
      }
      {
        props.me.usr.is_staff === true &&
        <Route path="/admin/globalcontent" render={() => <BannerAds {...props} activeLeague={null} configGrp={'GLOBAL_BANNER'} /> } />
      }
      {
        props.me.usr.is_sales === true &&
        <Route path="/admin/createclub" render={() => <CreateClub authed={true} {...props} /> } />
      }
      {
        props.me.usr.is_sales === true &&
        <Route path="/admin/salesreports" render={() => <SalesReports type='sales' {...props} /> } />
      }
      {
        (props.me.usr.is_staff === true ) &&
        <Route path="/admin/support" render={() => <Support  {...props} /> } />
      }
      {
        (props.isDesktop === true && props.me.usr.enbl_admin_reports === true && props.me.usr.is_staff === false) &&
        <Redirect to={"/admin/adminreports"} />
      }
      {
        (props.isDesktop === true && props.me.usr.is_sales === true && props.me.usr.is_staff === false) &&
        <Redirect to={"/admin/createclub"} />
      }
      {
        (props.isDesktop === true) ?
        <Redirect to={"/admin/tournaments"} /> :
        <Route path="/admin" render={() => <MobileAdminMenu {...props} /> } />
      }
    </Switch>
  )
}

const adminNavs = (props) => {
  let navItems = [];
  if (props.me.usr.is_staff === true){
    navItems.push(
      {to: "/tournaments", title: "Tournaments"},
      {to: "/charities", title: "Charities"},
      {to: "/adminreports", title: "Admin Reports"},
      {to: "/mailchimpreports", title: "Mailchimp"},
      {to: "/globalcontent", title: "Global Content"},
      {to: "/support", title: "Support"},
    );
  }
  if (props.me.usr.enbl_admin_reports === true && props.me.usr.is_staff === false){
    navItems.push(
      {to: "/adminreports", title: "Admin Reports"},
    );
  }
  if (props.me.usr.is_sales === true){
    navItems.push(
      {to: "/createclub", title: "New Golf Club"},
      {to: "/salesreports", title: "Sales Reports"},
    );
  }

  return navItems;
};

const DesktopAdminNav = (props) => {
  const items = adminNavs(props).map((item, index) => <DesktopAdminNavItem
    key={index}
    to={"/admin" + item.to}
    title={item.title}
  />);

  return (
    <ListGroup>
      {items}
    </ListGroup>
  )
}


const MobileAdminNav = (props) => {
  const items = adminNavs(props).map((item, index) => <MobileAdminNavItem
    key={index}
    to={"/admin" + item.to}
    title={item.title}
  />);

  return (
    <ListGroup>
      {items}
    </ListGroup>
  )
}

const DesktopAdminNavItem = (props) => {
  return (
    <NavLink
      to={props.to}
      exact={true}
      className="list-group-item-action list-group-item"
      activeClassName="active">
      <div>{props.title}</div>
    </NavLink>
  )
}


const MobileAdminNavItem = (props) => {
  return (
    <Link
      to={props.to}
      className="list-group-item-action list-group-item">
      <div style={{width: "100%"}} className="d-flex justify-content-between">
        {props.title}
        <FontAwesome name="chevron-right" style={{fontSize: "1rem", lineHeight: "22px"}} />
      </div>
    </Link>
  )
}


const MobileAdminMenu = (props) => {
  return (
    <Container fluid>
      <div className="p-3">
        <MobileAdminNav {...props} />
      </div>
    </Container>
  )
}

const AdminHeader = (props) => {
  return (
    <div className="pb-1 pl-4">
      <div className="adminTitle">Admin Console</div>
    </div>
  )
}
