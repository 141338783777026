import React from 'react';
import {
  Row, Col,
  Badge,
  Button,
  Card, CardHeader,
  Table,
} from 'reactstrap';

import { Link, withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

import PastEntries from 'common/PastEntries.js';
//import AuthedJoinLeague from 'pages/user/AuthedJoinLeague';

import perfectTeamEnter from 'img/250kenter.png';

import { get_homedata, join_league_dashboard, reject_league_dashboard, join_promo } from 'utils/api.js';
import { get_promo_content } from 'utils/promoContent.js';

import 'pages/user/Home.css';


class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      upcomingLeagueEvents: [],
      leagueInvites: [],
      pastEntries: [],
      promos: [],
      isPastSeasonEntryPresent: false,
      isShowJoinGolfPool:false,
      oadUpcoming: [],
    };

    this.fetchHomeData = this.fetchHomeData.bind(this);
    this.acceptInvite = this.acceptInvite.bind(this);
    this.rejectInvite = this.rejectInvite.bind(this);
    this.joinPromo = this.joinPromo.bind(this);
    this.showJoinGolfPool = this.showJoinGolfPool.bind(this);
  }

  componentDidMount() {
    if (this.props.activeLeague !== null) {
      this.props.selectLeague(null);
    }

    if (!!this.props.me && !!this.props.me.leagues && this.props.me.leagues.length === 0) {
      this.props.history.push('/joingolfpool');
      return;
    }

    get_promo_content().then(({landing, dashboard: freecontest}) => {
      this.setState({landing, freecontest});
    });

    this.fetchHomeData();
  }

  fetchHomeData() {
    get_homedata(({upcoming, invites, past, promos, isPastSeasonEntryPresent, oadUpcoming, sdUpcoming}) => {
      this.setState({
        upcomingLeagueEvents: upcoming,
        leagueInvites: invites,
        pastEntries: past,
        promos: promos,
        loading: false,
        isPastSeasonEntryPresent:isPastSeasonEntryPresent,
        isShowJoinGolfPool: ((upcoming.length > 0 || past.length > 0)?false:true),
        oadUpcoming: oadUpcoming,
        sdUpcoming: sdUpcoming,
      });
    }, (error) => {
      console.log(error);
      this.setState({
        upcomingLeagueEvents: [],
        leagueInvites: [],
        pastEntries: [],
        promos: [],
        isPastSeasonEntryPresent: false,
      });
    });
  }

  acceptInvite(leagueid) {
    join_league_dashboard(leagueid, ({success, reason, me}) => {
      if (!success) {
        alert(reason);
        return
      }

      this.props.updateMe(me);
      this.fetchHomeData();
    }, (error) => {
      console.log(error);
      alert('Error occurred while joining the league. If this issues persists, please contact Majors Challenge.')
    });
  }

  rejectInvite(leagueid) {
    reject_league_dashboard(leagueid, ({success, reason, me}) => {
      if (!success) {
        alert(reason);
        return
      }

      this.props.updateMe(me);
      this.fetchHomeData();
    }, (error) => {
      console.log(error);
      alert('Error occurred while rejecting the league. If this issues persists, please contact Majors Challenge.')
    });
  }

  joinPromo(leagueid) {
    join_promo(leagueid, ({success, error}) => {
      if (success) {
        this.props.history.push("/league/"+leagueid);
        window.location.reload();
      } else {
        alert(error);
      }
    }, (error) => {
      alert('An unexpected error occurred trying to join this promotion. If this issue persists, please contact support.');
    });
  }

  showJoinGolfPool(){
    this.setState({isShowJoinGolfPool:true,});
  }


  render() {

    if (this.state.loading) {
      return null;
    }

    let myLeagues = [];

    if (!!this.props.me && !!this.props.me.leagues) {
      for (const league of this.props.me.leagues) {
        if (league.commissioner) {
          myLeagues.push(league);
        }
      }
    }



    // const clubPromo = (
    //   <Card body className="mt-4">
    //     <center>
    //       <h5>Country Club Member?</h5>
    //       <p>We now offer exclusive pricing and tailormade service for you and your club.</p>
    //       <span>
    //         Contact:&nbsp;
    //         <a href="mailto:clubs@majorschallenge.com">
    //           clubs@majorschallenge.com
    //         </a>
    //       </span>
    //     </center>
    //   </Card>
    // );

    // TODO consider adding a section here for "My Leagues" to help out commissioners that deleted all their events...

    // const createLeaguePromo = myLeagues.length === 0 &&
    //   <div>
    //     <Card body className="p-0">
    //       <center className="mt-3">
    //         <h5>Start your own pool and your first 50 teams are free!</h5>
    //         <Link to="/createleague" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
    //           <Button color="success" className="my-3" size="lg">
    //             Create a League
    //           </Button>
    //         </Link>
    //       </center>
    //     </Card>
    //   </div>


    const createLeaguePromo =
      <div>
          <div className="d-flex justify-content-center">
             <div className="btn-group my-2">
               <Link to="/createleague" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                 <Button color="success" className="font-weight-bold" size="md">
                   Create a Golf Pool
                 </Button>
               </Link>
               {/*
               <Button color="success" className="rounded font-weight-bold ml-3" size="md" onClick={() => this.showJoinGolfPool()}>
                 Join a Golf Pool
               </Button>*/}
               <Link to="/joingolfpool" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-2">
                 <Button color="success" className="font-weight-bold" size="md">
                   Join a Golf Pool
                 </Button>
               </Link>
            </div>
          </div>
          <div className="d-none">
            <div className="text-center my-2">
              <Link to="/createleague" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                <Button color="success" className="" size="md" style={{width:"170px"}}>
                  Create a Golf Pool
                </Button>
              </Link>
            </div>
          </div>
          <div className="d-none">
            <div className="text-center mb-2 mt-1">
              <Link to="/joingolfpool" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                <Button color="success" className="" size="md" style={{width:"170px"}}>
                  Join a Golf Pool
                </Button>
              </Link>
              {/*
              <Button color="success" className="" size="md" style={{width:"170px"}} onClick={() => this.showJoinGolfPool()}>
                Join a Golf Pool
              </Button>*/}
            </div>
          </div>
          {this.state.isShowJoinGolfPool &&
            <div>
              {/*<AuthedJoinLeague {...this.props} />*/}
              <div className="text-center mx-0 my-2" style={{maxWidth:'670px'}}>
                <Link to="/club/free" style={{color: "inherit", textDecoration: "none", cursor: "pointer"}}>
                  <img src={perfectTeamEnter} alt="alt" width="100%" />
                </Link>

                <div style={{fontSize:"12px",color:"black", paddingTop:'10px'}}>
                  Looking for a friend's pool? Ask them to invite you.
                </div>
              </div>
              {/*
              <div className="text-center mx-3">
                <h3>
                  <span style={{color:"#008000"}}> Create a perfect team and WIN $250,000!</span>
                </h3>
                <p className="text-center" style={{color:"#808080"}}>
                  <span style={{fontSize:"20px"}}>What's a Perfect Team?</span><br></br>
                  <div style={{maxWidth:'650px'}}>
                    A perfect team is a lineup of fantasy golfers with the lowest possible score. It's like a perfect March Madness bracket, but not as difficult. There's no purchase necessary. Just enter our FREE contest, do your research then follow your golfers on the real-time leaderboard.
                  </div><br></br>
                  <Link to="/club/free" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                    <Button color="success" outline className="" size="md">
                      Enter To Win $250,000
                    </Button>
                  </Link><br></br><br></br>
                  <span style={{fontSize:"12px",color:"black"}}>Looking for a friend's pool? Ask them to invite you.</span><br></br>
                </p>
              </div>
              */}
            </div>
          }

      </div>

    let showWeeklyUpcoming = true;
    if (this.state.upcomingLeagueEvents.length === 1 && !this.state.upcomingLeagueEvents[0].leagueeventExists){
      showWeeklyUpcoming = false;
    }
    return (
      <div className="dashboard">
        {showWeeklyUpcoming &&
          <DashboardContentWrapper>
            <UpcomingLeagueEvents data={this.state.upcomingLeagueEvents} gameType={'WEEKLY'} />
          </DashboardContentWrapper>
        }

        {this.state.oadUpcoming.length > 0 &&
          <DashboardContentWrapper>
            <UpcomingLeagueEvents data={this.state.oadUpcoming} gameType={'OAD'}/>
          </DashboardContentWrapper>
        }

        {this.state.sdUpcoming.length > 0 &&
          <DashboardContentWrapper>
            <UpcomingLeagueEvents data={this.state.sdUpcoming} gameType={'SD'}/>
          </DashboardContentWrapper>
        }

        {
          this.state.leagueInvites.length !== 0 &&
          <DashboardContentWrapper>
            <PendingInvites
              invites={this.state.leagueInvites}
              acceptInvite={this.acceptInvite}
              rejectInvite={this.rejectInvite} />
          </DashboardContentWrapper>
        }
        {/**RI#203 - Home page redesign
          !!this.state.promos && this.state.promos.length > 0 &&
          <DashboardContentWrapper>
            <PromoEvents joinPromo={this.joinPromo} {...this.state}/>
          </DashboardContentWrapper>
        **/}
        {/* RI#142
          !!this.state.promos && this.state.promos.length > 0 &&
          <DashboardContentWrapper>
            <FreeContestEvents joinPromo={this.joinPromo} {...this.state}/>
          </DashboardContentWrapper>
          */
        }
        {/*
        <DashboardContentWrapper>
          <PastEntries events={this.state.pastEntries} pastSesnEntry={this.state.isPastSeasonEntryPresent}/>
        </DashboardContentWrapper>
        */}
        <DashboardContentWrapper>
          {createLeaguePromo}
        </DashboardContentWrapper>
        {/*<DashboardContentWrapper>
        {clubPromo}
        </DashboardContentWrapper>*/}
      </div>
    );
  }
}

const UpcomingLeagueEvents = (props) => {
  if (props.data.length === 0) {
    return null;
  }
  let data = props.data;
  if (props.gameType === 'WEEKLY'){
    data = props.data.filter((d)=>{return d.leagueeventExists;});
  }
  const upcomingEvents = data.map((item, index) => {
    const live_status = ["round1", "round2", "round3", "round4"].indexOf(item.status) !== -1;
    let badge;
    if (live_status) {
      badge = <Badge color="primary"  style={{marginLeft: "0.5rem"}}>LIVE</Badge>
    } else if (item.status === "open") {
      badge = <Badge color="warning"  style={{marginLeft: "0.5rem"}}>OPEN</Badge>
    }

    const cup = item.cup && <FontAwesome name="trophy" />

    let event = '';
    if (props.gameType === 'WEEKLY'){
      event = (item.status === "closed" || item.status === "open" || live_status) ?
        <Link to={"/events/"+item.eventid}>
          {item.title} {cup} {badge}
        </Link> :
        item.title;
    }else{
      event = (item.status === "closed" || item.status === "open" || live_status) ?
        <>{item.title}{badge}</>:
        item.title;
    }


    let league =
      <Link to={"/league/"+item.leagueid}>
        {item.league}
      </Link>

    let entries = item.status === "upcoming" ? "-" : (item.entries + ' of ' + item.total_entries);
    let eventCSS = item.eventid === null ? {whiteSpace: "nowrap", color: "gray", fontSize: ".85em"} : {};

    return (
      <tr key={index}>
        <td>
          <div style={eventCSS}>{event}</div>
          <div style={{whiteSpace: "nowrap", color: "gray", fontSize: ".85em"}}>{item.dates}</div>
        </td>
        {props.gameType==='WEEKLY' &&
        <td style={{whiteSpace: "nowrap"}} className="text-center">{entries}</td>
        }
        <td style={{whiteSpace: "nowrap"}}>{league}</td>
      </tr>
    )
  });

  return (
    <Card body className="p-0">
      <CardHeader className="">{props.gameType==='WEEKLY'?'Weekly Golf Pools':(props.gameType==='SD'?'Fantasy Draft':'One And Done Contests')}</CardHeader>
      <Row className="justify-content-between p-3">
        <Col style={{paddingLeft: "0.75rem"}}>
          <h6>{'Upcoming Tournaments'}</h6>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
            <th>{'Next Event'}</th>
            {props.gameType==='WEEKLY' &&
            <th className="text-center">{'Entries'}</th>
            }
            <th>{'League'}</th>
          </tr>
        </thead>
        <tbody>
          {upcomingEvents}
        </tbody>
      </Table>
    </Card>
  )
};

const PendingInvites = (props) => {
  if (props.invites.length === 0) {
    return null;
  }

  const inviteRows = props.invites.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.league}</td>
        <td>{item.created_by}</td>
        <td>{item.invited_by}</td>
        <td className="text-center">
          <Button size="sm" outline color="success" onClick={() => props.acceptInvite(item.leagueid)}>
            {'Accept'}
          </Button>
          {' '}
          <Button size="sm" outline color="danger" onClick={() => props.rejectInvite(item.leagueid)}>
            {'Decline'}
          </Button>
        </td>
      </tr>
    )
  });

  return (
    <Card body className="p-0">
      <Row className="justify-content-between p-3">
        <Col style={{paddingLeft: "0.75rem"}}>
          <h5>{'Pending Invitations'}</h5>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
            <th>{'League'}</th>
            <th>{'Commissioner'}</th>
            <th>{'Invited by'}</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {inviteRows}
        </tbody>
      </Table>
    </Card>
  )
}

const PromoEvents = (props) => {
  const promos = props.promos;
  if (!promos || promos.length === 0 || !props.freecontest) {
    return null;
  }
  const {mobile, desktop} = props.freecontest;


  return (
    <div>

        <div className="dashboardPromoImageContainer d-sm-none">
          <img className="dashboardPromoImageAspect" src={mobile} style={{maxWidth: "100%", cursor: 'pointer'}} onClick={() => props.joinPromo(promos[0].leagueid)} alt="Promo banner." />
        </div>
        <img className="d-none d-sm-block d-lg-none" src={desktop} style={{maxWidth: "100%", cursor: 'pointer'}} onClick={() => props.joinPromo(promos[0].leagueid)} alt="Promo banner." />
        <div className="p-lg-3 d-none d-lg-block" style={{maxWidth: "700px"}}>
          <img src={desktop} style={{maxWidth: "100%", cursor: 'pointer'}} onClick={() => props.joinPromo(promos[0].leagueid)} alt="Promo banner." />
        </div>

    </div>
  )

  //
  // return (
  //   <Card body className="p-0">
  //     <Row className="justify-content-between p-3">
  //       <Col style={{paddingLeft: "0.75rem"}}>
  //         <h5>Free Contests</h5>
  //       </Col>
  //     </Row>
  //     <Table responsive>
  //       <thead>
  //         <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
  //           <th>Event</th>
  //           <th>Top Prize</th>
  //           <th>Winners</th>
  //           <th>League</th>
  //           <th/>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {rows}
  //       </tbody>
  //     </Table>
  //   </Card>
  // )
}

const FreeContestEvents = (props) => {
  const promos = props.promos;
  if (!promos || promos.length === 0 || !props.freecontest) {
    return null;
  }
  const {mobile, desktop} = props.freecontest;

  const rows = promos.map((item, index) => {
    let enter =
      <Button onClick={() => props.joinPromo(item.leagueid)} color="success">
        Enter
      </Button>

    return (
      <tr key={index}>
        <td>
          <div>{item.title}</div>
          <div style={{whiteSpace: "nowrap", color: "gray", fontSize: ".85em"}}>{item.dates}</div>
        </td>
        <td style={{whiteSpace: "nowrap"}}>{props.landing.prize}</td>
        <td style={{whiteSpace: "nowrap"}}>{props.landing.winners}</td>
        <td style={{whiteSpace: "nowrap"}}>{item.league}</td>
        <td>{enter}</td>
      </tr>
    )
   });

  return (
      <Card body className="p-0">
        <Row className="justify-content-between p-3">
          <Col style={{paddingLeft: "0.75rem"}}>
            <h5>Free Contests</h5>
          </Col>
        </Row>
        <Table responsive>
          <thead>
            <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
              <th>Event</th>
              <th>Top Prize</th>
              <th>Winners</th>
              <th>League</th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </Card>
  )
}

const DashboardContentWrapper = (props) => {
  return (
    <div className="d-flex justify-content-center pt-4">
      <Col lg="auto">
        {props.children}
      </Col>
    </div>
  )
}

export default withRouter(Home);
