import React from 'react';

import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';

import {
  Link,
  withRouter
} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import LoginModal from 'common/LoginModal'

import { check_invitation, join_league_and_activate } from 'utils/api';

import 'pages/user/JoinLeague.css';
import 'pages/user/Signup.css';

class JoinAfterLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      league: props.match.params.league,
      code: props.match.params.code,
      valid_invite: null,
      invalid_reason: "",
      league_name: "",
      password: '',
      firstname: '',
      lastname: '',
      companyName: '',
      status: null,
      error: null,
    };

    this.checkCode = this.checkCode.bind(this);
    this.handleJoin = this.handleJoin.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.setPostLoginURL( this.props.postLoginURL );
    this.checkCode();
  }


  checkCode() {
    check_invitation(
      this.state.league,
      this.state.code,
    ({success, reason, league_name, invited_by, active, me, showCompany}) => {
      if(!success && reason === 'You are already a member of this league.'){
        this.props.history.push("/league/"+this.state.league);
        this.props.loggedInCallback(me);
        return;
      }
      this.setState({
        valid_invite: success,
        invalid_reason: reason,
        league_name,
        invited_by,
        active,
        showCompany,
      })
    }, (error) => {
      console.log(error);
    });
  }

  handleJoin(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({status: false});

    join_league_and_activate(
      this.state.league,
      this.state.code,
      this.state.firstname,
      this.state.lastname,
      this.state.password,
      this.state.companyName,
    ({success, reason, me}) => {
      if (success) {
        this.props.history.push("/league/"+this.state.league);
        this.props.loggedInCallback(me);
      } else {
        this.setState({valid_invite:false, invalid_reason: reason})
      }
    }, (error) => {
      console.log(error);
      this.setState({
        status: null,
        error: "We couldn't sign you up. Please fill out all the fields."
      })
    });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    if (this.state.valid_invite === null) {
      return null;
    }

    if (!this.state.valid_invite) {
      return <InvalidInvite invalid_reason={this.state.invalid_reason} {...this.props} />
    }

    if (this.state.active) {
      return <ActiveInvite {...this.state} {...this.props} />
    }

    return (
      <OnboardingWrapper {...this.props}>
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col xs="12" sm="10" md="9" lg="8" xl="6" className="joinContent mx-auto">
              <div className="joinContent_title">{'You\'ve been invited to the league'}</div>
              <h2 className="joinContent_leaguename">{this.state.league_name}</h2>
              <div className="joinContent_invitedby mx-3">
                {this.state.invited_by} has invited you to a Majors Challenge golf pool. To complete your account setup, please provide your name and a password.
              </div>
              <form onSubmit={this.handleJoin}>
                <Row noGutters className="justify-content-center">
                  <Col xs="10" sm="9" md="9" lg="9" xl="8">
                    <label className="joinContent_signuplabel">
                      <input type="text" name='firstname' value={this.state.firstname} maxlength="30" className="joinContent_signupinput" onChange={this.handleChange}  placeholder="First Name"/>
                    </label>
                  </Col>
                </Row>
                <Row noGutters className="justify-content-center">
                  <Col xs="10" sm="9" md="9" lg="9" xl="8">
                    <label className="joinContent_signuplabel">
                      <input type="text" name='lastname' value={this.state.lastname} maxlength="30" className="joinContent_signupinput" onChange={this.handleChange}  placeholder="Last Name"/>
                    </label>
                  </Col>
                </Row>
                <Row noGutters className="justify-content-center">
                  <Col xs="10" sm="9" md="9" lg="9" xl="8">
                    <label className="joinContent_signuplabel">
                      <input type="password" name='password' value={this.state.password} className="joinContent_signupinput" onChange={this.handleChange} placeholder="Password"/>
                    </label>
                  </Col>
                </Row>
                {this.state.showCompany &&
                  <Row noGutters className="justify-content-center">
                    <Col xs="10" sm="9" md="9" lg="9" xl="8">
                      <label className="joinContent_signuplabel">
                        <input type="text" name='companyName' value={this.state.companyName} className="joinContent_signupinput" onChange={this.handleChange} placeholder="Club or Company Name"/>
                      </label>
                    </Col>
                  </Row>
                }
                <input type="submit" value="Signup" hidden />
              </form>
              <Row noGutters className="justify-content-center pt-4">
                <Col xs="10" sm="9" md="9" lg="9" xl="8">
                  {'By finishing your account setup, you agree to the Majors Challenge '}
                  <Link to="/terms" target="_blank">Terms and Conditions <FontAwesome name="external-link" style={{fontSize: ".75em"}} /></Link>
                  {' and '}
                  <Link to="/privacy" target="_blank">Privacy Policy <FontAwesome name="external-link" style={{fontSize: ".75em"}} /></Link>.
                </Col>
              </Row>
              <Row className="justify-content-center text-right pt-4">
                <Col xs="10" sm="9" md="8" lg="6" xl="5">
                  {
                    this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
                  }
                  {
                    this.state.status === null ?
                      <Button color="success" onClick={this.handleJoin} block>
                        {" Signup!"}
                      </Button>
                    :
                    <Button color="success" disabled block>
                      <FontAwesome name="spinner" spin />
                    </Button>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    );
  }
}

const InvalidInvite = (props) =>
    <OnboardingWrapper {...props}>
      <Container className="landingCard mt-4">
        <Row className="justify-content-center pb-1">
          <h5>{props.invalid_reason}</h5>
        </Row>
      </Container>
    </OnboardingWrapper>

const ActiveInvite = (props) => {
  return (
    <OnboardingWrapper {...props}>
      <Container className="landingCard mt-4">
        <h2 className="text-center" style={{fontWeight: 600}}>{props.league_name}</h2>
        <h4 className="pb-3 text-center" style={{fontWeight: 300}}>{"Invited by: "+ props.invited_by}</h4>
        <Row className="justify-content-center pb-1">
          {"Please sign in to continue."}
        </Row>
        <Row className="justify-content-center">
          <LoginModal {...props} loginLink />
        </Row>
      </Container>
    </OnboardingWrapper>
  );
}

export default withRouter(JoinAfterLogin);
