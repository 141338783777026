import React from 'react';

import {
  Alert,
  Badge,
  Card, CardHeader, CardBody,
  Collapse,
  Row, Col,
  Input,
  Button,
  Table,
  Modal, ModalHeader, ModalBody, ModalFooter,

} from 'reactstrap';
import Select from 'react-select';

import FontAwesome from 'react-fontawesome';
import LoadingModal from 'common/LoadingModal.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import CsvDownloader from 'react-csv-downloader';


import {OneAndDoneGameRuleAuth} from 'pages/user/Games.js';
import {MobilePortrait } from 'utils/responsive.js';


import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';


import { get_lc_data, league_in_contest, set_lc_fld_value, event_in_lc,
  get_lc_oad_entries, lc_oad_entry_action} from 'utils/api.js';

export default class OneAndDone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      enrolled: false,
      howToOpen: false,
      contestType: 'ONE_AND_DONE',
      eventsModal: false,
      ruleOpen: false,
      tourChampOptionEnable: false,
      showOADEntries: false,
      oad_entries: [],
      selectedEventId: null,
      entryGolferEditModal: false,
    };


  }

  componentDidMount() {
    if (this.props.activeLeague === null) { return; }

    this.fetchEnrolledData(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) { return; }

    this.fetchEnrolledData(nextProps.activeLeague);
  }


  fetchEnrolledData = (league) => {
    if (!league) { return; }
    this.setState({isLoading:true});
    get_lc_data(league, this.state.contestType,
    ({success, enrolled, lc, events, prevteams, availableGolfers, error}) => {
      if (success) {
        let tourChampOptionEnable = false;
        if (events){
          events.map((x) => x.actionsOpen = false);
          tourChampOptionEnable = events.filter((x)=>{return x.enrolled && x.name.indexOf('TOUR ')>=0}).length > 0? true: false;
        }

        this.setState({isLoading:false, enrolled, howToOpen: !enrolled, lc, events, tourChampOptionEnable,});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  fetchOADEntries = (eventid) => {
    if (!eventid) { return; }
    this.setState({isLoading:true, selectedEventId: eventid});
    get_lc_oad_entries(this.props.activeLeague, eventid, this.state.lc.id,
    ({success, oadEntries, availableGolfers, error}) => {
      if (success) {
        this.setState({isLoading:false, oadEntries, availableGolfers});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  handleLCEnroll = (enrolled) => {
    if (enrolled === this.state.enrolled) { return; }

    const leaguein = (name) => {
      const league = this.props.activeLeague;
      league_in_contest(league, this.state.contestType, name, enrolled,
        (success, error) => {
          if (success) {
            this.fetchEnrolledData(league);
          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });
    }

    if (!enrolled){
      confirmAlert({
        title: 'Confirmation',
        message: 'Are you sure you want to cancel the One-and-Done?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { leaguein();}
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else{
      leaguein();
    }
  }


  toggleHowTo = () => {
    this.setState({
      howToOpen: !this.state.howToOpen, ruleOpen: this.state.howToOpen ? false: this.state.ruleOpen
    })
  }


  handleInputValueChange = (event) => {
    //this.setState({[event.target.name]: event.target.value});
    this.saveLCFieldValue(event.target.name,(event.target.type==='radio'? event.target.id:event.target.value));
  }


  saveLCFieldValue = (fldNm, fldVal) => {

    const setVal = ()=>{
      let lc = this.state.lc;
      lc[fldNm] = fldVal;
      this.setState({lc});
      set_lc_fld_value(this.props.activeLeague, this.state.lc.id, fldNm, fldVal,
      ({success, error}) => {
        if (success) {


        } else {
          console.log(error);
        }
      }, (error) => {
        console.log(error);
      });
    }
    if (fldNm === 'allowNewTeams' && !fldVal ){
      let msg = 'Locking entries will prevent new players from joining your contest. Are you sure?';
      if (this.state.lc.maxTeamsPerUser > 1){
        msg = 'Locking entries will prevent new players from joining your contest. It will also prevent existing players from creating additional entries. Are you sure?';
      }
      confirmAlert({
        title: 'Confirmation',
        message: msg,
        buttons: [
          {
            label: 'Yes',
            onClick: () => { setVal();}
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });

    }else{
      setVal();
    }
  }


  toggleEventsModal = () => {
    this.setState({eventsModal : !this.state.eventsModal});
  }

  toggleEventRowActions = (eventid) => {
    let events = this.state.events;
    if (!events) { return; }
    const mtchEvent = events.find(({id}) => id === eventid);
    mtchEvent.actionsOpen = !mtchEvent.actionsOpen;
    this.setState({
      events:events,
    });
  }

  handleEventToggle = (eventid, enrolled) => {
    if (!this.state.events) { return; }

    const registerEvent = ()=>{
      const league = this.props.activeLeague;
      this.setState({isLoading:true});
      event_in_lc(league, this.state.lc.id, eventid, enrolled,
      (success, error) => {
        this.setState({isLoading:false});
        if (success) {
          /*
          let events = this.state.events;
          if (eventid > 0){
            let mEvent = events.find(({id}) => id === eventid);
            mEvent.enrolled = enrolled;
          }else{
            events.map((e)=>e.enrolled=enrolled);
          }
          this.setState(events);*/

          this.fetchEnrolledData(league);
        } else {
          console.log(error);
        }
      }, (error) => {
        console.log(error);
      });
    }

    //if (this.state.events.find(({id, enrolled:currEnrolled}) => id === eventid && enrolled === currEnrolled)) { return; }
    if (!enrolled){
      let msg = '';
      if (eventid && eventid > 0){
        msg = this.state.events.find((x) => {return x.id === eventid;}).name;
        msg = 'Are you sure you want to remove ' + msg + '?';
      }else{
        msg = 'Are you sure you want to remove all the events?'
      }

      confirmAlert({
        title: 'Confirmation',
        message: msg,
        buttons: [
          {
            label: 'Yes',
            onClick: () => { registerEvent();}
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    }else{
      registerEvent();
    }



  }

  handleEventEntries = (eventid) =>{
    this.toggleShowOADEntries();
    this.fetchOADEntries(eventid);
  }

  toggleAddEditTeamModal = (show, teamid) => {
    this.setState({error : null});
    if (this.state.lc.eventsCnt ===0){
      confirmAlert({
        title: 'Stop',
        message: 'Please choose tournaments before adding teams',
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      });
      return;
    }

    if (this.state.addEditTeamModal === show){return;}
    if (show === true){
      this.setState({addEditTeamModal : true});
      if (teamid){ //edit existing team
        this.editTeamGolfer(teamid);
      }else{
        this.addTeamGolfer();
      }
      return;
    }
    if (this.state.addEditTeamModal && this.state.teamid > 0){
      let team = this.state.lc.teamGolfers.filter(x => x.teamid===this.state.teamid)[0];
      let selectedGolferList = []
      team.golgers.forEach((g, index) => {
        selectedGolferList.push({teamgolfer_id:g.teamgolfer_id, golfer_id: g.golfer_id,golfer_name:g.golfer_name,image:'', alt:g.alt,
                            first_contestevent_id: g.first_contestevent_id, last_contestevent_id: g.last_contestevent_id
                              });
      });
      /*if (JSON.stringify(selectedGolferList) !== JSON.stringify(this.state.selectedGolferList)){
        confirmAlert({
          title: 'Confirm',
          message: 'Unsaved data will be lost. Continue?',
          buttons: [
            {
              label: 'Continue',
              onClick: () => {this.setState({addEditTeamModal : !this.state.addEditTeamModal});}
            },
            {
              label: 'Save',
              onClick: () => {this.saveTeamGolfers();}
            }
          ]
        });
        return;
      }*/
    }
    this.setState({addEditTeamModal : !this.state.addEditTeamModal});
  }


  toggleRuleOpen = () =>{
    this.setState({ruleOpen: !this.state.ruleOpen});
  }

  toggleShowOADEntries = () =>{
    this.setState({showOADEntries: !this.state.showOADEntries});
  }

  handleEventEntry = (action, entry) => {
    const eventid = entry.event_id,
    oad_id = entry.oad_entry_id,
    oad_detail_id = entry.oad_entry_detail_id,
    user_id = entry.user_id;
    let pick_golfer_id=null,
    pick_alt_golfer_id=null;
    const deleteEntry = ()=>{
      lc_oad_entry_action(this.props.activeLeague, eventid, this.state.lc.id, action, oad_id, oad_detail_id,user_id,pick_golfer_id,pick_alt_golfer_id,
        ({success, oadEntries, error}) => {
          if (success) {
            this.setState({isLoading:false, oadEntries,});
          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });
    }
    if (action === 'DELETE'){
      confirmAlert({
        title: 'Confirmation',
        message: 'Deleting this entry will remove it for the entire season. Are you sure?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { deleteEntry();}
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else if (action === 'UPDATE'){
      pick_golfer_id=entry.pick_golfer_id
      pick_alt_golfer_id=entry.pick_alt_golfer_id;
      lc_oad_entry_action(this.props.activeLeague, eventid, this.state.lc.id, action, oad_id, oad_detail_id,user_id,pick_golfer_id,pick_alt_golfer_id,
        ({success, oadEntries, error}) => {
          if (success) {
            this.setState({isLoading:false, entryGolferEditModal:false, oadEntries,});

          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });
    }else{
      lc_oad_entry_action(this.props.activeLeague, eventid, this.state.lc.id, 'PREV_PICKS', oad_id, oad_detail_id,user_id,pick_golfer_id,pick_alt_golfer_id,
        ({success, userPrevPicks, error}) => {
          if (success) {
            this.setState({isLoading:false, userPrevPicks, selectedEntry:entry});
            this.toggleEntryGolferEditModal();
          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });

    }
  }

  toggleEntryGolferEditModal = ()=>{
    this.setState({entryGolferEditModal: !this.state.entryGolferEditModal});
  }

  saveSelectGolfer = (entry)=>{
    this.handleEventEntry('UPDATE', entry);
  }

  render() {
    if (this.state.showOADEntries){
      return (
        <div className="commissionerMembers">
          <MobileHeader to="One-and-Done" leagueid={this.props.activeLeague} />
          <ContentWrapper>
            <LCHeader />
            <div className='d-flex justify-content-end mr-2 mb-2'
              style={{marginLeft:'20px', color:'blue', cursor: 'pointer'}}
              onClick={this.toggleShowOADEntries}>
              <FontAwesome name="angle-left" size="2x" />
              <div className="backBtnText">Back</div>
            </div>
            <PrevPicks lc={this.state.lc} oadEntries={this.state.oadEntries}
              events={this.state.events}
              selectedEventId = {this.state.selectedEventId}
              handleEventEntry={this.handleEventEntry}/>
            {this.state.entryGolferEditModal &&
            <PrevPicksGolferEditModal entryGolferEditModal={this.state.entryGolferEditModal}
              availableGolfers={this.state.availableGolfers}
              entry={this.state.selectedEntry}
              prevPicks={this.state.userPrevPicks}
              toggleEntryGolferEditModal={this.toggleEntryGolferEditModal}
              saveSelectGolfer={this.saveSelectGolfer}/>
            }
            <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />

          </ContentWrapper>
        </div>
      );
    }
    return (
      <div className="commissionerMembers">
        <MobileHeader to="One-and-Done" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <LCHeader />
          <LCSubheader howToOpen={this.state.howToOpen} ruleOpen={this.state.ruleOpen} toggle={this.toggleHowTo} toggleRuleOpen={this.toggleRuleOpen}/>

          <LCParticipation {...this.state} leagueid={this.props.activeLeague}
            handleLCEnroll={this.handleLCEnroll}  handleInputValueChange={this.handleInputValueChange}
            saveLCFieldValue={this.saveLCFieldValue}
            toggleEventsModal={this.toggleEventsModal}
            handleEventToggle={this.handleEventToggle}
            handleEventEntries={this.handleEventEntries}
            toggleEventRowActions={this.toggleEventRowActions}
            toggleAddEditTeamModal={this.toggleAddEditTeamModal}
            toggleChoosePrevTeamModal={this.toggleChoosePrevTeamModal}
            setTeamName={this.setTeamName}
            selectGolfer={this.selectGolfer}
            unSelectGolfer={this.unSelectGolfer}
            toggleChooseGolferModal={this.toggleChooseGolferModal}
            saveTeamGolfers={this.saveTeamGolfers}
            removeTeamGolfers={this.removeTeamGolfers}
            />


          <LCFooter />

          <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />

        </ContentWrapper>
      </div>
    );
  }
}

const LCHeader = () =>
  <div className="d-flex mb-4">
    <div className="d-none d-lg-block mr-auto" style={{fontSize: "1.75em", fontWeight: 300}}>One-and-Done</div>
  </div>

const LCSubheader = (props) =>
  <Alert color="success" className="my-4">
    <div className="d-flex justify-content-between py-1 font-weight-bold" onClick={props.toggle}>
      One-and-Done Instructions
      <FontAwesome size="lg" name={props.howToOpen ? "angle-up" : "angle-down"} />
    </div>
    <Collapse isOpen={props.howToOpen}>
      <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
        Welcome to One-and-Done Fantasy Golf! In this competition, players select a PGA TOUR golfer each week, and their earnings from that event add to the player’s season-long total. The catch? Once a golfer is chosen, they can't be picked again. The person with the highest total earnings at the end of the PGA TOUR season wins. This game takes patience and strategy, knowing when to pick the best golfers and when and where to find value each week.
        &nbsp;<span style={{color: "#157EFB", cursor: "pointer"}} onClick={props.toggleRuleOpen}>Click here </span>for full rules.
      </span>
    </Collapse>
    <Collapse isOpen={props.ruleOpen}  className="my-n4">
      <OneAndDoneGameRuleAuth embedIn={'COMMISS'}/>
    </Collapse>
  </Alert>

const LCFooter = () =>
  <div className="d-flex justify-content-center py-1 font-weight-bold ml-2 text-danger" >
        {/*New Season Starting in 2024*/}
  </div>

const LCParticipation = (props) =>{

  const numericOptions = [{cd:'1',label:'1'},{cd:'2',label:'2'},{cd:'3',label:'3'},{cd:'4',label:'4'}].map((sts, index) =>
    <option value={sts.cd} key={index}>{sts.label}</option>
  );

  const maxTeamsPerUserOptions = ['1','2','3','4','5','6','7','8','9','10'].map((n, index) =>
    <option value={n} key={index}>{n}</option>
  );

  return(
  <Card body className="p-2 my-4">
    <div className="pl-2 py-2 d-flex align-items-center border-bottom">
        <div className="col-sm-8 pt-1">
          {'Participate in the One-and-Done?'}
        </div>
        <div className="col-sm-4 d-flex justify-content-end">
          <Bootstrap2Toggle
            onClick={(state)=>{props.handleLCEnroll(state)}}
            on={'YES'}
            off={'NO'}
            size="sm"
            offstyle="danger"
            onstyle="success"
            active={props.enrolled}
          />
        </div>
    </div>
    {props.enrolled &&
        <div className="pl-2 pt-1">

          <Row noGutters className=" pl-3 py-1 mr-1 border-bottom">
            <Col xs="12" className="">
              <div className="mb-2" style={{fontSize: ".95rem"}}>
                {'One-and-Done Announcement'}
              </div>
              <Input type="textarea" placeholder="Message to display." style={{fontSize:'.8rem'}} name="msg"
                value={props.lc.msg} onChange={props.handleInputValueChange} rows="6"/>
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-2">
                {''}
              </div>

              {/*
              <Button outline className="btn-sm mt-2" color="primary" onClick={() => {props.toggleAddEditTeamModal(true);}} >Add Team</Button>
              <AddEditTeamGolfer {...props}/>
              <TeamGolfersTable enrolled={props.enrolled} teamGolfers={props.lc.teamGolfers} toggleAddEditTeamModal={props.toggleAddEditTeamModal}/>
              */}
            </Col>
          </Row>

          <div className="pl-2 py-2">
            <div className="d-flex">
              <div className="col-sm-8 pt-2">
                {'Max Entries Per Player'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0 d-none d-sm-block">
                  {'Enable players to create and manage multiple entries by adjusting this setting to set the maximum teams allowed per player. Each entry operates independently and tracks it’s own earnings and used golfers.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end pt-2">
                <Input type="select"  value={props.lc.maxTeamsPerUser} name="maxTeamsPerUser"
                  style={{width:'75px'}}
                  onChange={props.handleInputValueChange}>
                  {maxTeamsPerUserOptions}
                </Input>
              </div>
            </div>
            <div className="ml-3 d-block d-sm-none mr-2 mt-1">
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-0 ">
                {'Enable players to create and manage multiple entries by adjusting this setting to set the maximum teams allowed per player. Each entry operates independently and tracks it’s own earnings and used golfers.'}
              </div>
            </div>
          </div>

          <div className="pl-2 py-2">
            <div className="d-flex">
              <div className="col-sm-8 pt-1">
                {'Allow New Entries Mid-Season'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0 d-none d-sm-block">
                  {'Keep this setting enabled for your first event, and consider locking it mid-season to restrict new player entries. Once locked, only existing entries can continue participating.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end pt-1">
                <Bootstrap2Toggle
                  onClick={(state)=>{props.saveLCFieldValue('allowNewTeams',state)}}
                  on={'Yes'}
                  off={'Locked'}
                  size="sm"
                  offstyle="danger"
                  onstyle="success"
                  active={props.lc.allowNewTeams}
                />
              </div>
            </div>
            <div className="ml-3 d-block d-sm-none mr-2 mt-1">
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-0 ">
                {'Keep this setting enabled for your first event, and consider locking it mid-season to restrict new player entries. Once locked, only existing entries can continue participating.'}
              </div>
            </div>
          </div>
          <div className="pl-2 py-2 border-bottom">
            <div className="d-flex">
              <div className="col-sm-8 pt-1">
                {'Selections Per Week'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0 d-none d-sm-block">
                  {'The number of golfers to choose on each entry per week. Once a golfer is chosen, they cannot be used again on that entry.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end pt-1">
                <Input type="select"  value={props.lc.noGolfersPerWk} name="noGolfersPerWk" style={{width:'75px'}}
                  onChange={props.handleInputValueChange}>
                  {numericOptions}
                </Input>
              </div>
            </div>
            <div className="ml-3 d-block d-sm-none mr-3 mt-1">
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                {'The number of golfers to choose on each entry per week. Once a golfer is chosen, they cannot be used again on that entry.'}
              </div>
            </div>
          </div>
          {props.tourChampOptionEnable &&
          <div className="pl-2 py-2 border-bottom">
            <div className="d-flex">
              <div className="col-sm-8 pt-1">
                {'TOUR Championship Earnings'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0 d-none d-sm-block">
                  {'Signature Event simulates earnings from a standard elevated event. FedExCup Bonus uses the large FedEx payout structure, which can heavily influence results.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end pt-1">
              <Bootstrap2Toggle
                onClick={(state)=>{props.saveLCFieldValue('tourChampOption',(state?'SIGNATURE_EVENT':'FEDEXCUP_BONUS'))}}
                on={'Signature Event'}
                off={'FedExCup Bonus'}
                size="xl"
                offstyle="danger"
                onstyle="success"
                active={props.lc.tourChampOption==='SIGNATURE_EVENT'?true:false}
                style={{ width: "170px" }} // Set custom width
              />
              </div>
            </div>
            <div className="ml-3 d-block d-sm-none mr-2 mt-1">
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                {'Signature Event simulates earnings from a standard elevated event. FedExCup Bonus uses the large FedEx payout structure, which can heavily influence results.'}
              </div>
            </div>
          </div>
          }
          <div className="pl-2 py-2 border-bottom">
            <div className="d-flex">
              <div className="col-sm-8 pt-1">
                {'Withdraw / Alternate Rule'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0 d-none d-sm-block">
                  {'When set to DEFAULT, golfers withdrawing after a tournament starts are treated as a cut, while alternates are only assigned to golfers who do not play. When set to GENEROUS if a golfer withdraws before the tournament or during rounds 1 or 2, an alternate will automatically replace them. Any golfer who is DQ is always treated as a cut.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end pt-1">
              <Bootstrap2Toggle
                onClick={(state)=>{props.saveLCFieldValue('withdrawAltRule',(state?'ALL_WD_CUT':'USE_ALT_IF_WD'))}}
                on={'Default'}
                off={'Generous'}
                size="sm"
                offstyle="danger"
                onstyle="success"
                active={props.lc.withdrawAltRule==='ALL_WD_CUT'?true:false}
              />
              </div>
            </div>
            <div className="ml-3 d-block d-sm-none mr-2 mt-1">
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                {'When set to DEFAULT, golfers withdrawing after a tournament starts are treated as a cut, while alternates are only assigned to golfers who do not play. When set to GENEROUS if a golfer withdraws before the tournament or during rounds 1 or 2, an alternate will automatically replace them. Any golfer who is DQ is always treated as a cut.'}
              </div>
            </div>
          </div>
          <div className="pl-2 py-2 d-flex align-items-center">
              <div className="col-sm-8 pt-1">
                You have <strong>{props.lc.eventsCnt}</strong> tournaments in your season
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <Button outline className="btn-sm mt-2" color="primary" onClick={props.toggleEventsModal} >Manage Tournaments</Button>
                {/*<Button outline className="btn-sm mt-2" color="primary" onClick={props.toggleEventsModal} >Manage Tournaments
                  <Modal isOpen={props.eventsModal} toggle={props.toggleEventsModal} className="inviteModal">
                    <ModalHeader toggle={props.toggleEventsModal} className="mcModalHeader">Tournaments</ModalHeader>
                        <ModalBody>
                          <EventsTable {...props} handleEventToggle={props.handleEventToggle}
                            toggleEventRowActions={props.toggleEventRowActions}/>

                        </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                  </Modal>
                </Button>*/}
              </div>
          </div>
          {props.eventsModal &&
            <div className="pl-2 py-2 d-flex align-items-center border-bottom">
              <EventsTable {...props} handleEventToggle={props.handleEventToggle}
                handleEventEntries={props.handleEventEntries}
                toggleEventRowActions={props.toggleEventRowActions}/>
            </div>
          }

          {/*
          <div className="pl-2 py-2 d-flex border-bottom ">
              <div className="col-sm-8 pt-1">
                {'Maximum Repeats'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                  {'The number of times a specific golfer can be selected during the season.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <Input type="select"  value={props.lc.maxSameGolferRepeat} name="maxSameGolferRepeat" style={{width:'65px'}}
                  onChange={props.handleInputValueChange}>
                  {numericOptions}
                </Input>
              </div>
          </div>
          */}
        </div>
    }
  </Card>
  );
}


const EventsTable = ({enrolled, events, handleEventToggle, handleEventEntries, toggleEventRowActions}) => {

  if (!enrolled) { return null; }

  const rows = events.map((event, index) => <EventRow {...event} key={event.id} indx={index} handleEventToggle={handleEventToggle} handleEventEntries={handleEventEntries} toggleEventRowActions={toggleEventRowActions}/>)
  const eventsCnt = events.filter((e)=> {return ['scheduled','open'].indexOf(e.status)>-1}).length;;
  const enrolledEveCnt = events.filter((e)=> {return e.enrolled}).length;
  const allEventsEnrolled = eventsCnt>enrolledEveCnt?false:true;

  return (
    <Card body className="p-0 mt-4">
    <div className="p-2 d-flex justify-content-between">
      <div className="mt-1" style={{color: "#555", fontWeight:700}}>Tournaments this Season</div>
      <div className="">
        <Button block color="success"  onClick={()=>{handleEventToggle(0, !allEventsEnrolled);}}
                style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase", minWidth: "138px"}} className="pt-2 pb-2">
                {allEventsEnrolled ? 'Unregister All' : 'Register All'}
        </Button>
      </div>
    </div>
      <Table responsive className="">
        <thead>
          <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            <th>Tournament</th>
            <th className="text-center text-nowrap d-none d-sm-table-cell" style={{width:'32%'}}>One-and-Done Event</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Card>
  )
}

const EventRow = (props) => {
  const {name, dates, course, major, status} = props;

  const tournament =
    <td>
        <div style={{color: "#555", fontWeight:700}}>
          <span className="text-nowrap">{name}</span>
          { major &&
            <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
          }
          { status === "open" &&
            <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
          }
          { ["round1", "round2", "round3", "round4"].indexOf(status) !== -1 &&
            <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
          }
        </div>
        <div style={{color: "#aaa", fontSize: ".75em"}}>{dates} &bull; {course}</div>
        <MobilePortrait>
          <div className="mt-2">
            <IncludeOrRemoveSmall {...props} />
          </div>
        </MobilePortrait>
    </td>

    return (
      <tr>
        {tournament}
        <td className="d-none d-sm-table-cell"><IncludeOrRemove {...props} /></td>
      </tr>
    )
}


const IncludeOrRemove = ({enrolled, status, id, actionsOpen, handleEventToggle, handleEventEntries, hasEntries, indx, toggleEventRowActions}) => {
  const toggle = () => {toggleEventRowActions(id);};
  if (['round1', 'round2', 'round3', 'round4','closed'].indexOf(status) > -1 && !enrolled){
    return <Button block color="secondary" outline
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
                  {'CLOSED'}
          </Button>;
  }
  if (enrolled && ['scheduled'].indexOf(status) > -1){
    return <div className="d-flex justify-content-around align-items-center">
          <div style={{width:'50%'}}>
          <Button block color="danger" outline onClick={()=>{handleEventToggle(id, !enrolled);}}
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="text-nowrap">
                  {'Remove'}
          </Button>
          </div>
          <div style={{width:'50%'}} className="pl-2">
          <Button block color="secondary" outline disabled
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="text-nowrap">
                  {'ENTRIES'}
          </Button>
          </div>

          </div>
  }
  if (enrolled){
    return <div className="d-flex justify-content-around align-items-center">
          <div style={{width:'50%'}}>
          <Button block color="danger" outline onClick={()=>{handleEventToggle(id, !enrolled);}}
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="text-nowrap">
                  {'Remove'}
          </Button>
          </div>
          <div style={{width:'50%'}} className="pl-2">
          <Button block color="success" outline onClick={()=>{handleEventEntries(id);}}
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="text-nowrap">
                  {'ENTRIES'}
          </Button>
          </div>

          </div>
  }
  return <Button block color="success" outline={enrolled} onClick={()=>{handleEventToggle(id, !enrolled);}}
                style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
                {enrolled ? 'Remove' : 'Register'}
        </Button>;
}

const IncludeOrRemoveSmall = ({enrolled, status, id, actionsOpen, handleEventToggle, handleEventEntries, hasEntries, indx, toggleEventRowActions}) => {
  const toggle = () => {toggleEventRowActions(id);};
  if (['round1', 'round2', 'round3', 'round4','closed'].indexOf(status) > -1 && !enrolled){
    return <Button block color="secondary" outline size="sm"
                  style={{fontWeight: 600, fontSize: ".65em", textTransform:"uppercase"}} className="pt-2 pb-2">
                  {'CLOSED'}
          </Button>;
  }
  if (enrolled && ['scheduled'].indexOf(status) > -1){
    return <div className="d-flex justify-content-around align-items-center">
          <div style={{width:'50%'}}>
          <Button block color="danger" size="sm" outline onClick={()=>{handleEventToggle(id, !enrolled);}}
                  style={{fontWeight: 600, fontSize: ".65em", textTransform:"uppercase"}} className="text-nowrap">
                  {'Remove'}
          </Button>
          </div>
          <div style={{width:'50%'}} className="pl-2">
          <Button block color="secondary" outline disabled size="sm"
                  style={{fontWeight: 600, fontSize: ".65em", textTransform:"uppercase"}} className="text-nowrap">
                  {'ENTRIES'}
          </Button>
          </div>

          </div>
  }
  if (enrolled){
    return <div className="d-flex justify-content-around align-items-center">
          <div style={{width:'50%'}}>
          <Button block color="danger" outline size="sm" onClick={()=>{handleEventToggle(id, !enrolled);}}
                  style={{fontWeight: 600, fontSize: ".65em", textTransform:"uppercase"}} className="text-nowrap">
                  {'Remove'}
          </Button>
          </div>
          <div style={{width:'50%'}} className="pl-2">
          <Button block color="success"  size="sm" outline onClick={()=>{handleEventEntries(id);}}
                  style={{fontWeight: 600, fontSize: ".65em", textTransform:"uppercase"}} className="text-nowrap">
                  {'ENTRIES'}
          </Button>
          </div>

          </div>
  }
  return <Button block color="success" size="sm" outline={enrolled} onClick={()=>{handleEventToggle(id, !enrolled);}}
                style={{fontWeight: 600, fontSize: ".65em", textTransform:"uppercase"}} className="pt-2 pb-2">
                {enrolled ? 'Remove' : 'Register'}
        </Button>;
}

const IncludeOrRemoveMob = ({enrolled, status, id, actionsOpen, handleEventToggle, handleEventEntries, hasEntries, indx, toggleEventRowActions}) => {
  const toggle = () => {toggleEventRowActions(id);};
  if (['round1', 'round2', 'round3', 'round4','closed'].indexOf(status) > -1 && !enrolled){
    return <div className="d-flex justify-content-end align-items-center mt-2 mr-2">
            <div>
              <Button size="sm" color="secondary" outline className="btn-no-border"
                >
                CLOSED
              </Button>
            </div>
          </div>;
  }
  if (enrolled && ['scheduled'].indexOf(status) > -1){
    return <div className="d-flex justify-content-end align-items-center mt-2 mr-2">
            <div>
              <Button size="sm" color="danger" outline className="btn-only-buttom-border" onClick={()=>{handleEventToggle(id, !enrolled);}}
                >
                <FontAwesome name="trash" />
              </Button>
            </div>

            <div className="pl-1">
              <Button size="sm" disabled color="secondary" outline className="btn-only-buttom-border" >
                <FontAwesome name="pencil" />
              </Button>
            </div>
          </div>
  }

  if (enrolled){
    return <div className="d-flex justify-content-end align-items-center mt-2 mr-2">
            <div>
              <Button size="sm" color="danger" outline className="btn-only-buttom-border" onClick={()=>{handleEventToggle(id, !enrolled);}}
                >
                <FontAwesome name="trash" />
              </Button>
            </div>
            <div className="pl-1">
              <Button size="sm" color="success" outline className="btn-only-buttom-border" onClick={()=>{handleEventEntries(id);}}>
                <FontAwesome name="pencil" />
              </Button>
            </div>
          </div>
  }
  return <div className="d-flex justify-content-end align-items-center mt-2 mr-2">
          <div>
            <Button size="sm" color="success" outline className="btn-only-buttom-border" onClick={()=>{handleEventToggle(id, !enrolled);}}
              >
              <FontAwesome name="plus" />
            </Button>
          </div>
        </div>
}


const PrevPicks = (props) => {

  if (!props.lc || !props.oadEntries){
    return null;
  }

  const prevPicks = props.oadEntries;

  let rows = prevPicks.map((entry, index) => {

    return (
          <PrevPicksGolferRow index={index} entry={entry} handleEventEntry={props.handleEventEntry}/>
    );
  });
  const selEvent = props.events.filter((e)=> e.id === props.selectedEventId)[0];
  const picksCnt = prevPicks?[...new Set(prevPicks.map(item => item.oad_entry_id))].length:0;


  const header = selEvent?
      selEvent.season + ' ' + selEvent.name + ' (' + picksCnt +' Entries)':'Entries';
  const csvExprtColumns = [{
    'id': 'user_name',
    'displayName': 'Member',
  },
  {
    'id': 'user_email',
    'displayName': 'Member Email',
  },
  {
    'id': 'team_name',
    'displayName': 'Team No',
  },
  {
    'id': 'pick_golfer',
    'displayName': 'Golfer',
  },
  {
    'id': 'pick_alt_golfer',
    'displayName': 'ALT Golfer',
  }];
  return (
    <Card className="my-4">
      <CardHeader className="d-flex justify-content-between" >
        <div className="d-flex justify-content-start pt-1">
          {header}
        </div>
        <div className="d-flex justify-content-end">
          <div className="btn-group">
            <CsvDownloader
              filename={header}
              separator=","
              wrapColumnChar='"'
              columns={csvExprtColumns}
              datas={prevPicks}>
              {
                prevPicks.length > 0 ?
                <Button size="sm" color="success" outline className="btn-only-buttom-border text-nowrap"
                  style={{minWidth:'60px'}}>
                  <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                </Button> :
                <Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap"
                  style={{minWidth:'60px'}}>
                  <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                </Button>
              }
            </CsvDownloader>

          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0">
      <Table responsive className="mb-0 border-bottom">
        <thead>
          <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
            <th className="text-left">Member</th>
            <th className="text-center">Team</th>
            <th className="text-left">Golfer</th>
            <th className="text-left">ALT Golfer</th>
            <th className="text-left"></th>
          </tr>
        </thead>
        <tbody style={{fontSize: ".9em"}}>
          {rows}
        </tbody>
      </Table>
      </CardBody>

    </Card>

  )
}

class PrevPicksGolferRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

  }

  render() {
    let entry = this.props.entry;

    return (
      <tr  className="">
        <td className="align-middle text-left">
          {entry.user_name}
        </td>
        <td className="align-middle text-center">
          {entry.team_name}
        </td>
        <td className="align-middle text-left">
          {entry.pick_golfer}
        </td>
        <td className="align-middle text-left">
          {entry.pick_alt_golfer}
        </td>
        <td className="align-middle text-center">
          <div className="d-flex justify-content-end align-items-center mr-4">

              <div className="pr-3">
                <Button size="sm" color="success" outline className="btn-only-buttom-border" onClick={()=>{this.props.handleEventEntry('PREV_PICKS',entry);}}>
                  EDIT
                </Button>
              </div>
              <div>
                <Button size="sm" color="danger" outline className="btn-only-buttom-border" onClick={()=>{this.props.handleEventEntry('DELETE',entry);}}
                  >
                  <FontAwesome name="trash" />
                </Button>
              </div>



            </div>
        </td>
      </tr>
    )

  }
}

class PrevPicksGolferEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntry: {...this.props.entry}
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.entry === nextProps.entry) {
      this.setState({selectedEntry:{...nextProps.entry} })
    }
  }

  selectGolfer = (type, golferId, golferNm)=>{
    const sEntry = this.state.selectedEntry;
    if (type === 'REG'){
      sEntry.pick_golfer_id = golferId;
      sEntry.pick_golfer = golferNm;

    }else if (type === 'ALT'){
      sEntry.pick_alt_golfer_id = golferId;
      sEntry.pick_alt_golfer = golferNm;
    }
    this.setState({selectedEntry:sEntry})
  }

  render() {

    let entry = this.state.selectedEntry;
    let golferOptions = [],
      selectedGolferIds = [],
      selectedGolfer = null,
      selectedAltGolfer = null;

      this.props.prevPicks.forEach((t)=>{
        if (t.golfer_id && entry.team_name === t.team_name){
          selectedGolferIds.push(t.golfer_id);
        }
        if (t.pick_alt_golfer_id && entry.team_name === t.team_name && t.e_status !== 'closed'){
          selectedGolferIds.push(t.pick_alt_golfer_id);
        }

      });

    if (this.props.availableGolfers){
      this.props.availableGolfers.forEach((g, index) => {
        let option = { value: g.golferid,
          isDisabled: (selectedGolferIds.indexOf(g.golferid) > -1 || g.golferid === entry.pick_golfer_id || g.golferid === entry.pick_alt_golfer_id ? true : false ),
          label: g.golfer_name
        };
        if (entry.pick_golfer_id && g.golferid === entry.pick_golfer_id){
          selectedGolfer = { value: g.golferid,
            label: g.golfer_name
          };
        }
        if (entry.pick_alt_golfer_id && g.golferid === entry.pick_alt_golfer_id){
          selectedAltGolfer = { value: g.golferid,
            label: g.golfer_name
          };
        }
        golferOptions.push(
            option
        );
      });
    }

    golferOptions.sort((a, b) => {
      const nameA = a.label.toLowerCase();
      const nameB = b.label.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; // Equal values
    });

    return (
      <Modal isOpen={this.props.entryGolferEditModal}  className="inviteModal">
        <ModalHeader toggle={this.props.toggleEntryGolferEditModal} className="mcModalHeader">Entry</ModalHeader>
            <ModalBody>
            <Row noGutters className="justify-content-center mb-2">
              <Col>
                <label className="formContent_formInput_label pl-0"
                  style={{fontSize:'1.1em', border:'0px', paddingBottom:'0.5rem'}}>Tournament</label>
                <label style={{paddingLeft:'2px'}}>{entry.tournament}</label>
              </Col>
            </Row>
            <Row noGutters className="justify-content-center mb-2">
              <Col>
                <label className="formContent_formInput_label pl-0"
                  style={{fontSize:'1.1em', border:'0px' , paddingBottom:'0.5rem'}}>Member</label>
                <label style={{paddingLeft:'2px'}}>{entry.user_name}</label>
              </Col>
            </Row>
              <Row noGutters className="justify-content-center mb-2">
                <Col>
                  <label className="formContent_formInput_label pl-0"
                  style={{fontSize:'1.1em', border:'0px', paddingBottom:'0.25rem'}}>Golfer</label>
                  <Select className="no-border my-2" isSearchable options={golferOptions}
                  value={selectedGolfer}
                  placeholder = {<div className="" style={{fontSize:'1.1em', color:'black'}}>Choose Golfer</div>}
                  onChange={(selectedOption) => {
                    if (selectedOption){
                      this.selectGolfer('REG', selectedOption.value, selectedOption.label,);
                    }
                  }
                  } />
                </Col>
              </Row>
              <Row noGutters className="justify-content-center mb-2">
                <Col>
                    <label className="formContent_formInput_label pl-0"
                      style={{fontSize:'1.1em', border:'0px', paddingBottom:'0.25rem'}}>Alt Golfer</label>

                    <Select className="no-border my-2" isClearable isSearchable options={golferOptions}
                    value={selectedAltGolfer}
                    placeholder = {<div className="" style={{fontSize:'1.1em', color:'black'}}>Choose Alt</div>}
                    onChange={(selectedOption) => {
                      if (selectedOption){
                        this.selectGolfer('ALT', selectedOption.value, selectedOption.label,);
                      }else{
                        this.selectGolfer('ALT', 0);
                      }
                    }
                    } />

                </Col>
              </Row>
            </ModalBody>
        <ModalFooter>
          <Button outline className="btn-sm mt-2" color="primary" onClick={() => {this.props.saveSelectGolfer(this.state.selectedEntry);}} >Save</Button>
          <Button outline className="btn-sm mt-2" color="secondary" onClick={() => {this.props.toggleEntryGolferEditModal();}} >Cancel</Button>
        </ModalFooter>
      </Modal>


    )

  }
}
