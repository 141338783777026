import React from 'react';

import {
  Card,
  Button,
} from 'reactstrap';

import { useTable, useSortBy } from "react-table";


import {
  get_commiss_report,
} from 'utils/api.js';


import 'common/McModal.css';
import CsvDownloader from 'react-csv-downloader';
import FontAwesome from 'react-fontawesome';
import {ReactTableStyles} from 'utils/constants';


export default class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportResult: [],
      reportCols: this.props.reportCols,
    };

  }

  componentDidMount() {
    this.getReportResult(this.props.activeLeague);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {


  }


  getReportResult = (league) => {
    get_commiss_report(league, this.props.reportCd,
    ({success, reportResult}) => {
      this.setState({
        reportResult: (success?reportResult:[])
      });
    }, (error) => {
      console.log(error);
    });
  }



  render() {

    const csvExprtColumns = this.state.reportCols.map(a => {
      return {
        'id': a.accessor,
        'displayName': a.Header,
      }
    });

    return (
      <div>
          <Card className="pb-0" style={{border:'0px'}}>

            <div className="d-flex justify-content-around  p-2 py-3"
                style={{backgroundColor:'rgba(236, 240, 241, 0.5)'}}>
                <div className="col-sm-6 pt-1 ml-n3"
                  style={{"fontSize": "1rem", "fontWeight": "bold", "textTransform": "uppercase",}}>
                  {this.props.reportName}
                </div>
                <div className="col-sm-6 text-right pr-0">
                  <div className="btn-group">
                    {this.props.enableDownloadCSV &&
                    <CsvDownloader
                      filename={this.props.reportName}
                      separator=","
                      wrapColumnChar='"'
                      columns={csvExprtColumns}
                      datas={this.state.reportResult}>
                      {
                        this.state.reportResult.length > 0 ?
                        <Button size="sm" color="success" outline className="ml-3 ml-md-none text-nowrap"
                          style={{minWidth:'60px'}}>
                          <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                        </Button> :
                        <Button size="sm" color="secondary" outline disabled className="ml-3 ml-md-none text-nowrap"
                          style={{minWidth:'60px'}}>
                          <FontAwesome name="file-excel-o" />&nbsp;&nbsp;<FontAwesome name="download" />
                        </Button>
                      }
                    </CsvDownloader>
                    }
                  </div>
                </div>

            </div>
            <div style={{border:'1px solid rgba(0, 0, 0, 0.125)'}}>
              <ReactCustomTable columns={this.state.reportCols} data={this.state.reportResult}
              enableSort={this.props.enableSort}/>
            </div>

          </Card>
      </div>
    );
  }


}


const ReactCustomTable = ({ columns, data, enableSort}) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data
    },
    (enableSort?useSortBy:null)
    );


    return (
      <ReactTableStyles>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead style={{backgroundColor: "gray", "color":"white", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                  {...column.getHeaderProps(enableSort?column.getSortByToggleProps():{
                    className: column.collapse ? 'collapse' : '',
                  })}
                  style={{
                    cursor: (enableSort?"pointer":""),
                  }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽" // Descending
                          : " 🔼" // Ascending
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ "fontSize": "0.7rem",}}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.className ? cell.column.className : cell.column.className,
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ):(
              // Display message when no data is available
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontSize: '1.2em',
                    fontWeight:'600'
                  }}
                >
                  no data
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>
      {/*
      <div className="pagination">

      </div>*/}
    </ReactTableStyles>
    );
  };
