import React from 'react';
import Linkify from 'react-linkify';

import {
  Alert,
  Container, Row, Col,
  Button, ButtonDropdown,
  DropdownToggle, DropdownMenu, DropdownItem,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Badge
} from 'reactstrap';

import {
  Link,
  Route,
} from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import FontAwesome from 'react-fontawesome';
//import Out from 'img/out_lg.png';
import Largewarning from 'img/largewarning.png';

//import { isMobPortrait } from 'utils/responsive.js';

import { DateTime } from 'luxon';

import {EventTitle} from 'common/EventTitle.js';
import GolferModal from 'common/GolferModal.js';
import Field from 'pages/user/Field';
import EventRulesModal from 'pages/user/EventRulesModal';
import Banner from 'common/Banner.js';

import EntryFeeDepositFund from 'pages/user/EntryFeeDepositFund.js';

import {
  get_event_field,
  save_lineup,
  delete_lineup,
  edit_team_name,
  mark_entry_without_donation,
} from 'utils/api.js';

import { get_promo_content } from '../../utils/promoContent.js';

import 'pages/user/PickTeam.css';

export default class PickTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      golferModalOpen: false,
      golferModalGolferId: null,
      team: props.event.tour === 'liv'?{
        groupA: [-1, null, null, null],
        groupB: [-2, null, null, null],
        groupC: [-3, -4, null, null, null],
        groupD: [null, null, null]
      }:{
        groupA: [null, null, null, null],
        groupB: [null, null, null, null],
        groupC: [null, null, null, null, null],
        groupD: [null, null, null]
      },
      golfers: {
        groupA: [],
        groupB: [],
        groupC: [],
        groupD: [],
      },
      selectedEntry: null,
      newlyAddedEntryId: null,
      entries: [],
      totalTeams: null,
      editMode: true,
      error: null,
      promo: false,
      cup: null,
      midsave: false,
      maxEntriesAllow: 0,
      maxEntriesPerUserAllow: 0,
      noMoreTeam: false,
      nmbrUserEntriesRemaining: 0,
      mcAdvConfigGrp: null,
      userLedgerIdDepositFund: null,
      addlDonationModal: false,
    }

    this.getEventField = this.getEventField.bind(this);
    this.newEntry = this.newEntry.bind(this);
    this.chooseEntry = this.chooseEntry.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.canSaveLineup = this.canSaveLineup.bind(this);
    this.saveLineup = this.saveLineup.bind(this);
    this.editTeamName = this.editTeamName.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.cancelEditMode = this.cancelEditMode.bind(this);
    this.selectGolfer = this.selectGolfer.bind(this);
    this.unselectGolfer = this.unselectGolfer.bind(this);
    this.toggleGolferModal = this.toggleGolferModal.bind(this);
    this.showGolferDetails = this.showGolferDetails.bind(this);
    this.setUserLedgerIdDepositFund = this.setUserLedgerIdDepositFund.bind(this);
  }
  componentDidMount() {
    this.getEventField(this.props.event.eventid);
    this.setState({error: null});
    get_promo_content().then(({events, announcement}) => {
      this.setState({promocontent: events, announcement});
    });


    let mcLeaderboardAdv_config = 'LB_MC_ADV_RGLR_LEAGUE';
    if (this.props.event.leagueType === 'free'){
      mcLeaderboardAdv_config = 'LB_MC_ADV_FREE_LEAGUE';
    }else if (this.props.event.leagueType === 'club'){
      mcLeaderboardAdv_config = 'LB_MC_ADV_CLUB_LEAGUE';
    }
    this.setState({mcAdvConfigGrp:mcLeaderboardAdv_config,});

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.event.eventid !== this.props.event.eventid) {
      this.getEventField(nextProps.event.eventid);

      this.setState({
        golferModalOpen: false,
        golferModalGolferId: null,
        team: nextProps.event.tour === 'liv'?{
          groupA: [-1, null, null, null],
          groupB: [-2, null, null, null],
          groupC: [-3, -4, null, null, null],
          groupD: [null, null, null]
        }:{
          groupA: [null, null, null, null],
          groupB: [null, null, null, null],
          groupC: [null, null, null, null, null],
          groupD: [null, null, null]
        },
        golfers: {
          groupA: [],
          groupB: [],
          groupC: [],
          groupD: [],
        },
        selectedEntry: null,
        newlyAddedEntryId: null,
        entries: [],
        totalTeams: null,
        editMode: true,
        error: null,
        promo: false,
        cup: null,
        midsave: false,
        maxEntriesAllow: 0,
        maxEntriesPerUserAllow: 0,
        noMoreTeam: false,
        nmbrUserEntriesRemaining: 0,
        mcAdvConfigGrp: null,
        addlDonationModal: false,
      });

    }
  }

  setUserLedgerIdDepositFund(userLedgerIdDepositFund){
    this.setState({userLedgerIdDepositFund});
  }

  getEventField(eventid) {
    get_event_field(eventid, ({golfers, entries, totalTeams, promo, cup, maxEntriesAllow, maxEntriesPerUserAllow, userDetails,toc,isTocFinalEvent,userInToc, league}) => {
      const golfersObj = {
        groupA: golfers[0],
        groupB: golfers[1],
        groupC: golfers[2],
        groupD: golfers[3]
      }

      const noMoreTeam = (((maxEntriesPerUserAllow>0 && maxEntriesPerUserAllow<=entries.filter((entry) => entry.status==='paid').length) ||
                        (maxEntriesAllow>0 && maxEntriesAllow<=totalTeams))?true:false);
      const nmbrUserEntriesRemaining = maxEntriesPerUserAllow>0?(maxEntriesPerUserAllow - entries.filter((entry) => entry.status==='paid').length):999;

      this.setState({golfers: golfersObj, entries, totalTeams, promo, cup, maxEntriesAllow:maxEntriesAllow,
                    maxEntriesPerUserAllow:maxEntriesPerUserAllow, noMoreTeam:noMoreTeam,
                    userDetails, league, userLedgerIdDepositFund: league.userLedgerIdDepositFund,
                    toc,isTocFinalEvent,userInToc, nmbrUserEntriesRemaining});

      if (entries && entries.length > 0) {
        this.chooseEntry(entries[0]);
      }
    }, (error) => {
      console.log("An unexpected error occurred loading this content.");
    });
  }
  chooseEntry(selectedEntry) {
    this.setState({
      selectedEntry,
      team: JSON.parse(JSON.stringify(selectedEntry.roster)),
      editMode: false,
      newlyAddedEntryId: null,
    })
  }
  deleteEntry(entry) {
    delete_lineup(this.props.event.eventid, entry.entry_id, ({success, entries, cup, totalTeams}) => {
      if (success) {

        let noMoreTeam = (((this.state.maxEntriesPerUserAllow>0 && this.state.maxEntriesPerUserAllow<=entries.filter((entry) => entry.status==='paid').length) ||
                          (this.state.maxEntriesAllow>0 && this.state.maxEntriesAllow<=totalTeams))?true:false);
        const nmbrUserEntriesRemaining = this.state.maxEntriesPerUserAllow>0?(this.state.maxEntriesPerUserAllow - entries.filter((entry) => entry.status==='paid').length):999;

        this.setState({entries, cup, totalTeams, noMoreTeam:noMoreTeam, nmbrUserEntriesRemaining });
        if (this.state.selectedEntry.entry_id === entry.entry_id) {
          this.newEntry();
        }
      } else {
        console.log("Could not delete entry: " + entry);
      }
    }, (error) => {
      console.log("Could not delete entry: " + entry + " -- " + error);
    })
  }
  newEntry() {

    this.setState({selectedEntry: null, editMode: true, newlyAddedEntryId:null, team: this.props.event.tour === 'liv'?{
      groupA: [-1, null, null, null],
      groupB: [-2, null, null, null],
      groupC: [-3, -4, null, null, null],
      groupD: [null, null, null]
    }:{
      groupA: [null, null, null, null],
      groupB: [null, null, null, null],
      groupC: [null, null, null, null, null],
      groupD: [null, null, null]
    },})
  }
  canSaveLineup() {
    for (let [, group] of Object.entries(this.state.team)) {
      for (let golfer of group) {
        if (golfer === null) {
          return false;
        }
      }
    }
    return true;
  }
  saveLineup(selectedEntryName, cupOptIn, resState, userLedgerIdDepositFund, onSuccess) {
    console.log('saveLineup called');
    this.setState({midsave: true, error: null})
    save_lineup(
      this.props.event.eventid,
      this.state.team,
      this.state.selectedEntry ? this.state.selectedEntry.entry_id : (this.state.newlyAddedEntryId?this.state.newlyAddedEntryId:null),
      selectedEntryName,
      cupOptIn,
      resState,
      userLedgerIdDepositFund,
      null, // memberid
      ({success, entries, entry_id, cup, totalTeams, userDetails, league, newlyAddedEntry, error}) => {
        if (!success) {
          this.setState({error, midsave: false});
          confirmAlert({
            title: 'Error',
            message: error,
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });
          return;
        }
        let selectedEntry = null;
        for (let entry of entries) {
          if (entry.entry_id === entry_id) {
            selectedEntry = entry;
            break;
          }
        }
        //console.log ('after save : ' + this.props.maxEntriesPerUserAllow + ' ' + this.state.maxEntriesPerUserAllow);

        let noMoreTeam = (((this.state.maxEntriesPerUserAllow>0 && this.state.maxEntriesPerUserAllow<=entries.filter((entry) => entry.status==='paid').length) ||
                          (this.state.maxEntriesAllow>0 && this.state.maxEntriesAllow<=totalTeams))?true:false);
        const nmbrUserEntriesRemaining = this.state.maxEntriesPerUserAllow>0?(this.state.maxEntriesPerUserAllow - entries.filter((entry) => entry.status==='paid').length):999;

        //console.log ('after save : ' + noMoreTeam);

        this.setState({entries, editMode: (newlyAddedEntry.status==='unpaid'?true:false), selectedEntry, cup, midsave: false, totalTeams, noMoreTeam:noMoreTeam, userDetails, league, userLedgerIdDepositFund: league.userLedgerIdDepositFund,newlyAddedEntryId:entry_id, nmbrUserEntriesRemaining});

        if (onSuccess) {
          onSuccess();
        }
    }, (error) => {
      this.setState({error: 'An unexpected error occurred. Please try again later or contact support if this persists.', midsave: false});
      /*confirmAlert({
        title: 'Error in Saving team',
        message: 'An unexpected error occurred. Please try again later or contact support if this persists.',
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });*/

    })
  }

  editTeamName(entryId, entryName, cupOptIn, onSuccess) {
    this.setState({midsave: true})
    edit_team_name(this.props.event.eventid, entryId, entryName, cupOptIn,
      ({success, entries, cup, error}) => {
        if (!success) {
          this.setState({error, midsave: false});
          confirmAlert({
            title: 'Error',
            message: error,
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });
          return;
        }
        let selectedEntry = null;
        for (let entry of entries) {
          if (entry.entry_id === entryId) {
            selectedEntry = entry;
            break;
          }
        }
        this.setState({entries, cup, selectedEntry, midsave: false});
        if (onSuccess) {
          onSuccess();
        }
    }, (error) => {
      this.setState({error: 'An unexpected error occurred. Please try again later or contact support if this persists.', midsave: false});
      confirmAlert({
        title: 'Error',
        message: 'An unexpected error occurred. Please try again later or contact support if this persists.',
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
    })
  }
  toggleEditMode() {
    this.setState({editMode: !this.state.editMode})
  }
  cancelEditMode() {
    this.setState({
      editMode: false,
      team: JSON.parse(JSON.stringify(this.state.selectedEntry.roster))
    });
  }

  markEntryWithoutDonation: Function = (entryid, onSuccess) =>{
    this.setState({midsave: true})
    mark_entry_without_donation(this.props.event.eventid, entryid,
      ({success, entries, totalTeams, error}) => {
        if (!success) {
          this.setState({error, midsave: false});
          return;
        }
        let selectedEntry = null;
        for (let entry of entries) {
          if (entry.entry_id === entryid) {
            selectedEntry = entry;
            break;
          }
        }
        let noMoreTeam = (((this.state.maxEntriesPerUserAllow>0 && this.state.maxEntriesPerUserAllow<=entries.filter((entry) => entry.status==='paid').length) ||
                          (this.state.maxEntriesAllow>0 && this.state.maxEntriesAllow<=totalTeams))?true:false);
        this.setState({entries, selectedEntry, midsave: false, editMode:false, totalTeams, noMoreTeam:noMoreTeam});
        if (onSuccess) {
          onSuccess();
        }
    }, (error) => {
      this.setState({error: 'An unexpected error occurred. Please try again later or contact support if this persists.', midsave: false});
    })
  }
  selectGolfer(group, golfer) {
    let team = {...this.state.team};
    const emptySlot = team[group].indexOf(null);

    if (emptySlot === -1) {
      console.log('cannot select golfer - team full');
    } else {
      team[group][emptySlot] = golfer;
      this.setState({team});
    }
  }
  unselectGolfer(group, golfer) {
    let team = {...this.state.team};
    const golferSlot = team[group].indexOf(golfer);
    if (golferSlot === -1) {
      console.log('cannot unselect golfer - not found');
    } else {
      team[group][golferSlot] = null;
      this.setState({team});
    }
  }
  toggleGolferModal() {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }
  showGolferDetails(golferid) {

    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }

  moveToProfilePage: Function = () => {
    this.props.history.push('/profile/settings');
  }

  toggleAddlDonation = () => {
    this.setState({addlDonationModal: !this.state.addlDonationModal});
  }

  handlePreAddlDonationSteps = (onSuccess) =>{
    onSuccess();
  }

  addlDonationSuccess: Function = (userLedgerId) =>{
    this.setState({addlDonationModal: false,});
    confirmAlert({
      title: 'Thank You',
      message: 'Thank you for your donation. Good luck!',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
  }

  render() {
    const commonPickGroupProps = {
      team: this.state.team,
      golfers: this.state.golfers,
      selectGolfer: this.selectGolfer,
      unselectGolfer: this.unselectGolfer,
      showGolferDetails: this.showGolferDetails,
      editMode: this.state.editMode,
      event: this.props.event,
    }

    const { lock_time, perfect_team } = this.props.event;
    let teetime = `Lineups lock ${perfect_team ? '2 hours before' : 'at'} first tee off`;
    if (!!lock_time) {
      let dt = DateTime.fromISO(lock_time, { zone: 'UTC' }).setZone('local')
      teetime = 'on ' + dt.toFormat('EEEE') + ' at ' + dt.toFormat('t ZZZZ');
    }

    const maybeLinkWrapper = (link, element) => link
      ? <a href={link} target='_blank' rel="noopener noreferrer">{element}</a>
      : element;


    let eventRules = <span>Event Rules</span>
    let prize = <span>Prizes</span>
    let eventRulesPrize = <span>Rules & Prizes</span>
    return (
      <Container fluid className="pickTeam">
        <EventTitle {...this.props} perfectteam={this.props.event.perfect_team} className="px-3 px-lg-4 "/>
        <div className="px-3 px-lg-4" style={{color: "#999"}}>
          {teetime}
        </div>
        <Route path="/events/:eventid/field" render={(params) =>
          <div className="px-3 px-lg-4">
            {!this.props.event.prizePayout &&
            <EventRulesModal {...this.props} component={eventRules} />
            }
            {this.props.event.prizePayout &&
              <>
              <span className="mx-2">&bull;</span>
              <EventRulesModal {...this.props} component={eventRulesPrize} />
              </>
            }
            {this.props.event.perfect_team &&
              <span>
                <span className="mx-2">&bull;</span>
                <a target="_blank" href="/perfectteam">Perfect Team</a>
              </span>
            }

            <Field {...params} {...this.props} />
          </div>
        } />
        <Route exact path="/events/:eventid" render={() =>
          <div>
            { this.state.totalTeams !== null &&
              <div className="px-3 px-lg-4">
                <Link to={"/events/"+this.props.event.eventid+"/field"}>
                  {this.state.totalTeams + ' teams entered'}
                </Link>
                {!this.props.event.prizePayout &&
                  <>
                    <span className="mx-2">&bull;</span>
                    <EventRulesModal {...this.props} component={eventRules} />
                  </>
                }
                {this.props.event.prizePayout &&
                  <>
                  <span className="mx-2">&bull;</span>
                  <EventRulesModal {...this.props} component={eventRulesPrize} />
                  </>
                }
                {this.props.event.perfect_team &&
                  <span>
                    <span className="mx-2">&bull;</span>
                    <a target="_blank" href="/perfectteam">Perfect Team</a>
                  </span>
                }
                {(this.state.league.leagueType === 'CHARITY' && this.state.selectedEntry) &&
                  <span>
                    <span className="mx-2">&bull;</span>
                    <a  href="#" onClick={() => {this.toggleAddlDonation(); return false;}}>Donate</a>
                  </span>
                }
              </div>
            }

            {(this.state.isTocFinalEvent) &&
              <div className="px-3 px-lg-4 my-2">
                <div className="text-danger">{'Tournament of Champions'}</div>
              </div>
            }
            <Container fluid className="pickTeamContainer">
              {this.state.promo && this.state.promocontent &&
                maybeLinkWrapper(this.state.promocontent.link,
                <Row noGutters className="py-4 d-flex justify-content-center">
                  <div className="pickTeamPromoImageContainerMobile d-sm-none">
                    <img className="pickTeamPromoImageAspect" src={this.state.promocontent.mobile} style={{maxWidth: "100%"}} alt="Promo banner."/>
                  </div>
                  <div className="pickTeamPromoImageContainerDesktop d-none d-sm-block">
                    <img className="pickTeamPromoImageAspect" src={this.state.promocontent.desktop} style={{maxWidth: "100%"}} alt="Promo banner."/>
                  </div>
                </Row>
              )}
              {this.state.promo && this.state.announcement && <PromoAnnouncement announcement={this.state.announcement} />}

              {(this.state.league && this.state.league.use_team_names) &&
              <PickTeamEntries
                event={this.props.event}
                entries={this.state.entries}
                newEntry={this.newEntry}
                chooseEntry={this.chooseEntry}
                deleteEntry={this.deleteEntry}
                selectedEntry={this.state.selectedEntry}
                toggleEditMode={this.toggleEditMode}
                noMoreTeam={this.state.noMoreTeam}
                league={this.state.league}
                getEventField={this.getEventField}
                newlyAddedEntryId={this.state.newlyAddedEntryId}
              />
              }

              <PickTeamContentHeader
                event={this.props.event}
                editMode={this.state.editMode}
                selectedEntry={this.state.selectedEntry}
                disableSave={!this.canSaveLineup()}
                deleteEntry={this.deleteEntry}
                saveLineup={this.saveLineup}
                editLineup={this.editLineup}
                editTeamName={this.editTeamName}
                toggleEditMode={this.toggleEditMode}
                cancelEditMode={this.cancelEditMode}
                error={this.state.error}
                cup={this.state.cup}
                midsave={this.state.midsave}
                mcAdvConfigGrp={this.state.mcAdvConfigGrp}
                activeLeague={this.props.activeLeague}
                userDetails={this.state.userDetails}
                league={this.state.league}
                getEventField={this.getEventField}
                userLedgerIdDepositFund = {this.state.userLedgerIdDepositFund}
                setUserLedgerIdDepositFund={this.setUserLedgerIdDepositFund}
                moveToProfilePage={this.moveToProfilePage}
                markEntryWithoutDonation={this.markEntryWithoutDonation}
                newlyAddedEntryId={this.state.newlyAddedEntryId}
                isTocFinalEvent={this.state.isTocFinalEvent}
                userInToc={this.state.userInToc}
                nmbrUserEntriesRemaining={this.state.nmbrUserEntriesRemaining}
              />
              {/*<Row className="pt-3 pickTeamMainContent" noGutters>
                <Col className="mb-0 px-lg-3">
                    <div style={{maxWidth: 750}}>
                      <Banner configGrp={this.state.mcAdvConfigGrp} leagueId={this.props.activeLeague} />
                    </div>
                </Col>
              </Row>
              */}
              {/*For Mobile*/}
              <Row noGutters className="pt-2 mx-lg-2 d-flex justify-content-center d-sm-none">
                 <Col className="mb-0 px-lg-3 d-flex justify-content-start px-3">
                    <div style={{maxWidth: 750}}>
                      <Banner configGrp={this.state.mcAdvConfigGrp} leagueId={this.props.activeLeague} />
                    </div>
                  </Col>
              </Row>
              <Row className="pt-3 pickTeamMainContent" noGutters>
                <PickGroup group="groupA" label="Group A" {...commonPickGroupProps} />
                <PickGroup group="groupB" label="Group B" {...commonPickGroupProps} />
                <PickGroup group="groupC" label="Group C" {...commonPickGroupProps} />
                <PickGroup group="groupD" label="Group D" {...commonPickGroupProps} />
              </Row>
              <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>

              {(this.state.league && this.state.selectedEntry) &&
              <Modal isOpen={this.state.addlDonationModal} autoFocus={true} className="pickTeamNameModal">
                <ModalHeader toggle={this.toggleAddlDonation}>Donation</ModalHeader>
                <ModalBody>
                  <EntryFeeDepositFund entryFee={this.state.league.entryFee}
                    leagueId={this.props.activeLeague}
                    leagueEventId={this.state.league.leagueEventId}
                    charityName={this.state.league.charityName}
                    depositFundSuccess={this.addlDonationSuccess}
                    entryId={this.state.selectedEntry.entry_id}
                    handlePrePaymentSteps={this.handlePreAddlDonationSteps}
                    toggleMovedToPaymentScreen={this.toggleAddlDonation}/>
                </ModalBody>
              </Modal>
              }

            </Container>
          </div>
        } />
      </Container>
    )
  }
}



class PickTeamContentHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entryName: props.selectedEntry !== null ? props.selectedEntry.name : "",
      entryId: props.selectedEntry !== null ? props.selectedEntry.entry_id : null,
      saveModal: false,
      editModal: false,
      optionsDropdown: false,
      teamsDropdown: false,
      error: null,
      cupOptIn: true,
      overrideText: false,
      userResState: props.userDetails !== undefined && props.userDetails !== null ? props.userDetails.resState : null,
      withoutDonationModal: false,
      withoutDonation: false,
      movedToPaymentScreen: false,

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
    this.toggleSaveModal = this.toggleSaveModal.bind(this);
    this.handleSaveModalSubmit = this.handleSaveModalSubmit.bind(this);

    this.toggleOptionsDropdown = this.toggleOptionsDropdown.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleEditModalSubmit = this.handleEditModalSubmit.bind(this);
    this.handleCupOptIn = this.handleCupOptIn.bind(this);
    this.handleCupOptOut = this.handleCupOptOut.bind(this);
    this.setOverrideText = this.setOverrideText.bind(this);

  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  handleSaveButton() {
    if (this.props.disableSave === true){
      confirmAlert({
        title: 'Alert',
        message: 'Incomplete Roster: Please select 12 golfers and 4 alternates.',
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return;
    }


    if (this.state.entryId === null) {
        if (!this.props.league.use_team_names && this.props.league.leagueType !== 'CHARITY'){
          let teamname = 'ENTRY'+this.props.league.member_id;
          this.setState({entryName:teamname});
          this.props.saveLineup(teamname, this.state.cupOptIn, this.state.resState, this.props.userLedgerIdDepositFund);
        }else{
          if (!this.props.league.use_team_names && this.props.league.leagueType === 'CHARITY'){
            let teamname = 'ENTRY'+this.props.league.member_id;
            this.setState({entryName:teamname});
          }
          this.toggleSaveModal();
        }
    } else {
      this.props.saveLineup(this.state.entryName, this.state.cupOptIn, this.state.resState, this.props.userLedgerIdDepositFund);
    }
  }

  toggleSaveModal() {
    this.setState({
      saveModal: !this.state.saveModal,
      movedToPaymentScreen: false,
      withoutDonation: false,
      error: null
    });
  }



  handleSaveModalSubmit(event) {
    /*if (this.state.resState === '' && this.props.userDetails.resStateRequired === 2){
      confirmAlert({
        title: 'Stop',
        message: 'Please provide your state of residence.',
        buttons: [
          {
            label: 'OK',
            onClick: () => { }
          }
        ]
      });
      return;
    }*/
    this.props.saveLineup(this.state.entryName, this.state.cupOptIn, this.state.resState, this.props.userLedgerIdDepositFund, () => this.toggleSaveModal());
    if (!event) { return; }
    event.preventDefault();
    event.stopPropagation();
  }



  toggleOptionsDropdown() {
    this.setState({optionsDropdown: !this.state.optionsDropdown});
  }
  toggleEditModal() {
    this.setState({
      editModal: !this.state.editModal,
      error: null
    });
  }
  handleEditModalSubmit(event) {
    this.props.editTeamName(this.state.entryId, this.state.entryName, this.state.cupOptIn, () => this.toggleEditModal());
    if (!event) { return; }
    event.preventDefault();
    event.stopPropagation();
  }
  handleCupOptIn(event) {
    this.setState({cupOptIn:true});
  }
  handleCupOptOut(event) {
    this.setState({cupOptIn:false});
  }
  setOverrideText() {
    confirmAlert({
      title: 'Alert',
      message: 'You MUST use an existing team otherwise you will fall behind or can be disqualified from the season long contest. Are you sure you want to create a new team?',
      buttons: [
                {label: 'Yes',onClick: () => {this.setState({overrideText: true});}},
                {label: 'Cancel',onClick: () => { }},
               ]
    });

  }

  componentWillReceiveProps(nextProps) {
    const entryId = nextProps.selectedEntry !== null ? nextProps.selectedEntry.entry_id : null;
    const entryName = nextProps.selectedEntry !== null ? nextProps.selectedEntry.name : "";

    let cupOptIn = null;
    if (nextProps.selectedEntry !== null) {
      cupOptIn = nextProps.selectedEntry.cup;
    } else if (nextProps.cup && nextProps.cup.first_event) {
      cupOptIn = true;
    }

    if (entryId !== this.state.entryId) {
      const overrideText = nextProps.selectedEntry !== null;
      this.setState({entryName, entryId, overrideText})
    }
    //console.log('nextProps.userDetails :' + nextProps.userDetails)
    const resState = nextProps.userDetails !== null && nextProps.userDetails !== undefined ? nextProps.userDetails.resState : null;

    this.setState({error: nextProps.error, cupOptIn, resState});
  }

  depositFundSuccess: Function = (userLedgerId) =>{
    this.setState({depositFundModal: false, saveModal:false, error: null});
    this.props.getEventField(this.props.event.eventid);
    confirmAlert({
      title: 'Thank You',
      message: 'Thank you for your donation. Good luck!',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
  }

  handlePrePaymentSteps = (onSuccess) =>{
    this.props.saveLineup(this.state.entryName, this.state.cupOptIn, this.state.resState, this.props.userLedgerIdDepositFund, () => {this.setState({movedToPaymentScreen:true});onSuccess()});
  }

  toggleWithoutDonation = () =>{
    if (!this.state.depositFund){
      this.props.saveLineup(this.state.entryName, this.state.cupOptIn, this.state.resState,
        this.props.userLedgerIdDepositFund, () => {
          this.setState({withoutDonation: !this.state.withoutDonation, error: null});
        });
      return;
    }
    this.setState({withoutDonation: !this.state.withoutDonation, error: null});
  }

  toggleMovedToPaymentScreen = (movedToPaymentScreen) =>{
    if (!!movedToPaymentScreen){
      this.setState({movedToPaymentScreen:movedToPaymentScreen});
    }else{
      this.setState({movedToPaymentScreen: !this.state.movedToPaymentScreen});
    }
  }

  render() {
    const isEditable = this.props.selectedEntry !== null && this.props.editMode === false;
    const isNewLineup = this.state.entryId === null;

    const saveButton = (
      <span className="d-inline-block mt-1">
        <Button
          color={!this.props.disableSave ? "success" : "secondary"}
          className="ml-3"
          onClick={this.handleSaveButton}
          style={{opacity:(this.props.disableSave ? '0.65':'1')}}>
          Save Lineup
        </Button>
        { !isNewLineup &&
          <Button
            outline
            color="danger"
            className="ml-3"
            onClick={this.props.cancelEditMode}>
            Cancel
          </Button>
        }
      </span>
    )
    const optionsButtonGroup = (
      <ButtonDropdown isOpen={this.state.optionsDropdown} toggle={this.toggleOptionsDropdown} className="ml-3 mt-1">
        <DropdownToggle caret color="success">Edit Team</DropdownToggle>
        <DropdownMenu className="pickTeamOptionsDropdown">
          <DropdownItem onClick={this.props.toggleEditMode}>Edit Lineup</DropdownItem>
          {(this.props.league && this.props.league.use_team_names) && <DropdownItem onClick={this.toggleEditModal}>Edit Name</DropdownItem>}
          <DropdownItem divider/>
          <DropdownItem onClick={() => this.props.deleteEntry(this.props.selectedEntry)}>Delete Team</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    )

    const focusSaveModalInput = () => {this.saveModalInput && this.saveModalInput.focus();}
    const focusEditModalInput = () => {this.editModalInput && this.editModalInput.focus();}

    const cupOptInOption = (
      <div className="pt-2 d-none">
        <div>Enter this team into the season long tournament?</div>
        <div className="text-muted small mb-2">Please check League Home or with your Commissioner for additional instructions regarding your Cup season.</div>
        <div className="pl-4">
          <Input type="radio" name="cupOptIn" className="mr-3" value={!!this.state.cupOptIn} checked={!!this.state.cupOptIn} onChange={this.handleCupOptIn} />
          <span style={{color:"#555"}}>Yes, use this team all season long. </span>
        </div>
        <div className="pl-4">
          <Input type="radio" name="cupOptOut" className="mr-3" value={!this.state.cupOptIn} checked={!this.state.cupOptIn} onChange={this.handleCupOptOut} />
          <span style={{color:"#555"}}>No, this team is only for this event.</span>
        </div>
      </div>
    )

    const moreCupTeamsToGo = this.props.cup && !this.props.cup.first_event && this.props.cup.valid_names.find(({entered}) => !entered) !== undefined;

    // const enteredStyle = {textDecoration: 'line-through', color: "#999"};
    //const toGoStyle = {color: 'blue', textDecoration: 'underline', cursor: 'pointer'};
    const nameFunc = (entryName, func) => {
      this.setState({entryName}, func);
    }
    const teamsToGo = (func) => (
      <div className="mt-2" style={{color: "#555"}}>
        {!moreCupTeamsToGo
          ? <span>All Cup teams have been assigned.</span>
          : (
            <div>
              <div>{this.state.overrideText
                ? 'Or use this lineup as one of your Cup teams:'
                : 'Choose one of your Cup teams for this lineup:'
              }</div>
              <div style={{overflow:'auto'}}>
                <div className="row my-2 justify-content-aroun">
              {this.props.cup.valid_names.filter(({entered}) => !entered).sort((a,b)=>{return a.name.length-b.name.length;}).map(({name/*, entered*/}, index)=>(
                <>
                {(index>0 && index%3===0) && <div class="w-100"></div>}
                <div class="ml-2">
                  <Button color="warning" onClick={()=>{nameFunc(name, func);}} className="ml-2 btn-sm my-1 text-nowrap">{name}</Button>
                </div>
                </>
              ))}
                </div>
              </div>
              {this.props.cup.scoring_method === 'Gross Score' &&
                <div className="mt-3 muted" style={{fontSize: '0.85em'}}>Unused teams are eliminated from the season long cup competition. New teams are for this tournament only. You should choose one of the teams above to build on your standing.</div>
              }
              {((this.props.cup.scoring_method === 'FedEx Weighted' || this.props.cup.scoring_method === 'FedEx Equal') &&
                this.props.cup.allow_new_teams === 'All Season Long') &&
                <div className="mt-3 muted" style={{fontSize: '0.85em'}}>Unused teams receive NO points in the season long cup competition. New teams start with zero points. You should choose one of the teams above to build on your standing.</div>
              }
              {((this.props.cup.scoring_method === 'FedEx Weighted' || this.props.cup.scoring_method === 'FedEx Equal') &&
                this.props.cup.allow_new_teams === 'First Event Only') &&
                <div className="mt-3 muted" style={{fontSize: '0.85em'}}>Unused teams receive zero Cup points. New teams are for this tournament only.</div>
              }
            </div>
          )
        }
      </div>
    )

    const perfectTeam = this.props.event.perfect_team &&
      <div className="pt-3">
        {!this.props.event.eligible &&
          <div className="mb-2 text-danger">
            <div>Sorry to trouble you. To be eligible for perfect team jackpot you must complete your profile before the contest locks.</div>
            {/*<div>
              <a target="_blank" href="/profile/settings">
                Update My Profile <FontAwesome name="external-link" />
              </a>
            </div>*/}
          </div>
        }

         <div className="text-muted small">
           <div>Only eligible to legal US residents who are at least 18 or age of majority in state of residence. By submitting an entry you agree to all terms in the
           <a target="_blank" href="/perfectteam">
            &nbsp;Contest Rules <FontAwesome name="external-link" />
           </a>.
           </div>
         </div>
      </div>;

      const states = [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ];

    const stateSelectOptions = states.map((item) =>
        <option value={item}>{item}</option>
    );

    //const stl = isMobPortrait()?{marginTop:'-55px',width:'180px', float:'right'}:{};
    return (
      <div>
        <Row noGutters className="pt-2 mx-lg-2 justify-content-end">
            <Col md="6" xs="2" className="mb-0 px-lg-3 justify-content-start pt-2 px-3 d-none d-sm-flex" >
                <div >
                  <Banner configGrp={this.props.mcAdvConfigGrp} leagueId={this.props.activeLeague} textAlign={'left'}/>
                </div>
            </Col>
            {(!this.props.isTocFinalEvent || (this.props.isTocFinalEvent && this.props.userInToc) ) &&
            <Col md="6" xs="10" className="d-flex justify-content-end px-1">
              {isEditable ? optionsButtonGroup : saveButton}
            </Col>
            }
        </Row>

        {(this.props.isTocFinalEvent && !this.props.userInToc) &&
          <Row noGutters className="pt-2 mx-lg-2 justify-content-center">
              <Col md="6" xs="10" className="d-flex justify-content-center px-1">
                <div className="text-danger font-weight-bold">{'You did not qualify for the Tournament of Champions'}</div>
              </Col>
          </Row>
        }

        {/*<Row noGutters className="pt-2 mx-lg-2 d-flex justify-content-center d-sm-none">
            <Col className="d-flex justify-content-end px-1">
              <div>
                {isEditable ? optionsButtonGroup : saveButton}
              </div>
            </Col>
        </Row>*/}

        <Modal isOpen={this.state.saveModal}  onOpened={focusSaveModalInput} autoFocus={true} className="pickTeamNameModal">
          <ModalHeader toggle={this.toggleSaveModal}>Enter a Team Name</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSaveModalSubmit}>
              {(!this.props.cup || !moreCupTeamsToGo || this.state.overrideText) &&
                (this.props.league && !this.props.league.amountNeedsToDeposit > 0)  &&
                <input
                  ref={(input) => {this.saveModalInput = input;}}
                  className="pickTeamNameModal_input"
                  type="text"
                  name="entryName"
                  value={this.state.entryName}
                  onChange={this.handleChange}
                  placeholder="e.g. Fairway to Heaven"
                  maxLength="25"
                />
              }
              {this.props.cup && this.props.cup.first_event &&
                cupOptInOption}
              {this.props.cup && !this.props.cup.first_event && this.props.cup.valid_names.length > 0 &&
                teamsToGo(this.handleSaveModalSubmit)}
              {this.props.event.perfect_team &&
                perfectTeam
              }
              {(!!this.props.userDetails && this.props.userDetails.resState === '' && this.props.userDetails.resStateRequired>0) &&
              <Row noGutters className="py-2 d-flex justify-content-center">
                <Col xs="8" className="pt-2" >
                    <div>
                      Please provide your state of residence:
                    </div>
                </Col>
                <Col xs="4" className="">
                  <Input type="select" value={this.state.resState} name="resState" id="resState" className="mx-1"
                    style={{ }} onChange={this.handleChange}>
                    <option value=''>{'Select'}</option>
                    {stateSelectOptions}
                    <option disabled>──────────</option>
                    <option value='Non Resident'>Non Resident</option>
                  </Input>
                </Col>
              </Row>
              /*<Row noGutters className="py-2 d-flex justify-content-center d-none d-sm-block">
                <Col xs="8" className="" >
                    <div>
                      Please provide your state of residence:
                    </div>
                </Col>
                <Col xs="4" className="">
                  <Input type="select" value={this.state.resState} name="resState" id="resState" className="mx-1"
                    style={{width:"10em", }} onChange={this.handleChange}>
                    <option value=''>{'Select'}</option>
                    {stateSelectOptions}
                  </Input>
                </Col>
              </Row>*/
              }
              {/*this.props.userLedgerIdDepositFund &&
                <Row noGutters className="py-2">
                  <Col className="pt-0" >
                    <div>{"Thank you for your donation. Good luck!"}</div>
                  </Col>
                </Row>
              */}
            </form>
            {(this.props.league && this.props.league.amountNeedsToDeposit > 0) &&
              <>
              {(!this.state.movedToPaymentScreen &&  this.props.league.use_team_names) &&
              <Row className="justify-content-between mb-2">
                <Col sm="3">
                  <div className="pt-1">Team Name </div>
                </Col>
                <Col className="">
                  <Input ref={(input) => {this.saveModalInput = input;}} style={{borderColor:"rgb(96, 174, 227)"}}
                  className=""
                  type="text"
                  name="entryName"
                  value={this.state.entryName}
                  onChange={this.handleChange}
                  placeholder="e.g. Fairway to Heaven"
                  maxLength="25"/>
                </Col>
              </Row>
              }
              {!this.state.withoutDonation &&
              <EntryFeeDepositFund entryFee={this.props.league.amountNeedsToDeposit}
                leagueId={this.props.activeLeague}
                leagueEventId={this.props.event.eventid}
                charityName={this.props.league.charityName}
                depositFundSuccess={this.depositFundSuccess}
                entryId={this.props.newlyAddedEntryId}
                handlePrePaymentSteps={this.handlePrePaymentSteps}
                toggleMovedToPaymentScreen={this.toggleMovedToPaymentScreen}/>
              }
              {this.state.withoutDonation &&
                <>
                <Row className="mt-4">
                  <Col>
                    <h5>Consider helping a great cause</h5>
                    <p>The good people at <strong>{this.props.league.charityName}</strong> need your help to support the incredible causes they work with. When you donate you truly make a difference.</p>
                  </Col>
                </Row>
                <Row className="mt-0 mb-3 ml-2">
                  <Col className="pl-0">
                    <div style={{fontSize:".80em"}} >
                      {"You are making this donation with the understand that no prize, cash or otherwise, is awarded by Majors Challenge to any participant in this golf pool. Proceeds from this golf pool will be made as a charitable donation to "}<b>{this.props.league.charityName}</b>{" or any such predetermined similar charity at the sole discretion of Majors Challenge, in Majors Challenge's own name. By entering, you consent to share your user profile with the charity organizers and allow them to contact you directly through email or other means. Donations do not increase your chance of winning.Donations are tax deductible to the extent allowable by law."}
                    </div>
                  </Col>
                </Row>
                </>
              }
              </>
            }
          </ModalBody>
          <ModalFooter>
            <div style={{width:'100%'}}>
              {/*
                this.state.error &&
                <Row noGutters className="pb-1">
                  <Col className="" >
                    <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
                  </Col>
                </Row>
              */}
              {(this.props.league && this.props.league.amountNeedsToDeposit > 0) &&
                <>
                {(!this.state.withoutDonation && !this.state.movedToPaymentScreen) &&
                  <>
                <div className="d-flex justify-content-between" style={{width:'100%'}}>
                  <div>&nbsp;{/*<Button outline onClick={this.toggleWithoutDonationModal} disabled={this.props.midsave}>{'Save team without donation'}</Button>*/}</div>
                  <div>
                     <div className="renderComponent" style={{fontSize:'0.8rem'}} onClick={this.toggleWithoutDonation}>Save team without donation</div>
                  </div>
                </div>
                </>
                }
                {this.state.withoutDonation &&
                <div style={{width:'100%'}}>
                  <div className="d-flex justify-content-between" style={{width:'100%'}}>
                    <Button outline onClick={()=>this.props.markEntryWithoutDonation(this.props.newlyAddedEntryId,()=>{this.toggleSaveModal()})}> Continue Without Donation</Button>
                    <Button color="primary" onClick={this.toggleWithoutDonation}> Donate </Button>
                  </div>
                </div>
                }
                </>
              }
              {(this.props.league && !this.props.league.amountNeedsToDeposit > 0) &&
                <div className="d-flex justify-content-between" style={{width:'100%'}}>
                {(this.props.event.perfect_team && !this.props.event.eligible)
                  ?<Button color="warning" onClick={this.props.moveToProfilePage}>Update My Profile</Button>
                  :<Button color="warning" style={{maxWidth:'0px', border:'0px', padding:'0px'}}></Button>
                }
                {moreCupTeamsToGo && !this.state.overrideText
                  ? <Button color="primary" onClick={this.setOverrideText} disabled={this.props.midsave}>Create New Team</Button>
                  : <Button color="primary" onClick={this.handleSaveModalSubmit} disabled={this.props.midsave}>Save Team</Button>
                }
                </div>
              }
            </div>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.editModal} toggle={this.toggleEditModal} onOpened={focusEditModalInput} autoFocus={true} className="pickTeamNameModal">
          <ModalHeader toggle={this.toggleEditModal}>Edit Team Name</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleEditModalSubmit}>
              <input
                ref={(input) => {this.editModalInput = input;}}
                className="pickTeamNameModal_input"
                type="text"
                name="entryName"
                value={this.state.entryName}
                onChange={this.handleChange}
                placeholder="e.g. Fairway to Heaven"
                maxLength="25"
                onFocus={(e) => {
                  const value = e.target.value;
                  e.target.value = '';
                  e.target.value = value;
                }}
              />
              {this.props.cup && this.props.cup.first_event &&
                cupOptInOption}
              {this.props.cup && !this.props.cup.first_event && this.props.cup.valid_names.length > 0 &&
                teamsToGo(this.handleEditModalSubmit)}
              {perfectTeam}
            </form>
          </ModalBody>
          <ModalFooter>
            {/*
              this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
            */}
            <Button color="primary" onClick={this.handleEditModalSubmit} disabled={this.props.midsave}>Save Team Name</Button>
          </ModalFooter>
        </Modal>

      </div>
    )
  }
}

class PickTeamEntries extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teamsDropdown: false,
    }

    this.toggleTeamsDropdown = this.toggleTeamsDropdown.bind(this);
  }

  toggleTeamsDropdown() {
    this.setState({teamsDropdown: !this.state.teamsDropdown});
  }

  componentWillReceiveProps(nextProps) {

  }


  render() {
    if (this.props.entries.filter((entry) => entry.status==='paid').length === 0) {
      return null
    }
    const noEntrySelected = this.props.selectedEntry !== null;
    const selected_id = noEntrySelected ? this.props.selectedEntry.entry_id : null;

    const enumeratedEntries = this.props.entries.filter((entry) => entry.status==='paid').map((entry) => {
      return (
        <Col key={entry.entry_id} xs="12" sm="6" md="4" lg="3">
          <Button outline={selected_id !== entry.entry_id} color="secondary" onClick={() => this.props.chooseEntry(entry)} block className="my-2 d-flex justify-content-between align-items-center">
            <span className="pickTeamEntriesCell_team ml-1 my-2">
              {entry.name}
              {entry.cup && <FontAwesome name="trophy" className="px-2"/> }
            </span>
            {/* <div className="pickTeamEntriesCell_roster">{entry.golfer_names}</div> */}
          </Button>
        </Col>
      )
    })

    let dropdownStyle = {fontSize: "0.9rem"}
    if (!noEntrySelected) {
      dropdownStyle['fontStyle'] = 'italic';
    }

    const newEntryBtnTxt = this.props.isTocFinalEvent ?
                          (this.props.nmbrUserEntriesRemaining === 1 ?
                            "1 Entry Remain" : this.props.nmbrUserEntriesRemaining + " Entries Remain" ): "Enter Another Team";

    const dropdownLabel = noEntrySelected
            ? <span>{this.props.selectedEntry.name} {this.props.selectedEntry.cup && <FontAwesome name="trophy" className="ml-2" />}</span>
            : "Choose Golfers Below"
    const teamsButtonGroup = (
      <ButtonDropdown isOpen={this.state.teamsDropdown} toggle={this.toggleTeamsDropdown} className="my-2" style={{width:'180px'}}>
        <DropdownToggle caret color={noEntrySelected ? "secondary" : "primary"} style={dropdownStyle}>
          {dropdownLabel}
        </DropdownToggle>
        <DropdownMenu>
          {this.props.entries.map((entry) =>
            <DropdownItem key={entry.entry_id} onClick={() => this.props.chooseEntry(entry)}>
            {entry.name}
            {entry.cup && <FontAwesome name="trophy" className="ml-2" /> }
            </DropdownItem>
          )}
          {this.props.noMoreTeam === false &&
            <DropdownItem divider/> &&
            <DropdownItem onClick={() => noEntrySelected && this.props.newEntry()}>{newEntryBtnTxt}</DropdownItem>
          }
        </DropdownMenu>
      </ButtonDropdown>
    )



    return (
      <div className="pickTeamEntries px-3">
        <Row className="d-none d-md-flex">
          {enumeratedEntries}
          {this.props.noMoreTeam === false &&
            <Col xs="12" sm="6" md="4" lg="3">
              <Button outline={noEntrySelected} color="primary" onClick={() => noEntrySelected && this.props.newEntry()} block className="my-2 mx-lg-2">
                <div className="pickTeamEntriesCell_team text-center my-2 font-italic">
                  {noEntrySelected ? newEntryBtnTxt : "Choose Golfers Below"}
                </div>
              </Button>
            </Col>
          }
        </Row>
        <Row className="d-md-none">
        <Col>
          {teamsButtonGroup}
        </Col>
        </Row>
      </div>
    )
  }
}

const getMatchingGolferOrNull = (golferid, golfers) => {
  if (!golferid) {
    return null;
  }
  for (let p of golfers) {
    if (p.golferid === golferid) {
      return p;
    }
  }
  return null;
}


const PickGroup = (props) => {
  const teamGroup = props.team[props.group];
  const golfers = props.golfers[props.group];

  const chosenTeamMembers = teamGroup.map((teamGolferId, index) => {

    const isAlternateSlot = index === (teamGroup.length - 1);
    const golfer = getMatchingGolferOrNull(teamGolferId, golfers);
    const unselectGolfer = () => props.unselectGolfer(props.group,teamGolferId);
    const showGolferDetails = () => props.showGolferDetails(golfer.golferid);

    return (
      <TeamSlot
        key={index}
        golfer={golfer}
        teamGolferId={teamGolferId}
        unselectGolfer={unselectGolfer}
        isAlternateSlot={isAlternateSlot}
        showGolferDetails={showGolferDetails}
        editMode={props.editMode}
      />
    );
  });

  const availableGolfers = golfers.map((golfer) => {

    const isChosen = teamGroup.includes(golfer.golferid);
    const isTeamFull = !teamGroup.includes(null);
    const selectGolfer = () => props.selectGolfer(props.group, golfer.golferid);
    const unselectGolfer = () => props.unselectGolfer(props.group, golfer.golferid);
    const showGolferDetails = () => props.showGolferDetails(golfer.golferid);

    return (
      <AvailableGolfer
        key={golfer.golferid}
        {...golfer}
        selectGolfer={selectGolfer}
        unselectGolfer={unselectGolfer}
        isChosen={isChosen}
        isTeamFull={isTeamFull}
        showGolferDetails={showGolferDetails}
        editMode={props.editMode}
      />
    );
  });

  const spacerStyle = {height: "64px", marginBottom: "0.5rem"};
  let spacers = null;
  if (props.group === "groupD") {
    spacers = (
      <div>
        <div style={spacerStyle}/>
        <div style={spacerStyle}/>
      </div>
    )
  } else if (props.group !== "groupC") {
    spacers = <div style={spacerStyle}/>
  }

  if (props.event && props.event.tour === 'liv'){
    spacers = <div style={spacerStyle}/>
  }

  return (
    <div className="px-1 px-md-2 pickTeamPickGroup">
      <h6 className="text-center">{props.label}</h6>
      {chosenTeamMembers}
      {spacers}
      {availableGolfers}
    </div>

  )
}

const TeamSlot = (props) => {
  if (props.golfer) {
    return <TeamSlotWithGolfer {...props}/>
  } else {
    return <TeamSlotEmpty {...props} />
  }
}

const TeamSlotEmpty = (props) => {
  let classes = props.isAlternateSlot ? "teamSlotEmpty teamSlotAlternate" : "teamSlotEmpty";
  if (props.teamGolferId && [-1,-2,-3,-4].indexOf(props.teamGolferId) > -1){
    classes = classes + ' d-none';
  }
  return (
    <div className={classes}>
      <Row>
        <Col xs="3">
          <img src="https://media.majorschallenge.com/headshots/headshot.png" alt="choose a golfer" className="teamSlotGolfer_img"/>
        </Col>
        <Col xs="9">
          <div className="teamSlotEmpty_text">
            {
              props.isAlternateSlot ? "Choose Alternate" : "Choose Starter"
            }
          </div>
        </Col>
      </Row>
    </div>
  )
}

const TeamSlotWithGolfer = (props) => {
  const classes = props.isAlternateSlot ? "teamSlotGolfer teamSlotAlternate" : "teamSlotGolfer";
  const nameClass = props.editMode ? "teamSlotGolfer_name teamSlotGolfer_name__short" : "teamSlotGolfer_name";

  return (
    <div className={classes}>
      <div className="d-flex justify-content-start">
        <div className="d-block">
          <img src={props.golfer.image} alt={props.golfer.golfer} className="teamSlotGolfer_img" onError={(e) => e.target.src = "https://media.majorschallenge.com/headshots/headshot.png"}/>
        </div>
        <div className="pl-1">
          <h6 className={nameClass} alt={props.golfer.golfer} onClick={props.showGolferDetails}>
            {props.isAlternateSlot && <Badge className="mr-1">ALT</Badge>}
            {props.golfer.golfer}
          </h6>
          <div className="teamSlotGolfer_meta d-flex">T10s: {props.golfer.t10s}
            <span>Cuts {props.golfer.cutsmade}</span>
            {((props.golfer.status && props.golfer.status.toUpperCase() === 'WD') || (props.golfer.injury && props.golfer.injury.isWithdrawn === 1)) &&
              <div><Badge className="mx-2 text-white bg-danger" style={{marginTop:'1px'}} color="denger">OUT</Badge></div>
            }
            {((props.golfer.injury && props.golfer.injury.isWithdrawn === 0) && (!props.golfer.status || (props.golfer.status && props.golfer.status.toUpperCase() !== 'WD'))) &&
              <img src={Largewarning} alt="alt" style={{paddingLeft: "10px", height:'17px', marginBottom:'3px'}}/>
            }
            {(!props.golfer.injury) &&
              <span></span>
            }
          </div>
        </div>
        {
          props.editMode &&
          <div style={{width: "100%"}} className="d-flex justify-content-end align-items-center pl-1 pr-2">
            <span className="teamSlotGolfer_minus" onClick={(e) => props.unselectGolfer()}>-</span>
          </div>
        }
      </div>
    </div>
  )
}

const AvailableGolfer = (props) => {
  if (props.editMode === false) {
    return null;
  }

  const chosenStyle = props.isChosen || props.isTeamFull ? {opacity: "0.6"} : {};

  let icon = null;
  if (props.isChosen) {
    icon = <span className="availableGolfer_minus" onClick={(e) => props.unselectGolfer()}>-</span>
  } else if (!props.isTeamFull) {
    icon = <span className="availableGolfer_plus" onClick={(e) => props.selectGolfer()}>+</span>
  }

  return (
    <div className="availableGolfer" style={chosenStyle}>
      <Row>
        <Col xs="9" className="text-left">
          <h6 className="availableGolfer_name d-flex" onClick={props.showGolferDetails}>{props.golfer}
          {((props.status && props.status.toUpperCase() === 'WD') || (props.injury && props.injury.isWithdrawn === 1)) &&
            <div><Badge className="mx-2 text-white bg-danger" style={{marginTop:'2px', fontSize:"0.6rem", paddingTop:'3px',verticalAlign:'super'}} color="denger">OUT</Badge></div>
          }

          {((props.injury && props.injury.isWithdrawn === 0) && (!props.status || (props.status && props.status.toUpperCase() !== 'WD'))) &&
            <img src={Largewarning} alt="Warning" style={{paddingLeft: "10px", height:'17px', marginBottom:'3px'}}/>
          }
          </h6>

          <div className="availableGolfer_meta">T10s: {props.t10s}
            <span>Cuts {props.cutsmade}</span>
          </div>
        </Col>
        <Col xs="3" className="d-flex justify-content-end align-items-center">
          {icon}
        </Col>
      </Row>
    </div>
  )
}

const PromoAnnouncement = ({announcement}) => {
  if (!announcement) {
    return null;
  }
  return (
      <Row noGutters className="d-flex justify-content-center">
        <Col lg="7">
          <Alert color="success" className="my-4">
            {announcement.title && <h6>{announcement.title}</h6>}
            <Linkify properties={{target: '_blank'}}>
              <span style={{whiteSpace: "pre-line", fontSize: "14px"}}>{announcement.message}</span>
            </Linkify>
          </Alert>
        </Col>
      </Row>
    )
}
