import React from 'react';

import {
  Col,
  Container,
  Row,
} from 'reactstrap';

import {
  withRouter
} from 'react-router-dom';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import { check_league_club,} from 'utils/api';


class UnauthedJoinLeagueClub extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };


  }

  componentDidMount() {
    if (this.props.match.params.slug){
      this.checkLeagueClub(this.props.match.params.slug);
    }
  }



  checkLeagueClub(slug) {

    check_league_club(
      slug,
    ({success, club, league}) => {
      if (!success){
        this.props.history.push("/");
        return;
      }
      if (club){
        this.props.history.push("/club/"+slug);
      }else{
        this.props.history.push("/ileague/"+slug);
      }
    }, (error) => {
      console.log(error);
      this.props.history.push("/");
    });
  }



  render() {


    return (
      <OnboardingWrapper {...this.props}>
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col xs="12" sm="10" md="9" lg="8" xl="6" className="joinContent mx-auto">
              checking ...
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    );
  }
}

export default withRouter(UnauthedJoinLeagueClub);
