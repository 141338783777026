import React from 'react';

import {
  Card,
  Button,
} from 'reactstrap';


import {
  MobileHeader,
  ContentWrapper
} from 'common/AdminComponents.js';

import {

} from 'utils/api.js';

import Reports from 'common/Reports.js';



export default class CommissionerReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

  }

  componentDidMount() {
    //this.getReportResult(this.props.activeLeague);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {


  }

  weeklyReportCols = [
    {
      Header: "Season",
      accessor: "season",
    },
    {
      Header: "Tournament",
      accessor: "tournament",
    },
    {
      Header: "Pool Type",
      accessor: "pool_type",
    },
    {
      Header: "Entry Amount",
      accessor: "entry_amount",
    },
    {
      Header: "Entries",
      accessor: "entries",
    },
    {
      Header: "Purse",
      accessor: "purse",
    },
    {
      Header: "Payout",
      accessor: "payout",
    },
    {
      Header: "Rake",
      accessor: "rake",
    },
  ];





  render() {

    return (
      <div>
        <MobileHeader to="Reports" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
            {'Reports'}
          </div>
          <Reports activeLeague={this.props.activeLeague}
          reportCols={this.weeklyReportCols}
          reportCd="WEEKLY_REPORT"
          reportName="Weekly Report"
          enableDownloadCSV={true}
          enableSort={true}/>
        </ContentWrapper>
      </div>
    );
  }


}
