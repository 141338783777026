import React from 'react';
import {
  Link,
} from 'react-router-dom';
import numeral from 'numeral';
import EventRulesModal from 'pages/user/EventRulesModal';


import './EventTitle.css';

const EventTitle = ({activeLeague, event, prizePayout, className, perfectteam, suppressEventRule, eventData, toggleFundDonate, showProps}) => {
  let showEventRule = true;
  if (suppressEventRule){
    showEventRule = false;
  }
  const eventStatus = {
      "round1": event.roundclosed ? "Round 1 is complete" : "Round 1 in progress",
      "round2": event.roundclosed ? "Round 2 is complete" : "Round 2 in progress",
      "round3": event.roundclosed ? "Round 3 is complete" : "Round 3 in progress",
      "round4": event.roundclosed ? "Round 4 is complete" : "Round 4 in progress",
      "closed": "Event has concluded",
  }[event.status] || "";

  let eventRules = <span>Event Rules</span>
  let prize = <span>Prizes</span>
  let eventRulesPrize = <span>Rules & Prizes</span>
  return (
    <div className={className}>
      <div className="eventTitle">
        {event.name}
      </div>
      <div className="eventTitle_sub">
        {event.course} &bull; {event.dates}
      </div>
      {(showProps && showProps.purse && event.purse) &&
        <div className="eventTitle_sub">
          Purse: ${numeral(event.purse).format('0,0')}
        </div>
      }
      {(showProps && showProps.defending_champ && event.defending_champ) &&
        <div className="eventTitle_sub">
            Defending Champion: {event.defending_champ}
        </div>
      }

      {eventStatus !== "" &&
        <div className="eventTitle_sub_sub">
          {eventStatus}
          {(showEventRule && prizePayout) &&
            <>
            <span className="mx-2">&bull;</span>
            <EventRulesModal activeLeague={activeLeague} event={event} component={eventRulesPrize} />
            </>
          }
          {(showEventRule && !prizePayout) &&
            <>
            <span className="mx-2">&bull;</span>
            <EventRulesModal activeLeague={activeLeague} event={event} component={eventRules} />
            </>
          }
          {(!showEventRule && prizePayout) &&
            <>
            <span className="mx-2">&bull;</span>
            <EventRulesModal activeLeague={activeLeague} event={event} component={prize} />
            </>
          }
          {(eventData && eventData.nationalLeageGrpId) &&
            <>
            <span className="mx-2">&bull;</span>
              <Link to={{
                  pathname: "/agaevents/"+ eventData.nationalLeageGrpId+'/'+eventData.eventid,
                  state: { leagueName: eventData.leagueName, legueEventid: eventData.leagueEventId}
              }}>National Leaderboard</Link>
            </>
          }
          {(parseInt(perfectteam) < 1) &&
            <span>
              <span className="mx-2">&bull;</span>
              <a target="_blank" href="/perfectteam">Perfect Team</a>
            </span>
          }
          {(toggleFundDonate && eventData.leagueType === 'CHARITY') &&
            <span>
              <span className="mx-2">&bull;</span>
              <a  href="#" onClick={() => {toggleFundDonate(); return false;}}>Donate</a>
            </span>
          }
        </div>
      }
    </div>
  )
}


export {EventTitle};
