
import axios from 'axios';

let HOSTNAME = window.location.hostname;
let HOSTNAME_IS_LOCAL = HOSTNAME.includes('127.0.0.1') || HOSTNAME.includes('localhost');
let HOSTNAME_IS_STAGING = HOSTNAME === 'dev.majorschallenge.com';
let HOSTNAME_IS_CASH = HOSTNAME === 'cash.majorschallenge.com';
let BASE_URL = HOSTNAME_IS_LOCAL ?
                'http://localhost:8000' :
                window.location.protocol + '//' + (HOSTNAME_IS_STAGING ? 'devapi' : (HOSTNAME_IS_CASH ? 'cashapi': 'api')) + '.majorschallenge.com';
BASE_URL += '/api';

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export function submit_club_intake(name, email, phone, golfcourse, success, error) {
  var fd = new FormData();
  fd.set('name', name);
  fd.set('email', email);
  fd.set('phone', phone);
  fd.set('golfcourse', golfcourse);
  fd.set('_subject', `MC Club Inquiry: ${name}`);
  fd.set('_replyto', email);

  const pEmail = axios({
    method: 'post',
    url: 'https://formsapi.jabwn.com/key/0NFWs7ATBAyfdJ6A8XnW',
    data: fd,
    config: {  headers: {'Content-Type': 'application/x-www-form-urlencoded' }},
    withCredentials: false,
  });

  const text = `Name: ${name}\n`
  + `Email: ${email}\n`
  + `Phone: ${phone}\n`
  + `Golf Course: ${golfcourse}\n`;

  const pSlack = axios({
    method: 'post',
    url: 'https://hooks.slack.com/services/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',
    data: JSON.stringify({text}),
    config: {  headers: {'Content-Type': 'application/json' }},
    withCredentials: false,
  });

  Promise.all([pEmail, pSlack]).then(success).catch(error);
}

export function get_promo() {
  let tm = new Date().getTime();
  const url = HOSTNAME_IS_LOCAL || HOSTNAME_IS_STAGING || HOSTNAME_IS_CASH ?
  'https://s3.amazonaws.com/media.majorschallenge.com/promoTest.json?timestamp='+tm :
  'https://s3.amazonaws.com/media.majorschallenge.com/promo.json?timestamp='+tm ;

  return axios({
    method: 'get',
    url,
    withCredentials: false,
  })
}



export async function get_curr_season() {
  try {
    const response = await axios.post('/get_curr_season');

    console.log('get_curr_season:', response.data.season);
    return response.data.season;
  } catch (error) {
    console.error('get_curr_season:', error);
  }
}

export function get_season(success, error) {
  axios.get('/get_curr_season').then(success).catch(error);
}

export function is_authed(success, error) {
  axios.get('/is_authed').then(success).catch(error);
}

export function leagueinfoWL(league, success, error) {
  axios.post('/leagueinfoWL', {
    league
  }).then((response) => success(response.data)).catch(error);

}

export async function clubinfoWL(clubname, success, error) {
  axios.post('/clubinfoWL', {
    clubname
  }).then((response) => success(response.data)).catch(error);
}

export function login(email, password, success, error) {
  axios.post('/login', {
    username: email,
    password,
  }).then(success).catch(error);
}

export function login_facebook(fb_user_id, access_token, success, error) {
  axios.post('/login_facebook', {
    uid: fb_user_id,
    temptoken: access_token,
  }).then(success).catch(error);
}

export function login_google(id_token, success, error) {
  axios.post('/login_google', {
    id_token,
  }).then(success).catch(error);
}

export function logout(success, error) {
  axios.post('/logout').then(success).catch(error);
}

export function signup(email, firstname, lastname, password, success, error) {
  axios.post('/signup', {
    email,
    firstname,
    lastname,
    password,
  }).then(success).catch(error);
}

export function start_password_reset(email, success, error) {
  axios.post('/start_password_reset', {
    email
  }).then((response) => success(response.data)).catch(error);
}

export function check_password_reset(code, success, error) {
  axios.post('/check_password_reset', {
    code
  }).then(response => success(response.data)).catch(error);
}

export function submit_password_reset(code, newPass, confirmNewPass, success, error) {
  axios.post('/submit_password_reset', {
    code,
    newPass,
    confirmNewPass
  }).then(response => success(response.data)).catch(error);
}

export function activation(activation_code, success, error) {
  axios.post('/activation', {
    activation_code: activation_code
  }).then((response) => success(response.data)).catch(error);

}

export function next_events(success, error) {
  axios.post('/next_events').then(
    (response)=>{ success(response.data) }
  ).catch(error);
}

export function create_league(name, tournaments_type, cup, variable_pool, multiplier, fee, pool_type, season_long_fee, isOneanddone, isSeasondraft, success, error) {
  var factor = variable_pool ? multiplier : fee;

  axios.post('/create_league', {
    league_name: name,
    tournaments_type,
    cup,
    variable_pool,
    pool_factor: factor,
    pool_type,
    season_long_fee,
    isOneanddone,
    isSeasondraft
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function create_charity_league(authed, foundation_name, foundation_url, foundation_slug, rep_name, rep_email, rep_ph_no, address_lines, city, state, postal, tax_id, determinationLetterData, determinationLetterFileExtn, success, error) {
  if (authed){
    axios.post('/create_charity_league', {
      foundation_name, foundation_url, foundation_slug,
      rep_name, rep_email, rep_ph_no, address_lines, city, state, postal, tax_id, determinationLetterData, determinationLetterFileExtn,
    }).then(
      (response)=>{
        success(response.data);
      }
    ).catch(error);
}else{
    axios.post('/unauth_create_charity_league', {
      foundation_name, foundation_url, foundation_slug,
      rep_name, rep_email, rep_ph_no, address_lines, city, state, postal, tax_id, determinationLetterData, determinationLetterFileExtn,
    }).then(
      (response)=>{
        success(response.data);
      }
    ).catch(error);
  }
}

export function get_charity_leagues(status, success, error) {
  axios.post('/get_charity_leagues', {
    status
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_charity_league(league, success, error) {
  axios.post('/get_charity_league', {
    league
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function update_charity_league(charityid, fld, fldVal, success, error) {
  axios.post('/update_charity_league', {
    charityid,
    fld,
    fldVal
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function check_invitation(league, code, success, error) {
  axios.post('/check_invitation', {
    league,
    code,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function join_league(league, code, success, error) {
  axios.post('/join_league_authed', {
    league,
    code,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function join_league_and_activate(league, code, firstname, lastname, password, companyName, success, error) {
  axios.post('/join_league_and_activate', {
    league,
    code,
    firstname,
    lastname,
    password,
    companyName,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_user_members(league, pageSize, success, error) {
  axios.post('/get_members', {
    league,
    pageSize,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function add_member(leagueid, username, email, firstname, lastname, password, altCommis, success, error) {
  axios.post('/add_member', {
    leagueid,
    username,
    email,
    firstname,
    lastname,
    password,
    altCommis,
    }).then(success).catch(error);
}

export function set_friendship(league, member, isFriend, success, error) {
  axios.post('/set_friendship', {
    league,
    member,
    isFriend,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function quit_league(league, success, error) {
  axios.post('/quit_league', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function check_club(clubname, success, error) {
  axios.post('/check_club', {
    clubname
  }).then((response) => {success(response.data)}).catch(error);
}

export function check_league_club(slug, success, error) {
  axios.post('/check_league_club', {
    slug
  }).then((response) => {success(response.data)}).catch(error);
}



export function invite_self_to_club(clubname, email, success, error) {
  axios.post('/invite_self_to_club', {
    clubname,
    email
  }).then((response) => {success(response.data)}).catch(error);
}

export function check_league(leagueSlug, leaguePwd, success, error) {
  axios.post('/check_league', {
    leagueSlug,
    leaguePwd,
  }).then((response) => {success(response.data)}).catch(error);
}

export function add_self_member(leagueid, commissionerId, firstname, lastname, email, password, success, error) {
  axios.post('/add_self_member', {
    leagueid,
    commissionerId,
    firstname,
    lastname,
    email,
    password
  }).then((response) => {success(response.data)}).catch(error);
}

export function get_payout_events(league, success, error) {
  axios.post('/get_payout_events', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_payouts(eventid, success, error) {
  axios.post('/get_payouts', {
    eventid,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function lock_point_pool(eventid, success, error) {
  axios.post('/lock_point_pool', {
    eventid,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function mark_paid(entry, success, error) {
  axios.post('/mark_entry_paid', {
    entry,
    paid: true,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function mark_unpaid(entry, success, error) {
  axios.post('/mark_entry_paid', {
    entry,
    paid: false,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function mark_all_paid(entries, success, error) {
  axios.post('/mark_entries_paid', {
    entries,
    paid: true,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function mark_all_unpaid(entries, success, error) {
  axios.post('/mark_entries_paid', {
    entries,
    paid: false,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function include_in_pool(entry, success, error) {
  axios.post('/include_entry_in_pool', {
    entry,
    include: true,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function exclude_from_pool(entry, success, error) {
  axios.post('/include_entry_in_pool', {
    entry,
    include: false,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function remove_entry(entry, success, error) {
  axios.post('/remove_entry', {
    entry,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_teams_details_entry(entryId, success, error) {
  axios.post('/get_teams_details_entry', {
    entryId,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_teams_field(eventid, success, error) {
  axios.post('/get_teams_field', {
    eventid,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function invite_to_league(league, emails, note, success, error) {
  axios.post('/invite_to_league', {
    league,
    emails,
    note,
  }).then(success).catch(error);
}

export function get_homedata(success, error) {
  axios.post('/dashboard').then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function join_league_dashboard(league, success, error) {
  axios.post('/join_league_dashboard', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function reject_league_dashboard(league, success, error) {
  axios.post('/reject_league_dashboard', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function join_promo(league, success, error) {
  axios.post('/join_promo', {
    league,
  }).then((response) => {
    success(response.data);
  }).catch(error);
}

export function get_leagueevents(eventid, success, error) {
  axios.post('/get_leagueevents', {
    eventid,
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function get_leaderboards(eventid, success, error) {
  axios.post('/get_leaderboards', {
    eventid,
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function get_leaderboards_pager(eventid, filter, pageNo, recPerPage, round, success, error) {
  axios.post('/get_leaderboards', {
    eventid,
    filter,
    pageNo,
    recPerPage,
    round,
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function get_aga_league_events(leaguegrpid, success, error) {
  axios.post('/get_aga_league_events', {
    leaguegrpid,
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function get_aga_leaderboards(leaguegrpid, eventid, filter, pageNo, recPerPage, round, success, error) {
  axios.post('/get_aga_leaderboards', {
    leaguegrpid,
    eventid,
    filter,
    pageNo,
    recPerPage,
    round,
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function get_LCleaderboards(leagueid, eventid, leaguecontestid, success, error) {
  axios.post('/get_LCleaderboards', {
    leagueid,
    eventid,
    leaguecontestid
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function get_lc_oad_entries(leagueid, eventid, leaguecontestid, success, error) {
  axios.post('/get_lc_oad_entries', {
    leagueid,
    eventid,
    leaguecontestid
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function lc_oad_entry_action(leagueid, eventid, leaguecontestid, action, oad_entry_id, oad_entry_detail_id, user_id, pick_golfer_id,pick_alt_golfer_id, success, error) {
  axios.post('/lc_oad_entry_action', {
    leagueid,
    leaguecontestid,
    eventid,
    action,
    oad_entry_id,
    oad_entry_detail_id,
    user_id,
    pick_golfer_id,
    pick_alt_golfer_id,
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function get_cupstandings(cup, success, error) {
  axios.post('/cupstandings', {
    cupid: cup,
  }).then(
    (response)=>{
      success(response.data.standings,response.data.numWinners,response.data.cupScoring2ndPhase,response.data.scoringMethod,response.data.cupScorePoints);
    }
  ).catch(error);
}

export function get_cupevents(cup, success, error) {
  axios.post('/cupevents', {
    cupid: cup,
  }).then(
    (response)=>{
      success(response.data.events, response.data.league, response.data.cupMsg);
    }
  ).catch(error);
}

export function get_cupseasonids(cup, success, error) {
  axios.post('/cupseasonids', {
    cupid: cup,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_clubdata(clubname, success, error) {
  axios.post('/clubhome', {
    clubname,
  }).then(
    ({data}) => {
      success(data);
    }
  ).catch(error);
}

export function join_club(clubname, success, error) {
  axios.post('/joinclub', {
    clubname,
  }).then(
    ({data}) => {
      success(data);
    }
  ).catch(error);
}

export function get_next_league_event(league, success, error) {
  axios.post('/get_next_league_event', {
    league,
  }).then(
    ({data}) => {
      success(data);
    }
  ).catch(error);
}

export function get_leaguedata(league, success, error) {
  axios.post('/leaguehome', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_moneyleaders(league, season, success, error) {
  axios.post('/moneyleaders', {
    league,
    season,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_leaguecontest(league, season, contesttype, success, error) {
  axios.post('/leagueContest', {
    league,
    season,
    contesttype,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_leagueContestOAD(league, season, success, error) {
  axios.post('/leagueContestOAD', {
    league,
    season,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_leagueContestOADBreakdown(league, leaguecontestId, success, error) {
  axios.post('/leagueContestOADBreakdown', {
    league,
    leaguecontestId,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_leagueContestOADField(league, leaguecontestId, contesteventId, success, error) {
  axios.post('/leagueContestOADField', {
    league,
    leaguecontestId,
    contesteventId,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_golfer(playerid, success, error) {
  axios.post('/get_golfer', {
    golferid: playerid,
  }).then(
    (response)=>{
      success(response.data.bio, response.data.tournaments, response.data.majors, response.data.live);
    }
  ).catch(error);
}

export function get_golfer_scorecard(golferid, eventid, success, error) {
  axios.post('/get_golfer_scorecard', {
    golferid,
    eventid,
  }).then(
    (response)=>{
      success(response.data.scorecards);
    }
  ).catch(error);
}

export function get_commissionerdata(league, success, error) {
  axios.post('/commissioner', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_members(league, success, error) {
  axios.post('/members', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function search_members(league, name, pageSize, success, error) {
  axios.post('/search_members', {
    league,
    name,
    pageSize,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_member_status(league, member, status, refresh_members, success, error) {
  axios.post('/set_member_status', {
    league,
    member,
    status,
    refresh_members,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_member_profile_fld_value(league, member, fld, fldVal, success, error) {
  axios.post('/set_member_profile_fld_value', {
    league,
    member,
    fld,
    fldVal,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_leaguesettings(league, success, error) {
  axios.post('/get_leaguesettings', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_leaguesettings(league, leagueName, openMembership, allowInvites, maxEntries, maxUserEntries, maxLoss, variablePool, poolFactor, winningPercentages, winningFlat, useFlatWinnings, tieBreakRule, allowWebCommunication, allowGenCommunication, addlContstRuleTxt, noPayoutPrizes, displayMoneyLeaders, allowPublicAccess, leagueSlugPwd, withdrawAltRule, noBestGolfersScore, poolType, seasonLongFee, motd, dailyLeaderboard, isPrivate, success, error) {
  axios.post('/set_leaguesettings', {
    league,
    leagueName,
    openMembership,
    allowInvites,
    maxEntries,
    maxUserEntries,
    maxLoss,
    variablePool,
    poolFactor,
    winningPercentages,
    winningFlat,
    useFlatWinnings,
    tieBreakRule,
    allowWebCommunication,
    allowGenCommunication,
    addlContstRuleTxt,
    noPayoutPrizes,
    displayMoneyLeaders,
    allowPublicAccess,
    leagueSlugPwd,
    withdrawAltRule,
    noBestGolfersScore,
    poolType,
    seasonLongFee,
    motd,
    dailyLeaderboard,
    isPrivate,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_eventsettings(league, event, success, error) {
  axios.post('/get_eventsettings', {
    league,
    event,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_leagueeventsettings(league, event, success, error) {
  axios.post('/get_leagueeventsettings', {
    league,
    event,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_eventsettings(league, event, cup, noMoreEntries, maxEntries, maxUserEntries, maxLoss, variablePool, poolFactor, winningPercentages, winningFlat, useFlatWinnings, tieBreakRule, addlContstRuleTxt, noPayoutPrizes, noBestGolfersScore, dailyLeaderboard, eventHost, success, error) {
  axios.post('/set_eventsettings', {
    league,
    event,
    cup,
    noMoreEntries,
    maxEntries,
    maxUserEntries,
    maxLoss,
    variablePool,
    poolFactor,
    winningPercentages,
    winningFlat,
    useFlatWinnings,
    tieBreakRule,
    addlContstRuleTxt,
    noPayoutPrizes,
    noBestGolfersScore,
    dailyLeaderboard,
    eventHost,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_leaguestatus(league, status, success, error) {
  axios.post('/set_league_status', {
    league,
    status,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_weeklyLeagueStatus(league, status, success, error) {
  axios.post('/set_weekly_league_status', {
    league,
    status,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_myprofile(success, error) {
  axios.post('/get_my_profile', {
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_commissleagues(success, error) {
  axios.post('/get_commiss_leagues', {
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_events(league, success, error) {
  axios.post('/events', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function enter_event(league, event, success, error) {
  axios.post('/enter_event', {
    league,
    event,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function leave_event(league, event, success, error) {
  axios.post('/leave_event', {
    league,
    event,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function hide_unhide_event(league, event, success, error) {
  axios.post('/hide_unhide_event', {
    league,
    event,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_event_field(eventid, success, error) {
  axios.post('/get_event_field', {
    eventid
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function save_lineup(eventid, team, selectedEntry, entryName, cupOptIn, resState, userLedgerIdDepositFund, memberid, success, error) {
  axios.post('/save_lineup', {
    eventid,
    team,
    selectedEntry,
    entryName,
    cupOptIn,
    resState,
    userLedgerIdDepositFund,
    memberid,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function delete_lineup(eventid, entry, success, error) {
  axios.post('/delete_lineup', {
    eventid,
    entry,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function edit_team_name(eventid, entryid, entryName, cupOptIn, success, error) {
  axios.post('/edit_team_name', {
    eventid,
    entryid,
    entryName,
    cupOptIn,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function mark_entry_without_donation(eventid, entryid, success, error) {
  axios.post('/mark_entry_without_donation', {
    eventid,
    entryid,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_event_field_team(eventid, success, error) {
  axios.post('/get_event_field_team', {
    eventid
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_event_field_team_members(leagueid, success, error) {
  axios.post('/get_event_field_team_members', {
    leagueid
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_motd(league, success, error) {
  axios.post('/get_motd', {
    league,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function set_motd(league, motd, success, error) {
  axios.post('/set_motd', {
    league,
    motd,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function set_cup_fld_value(league, fld, fldVal, success, error) {
  axios.post('/set_cup_fld_value', {
    league,
    fld,
    fldVal
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function share_feedback(type, details, success, error) {
  axios.post('/share_feedback', {
    type,
    details,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_member_profile(league, member, success, error) {
  axios.post('/get_member_profile', {
    league,
    member,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function resend_invitation_emails(league, member, success, error) {
  axios.post('/resend_invitation_emails', {
    league,
    member,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function add_member_note(league, member, message, success, error) {
  axios.post('/add_member_note', {
    league,
    member,
    message,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_alt_commissioner(league, member, commissionerind, success, error) {
  axios.post('/set_alt_commissioner', {
    league,
    member,
    commissionerind,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_account_info(success, error) {
  axios.get('/get_account_info').then((response)=>{
    success(response.data.success, response.data.user);
  }).catch(error);
}

export function set_account_info(info, success, error) {
  axios.post('/set_account_info', {...info}).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function email_change(new_email, profile_pwd, success, error) {
  axios.post('/email_change', {new_email, profile_pwd}).then((response)=>{
    success(response.data);
  }).catch(error);
}


export function get_past_entries(season, success, error) {
  axios.post('/get_past_entries', {
    season
  }).then(
    (response) => {
      success(response.data);
    }
  ).catch(error);
}

export function change_password(oldPass, newPass, confirmNewPass, success, error) {
  axios.post('/change_password', {oldPass, newPass, confirmNewPass}).then((response) => {
    success(response.data);
  }).catch(error);
}

export function get_stripe_key(success, error) {
  axios.get('/get_stripe_key').then((response) => {
    success(response.data.key);
  }).catch(error);
}

export function get_league_balance(league, success, error) {
  axios.post('/get_league_balance', {league}).then((response) => {
    success(response.data);
  }).catch(error);
}

export function submit_payment(info, success, error) {
  axios.post('/submit_payment', {...info}).then((response) => {
    success(response.data);
  }).catch(error);
}

export function get_all_events(season, tour, success, error) {
  axios.post('/get_all_events',{season, tour}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}


export function get_sr_tournaments(season, tour, success, error) {
  axios.post('/get_sr_tournaments',
    {season, tour}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function add_sr_tournaments(tour, srseason, mcseason, srIds, major, success, error) {
  axios.post('/add_sr_tournaments',
    {tour, srseason, mcseason, srIds, major}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function schedule_event(eventid, success, error) {
  axios.post('/schedule_event',
    {eventid}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function unschedule_event(eventid, success, error) {
  axios.post('/unschedule_event',
    {eventid}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function import_event_field(eventid, success, error) {
  axios.post('/import_event_field',
    {eventid}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function compare_event_field(eventid, success, error) {
  axios.post('/compare_event_field',
    {eventid}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function fix_missing_field(eventid, success, error) {
  axios.post('/fix_missing_field',
    {eventid}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function reset_field_tiers(eventid, success, error) {
  axios.post('/reset_field_tiers',
    {eventid}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function set_golfer_tier(eventfieldid, tier, success, error) {
  axios.post('/set_golfer_tier', {
      eventfieldid,
      tier,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function fetch_field(eventid, success, error) {
  axios.post('/fetch_field',
    {eventid}).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function open_event(eventid, success, error) {
  axios.post('/open_event', {
      eventid,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function close_event(eventid, success, error) {
  axios.post('/close_event', {
      eventid,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function fetch_alternate_field(eventid, success, error) {
  axios.post('/fetch_alternate_field', {
      eventid,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function mark_alternate(eventfieldid, alternate, secAlternate, success, error) {
  axios.post('/mark_alternate', {
      eventfieldid,
      alternate,
      secAlternate,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function get_cup_data(league, success, error) {
  axios.post('/get_cup_data', {
      league,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function get_cup_manage_field(league,leagueevent, success, error) {
  axios.post('/get_cup_manage_field', {
      league,leagueevent,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function league_in_cup(league, cup, success, error) {
  axios.post('/league_in_cup', {
      league,
      cup,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function in_out_team_in_cup(league, entry, cup, onoff, success, error) {
  axios.post('/in_out_team_in_cup', {
      league,
      entry,
      cup,
      onoff,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function event_in_cup(league, event, cup, success, error) {
  axios.post('/event_in_cup', {
      league,
      event,
      cup,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}
export function create_club(clubName, slug, commissioner, allowInvites, entryFee,commissFirstNm,commissLastNm, addressLine, city, state, zip, maxEntries, payoutStructure, referraluser, success, error) {
  axios.post('/create_club', {
    club_name: clubName,
    slug,
    commissioner,
    allow_invites: allowInvites,
    entry_fee: entryFee,
    first_name:commissFirstNm,
    last_name:commissLastNm,
    address_line: addressLine,
    city,
    state,
    zip,
    maxEntries,
    payoutStructure,
    referraluser,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function create_piggy_club(league, success, error) {
  axios.post('/create_piggy_club', {
    league,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_report_list(type, success, error) {
  axios.post('/get_report_list', {
    type
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_report_result(reportid, success, error) {
  axios.post('/get_report_result', {
    reportid:reportid
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_mc_user_list(email, fName, lName, leagueName, supportInd, success, error) {
  axios.post('/get_mc_user_list', {
    email,
    fName,
    lName,
    leagueName,
    supportInd,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function add_remove_support_member(league_id, user_id, action, success, error) {
  axios.post('/add_remove_support_member', {
    league_id,
    user_id,
    action,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_league_for_manage(league_id, success, error) {
  axios.post('/get_league_for_manage', {
    league_id,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function update_league_for_manage(league_id, fld, fldVal, details, success, error) {
  axios.post('/update_league_for_manage', {
    league_id,
    fld,
    fldVal,
    details,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_app_page_configs(config_grp, league_id, success, error) {
  axios.post('/get_app_page_configs', {
    config_grp, league_id,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_app_configs(config_grp, success, error) {
  axios.post('/get_app_configs', {
    config_grp
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function set_app_config(config_id, config_value, success, error) {
  axios.post('/set_app_config', {
    config_id,
    config_value,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_club_promo_data(league, hostUrl, success, error) {
  axios.post('/get_club_promo_data', {
    league,
    hostUrl,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function add_user_clicks(league, banner, url, success, error) {
  axios.post('/add_user_clicks', {
    league,
    banner,
    url,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}


export function update_pga_leaderboards(pga, success, error) {
  axios.post('/update_pga_leaderboards', {
      pga,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function update_golfer_metrics(pga, success, error) {
  axios.post('/update_golfer_metrics', {
      pga,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function event_update_field(eventid, success, error) {
  axios.post('/event_update_field', {
      eventid,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function authrize_user_payment(amount, cardholder, cardNmbr, expiryDate, cvv, cardHolderState, cardHolderZip, cardNetwork, leagueId, leagueEventId, entryId, success, error) {
  axios.post('/authrize_user_payment', {
    amount, cardholder, cardNmbr, expiryDate, cvv, cardHolderState, cardHolderZip, cardNetwork, leagueId, leagueEventId, entryId,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function authrize_gpay_payment(amount, dataValue, cardNmbr, cardNetwork, leagueEventId, success, error) {
  axios.post('/authrize_gpay_payment', {
    amount,
    dataValue,
    cardNmbr,
    cardNetwork,
    leagueEventId,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function authrize_applepay_payment(amount, dataValue, cardNmbr, cardNetwork, leagueEventId, entryId, success, error) {
  axios.post('/authrize_applepay_payment', {
    amount,
    dataValue,
    cardNmbr,
    cardNetwork,
    leagueEventId,
    entryId,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function validate_applepay_merchant(validationURL, success, error) {
  axios.post('/validate_applepay_merchant', {
    validationURL,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}


export async function paypal_user_payment_order_create(amount, fundingSource, leagueId, leagueEventId, entryId) {
  try {
    const response = await axios.post('/paypal_user_payment_order_create', {
      amount,
      fundingSource,
      leagueId,
      leagueEventId,
      entryId,
    });

    console.log('Order created:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
  }
}

export async function paypal_user_payment_order_capture(userLedgerId, userOrderId, leagueId, leagueEventId, entryId) {
  try {
    const response = await axios.post('/paypal_user_payment_order_capture', {
      userLedgerId,
      userOrderId,
      leagueId,
      leagueEventId,
      entryId,
    });

    console.log('Order created:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
  }
}


export function event_update_golfer_score_card(eventid, round, success, error) {
  axios.post('/event_update_golfer_score_card', {
      eventid,
      round,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function get_club_flyer_pdf(league, clubName, callToAction, tournament, entryFee, prizes, logoBase64, hostUrl, success, error) {
  axios.post('/get_club_flyer_pdf', {
    league,
    clubName,
    callToAction,
    tournament,
    entryFee,
    prizes,
    logoBase64,
    hostUrl,
  }).then(
    (response)=>{

    /*  const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'club.pdf');
      document.body.appendChild(link);
      link.click();*/
      success(response);
    }
  ).catch(error);
}

export function get_club_flyer_doc(league, clubName, callToAction, tournament, entryFee, prizes, logoBase64, hostUrl, success, error) {
    axios.post('/get_club_flyer_doc', {
      league,
      clubName,
      callToAction,
      tournament,
      entryFee,
      prizes,
      logoBase64,
      hostUrl,
    }).then(
      (response)=>{
        success(response);
      }
    ).catch(error);

}

export function get_bannerAds(leagueGrpid, leagueid, configGrp, place, success, error) {
  axios.post('/get_bannerAds', {
    leagueGrpid,
    leagueid,
    configGrp,
    place,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function upload_bannerAds(leagueGrpid, leagueid, configGrp, imageData, fileExtension, success, error) {
  axios.post('/upload_bannerAds', {
    leagueGrpid,
    leagueid,
    configGrp,
    imageData,
    fileExtension,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function update_bannerAdProps(leagueGrpid, leagueid, configGrp, bannerid, bannerprop, bannerpropvalue, success, error) {
  axios.post('/update_bannerAdProps', {
    leagueGrpid,
    leagueid,
    configGrp,
    bannerid,
    bannerprop,
    bannerpropvalue,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function delete_bannerAds(leagueGrpid, leagueid, configGrp, bannerid, success, error) {
  axios.post('/delete_bannerAds', {
    leagueGrpid,
    leagueid,
    configGrp,
    bannerid,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function get_user_banner_clicks(leagueid, success, error) {
  axios.post('/get_user_banner_clicks', {
    leagueid,
  }).then(
    (response)=>{
      success(response.data);
    }
  ).catch(error);
}

export function verify_mc_auth_token(application, token, success, error) {
  axios.post('/verify_mc_auth_token', {
    application,
    token
  }).then((response) => success(response.data)).catch(error);

}

export function update_whitelabel(league, fld, fldVal, success, error) {
  axios.post('/update_whitelabel', {
    league,
    fld,
    fldVal
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_toc_data(league, success, error) {
  axios.post('/get_toc_data', {
      league,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function league_in_toc(league, toc, success, error) {
  axios.post('/league_in_toc', {
      league,
      toc,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function event_in_toc(league, event, toc, success, error) {
  axios.post('/event_in_toc', {
      league,
      event,
      toc,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function set_toc_fld_value(league, fld, fldVal, success, error) {
  axios.post('/set_toc_fld_value', {
    league,
    fld,
    fldVal
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_lc_data(league, contest_type, success, error) {
  axios.post('/get_lc_data', {
      league,
      contest_type,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function league_in_contest(league, contest_type, name, enrolled, success, error) {
  axios.post('/league_in_contest', {
      league,
      contest_type,
      name,
      enrolled,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function get_commiss_report(league, report, success, error) {
  axios.post('/get_commiss_report', {
      league,
      report,
    }).then(
    (response) => {
      success(response.data);
    }).catch(error);
}

export function set_lc_fld_value(league, contestid, fld, fldVal, success, error) {
  axios.post('/set_lc_fld_value', {
    league,
    contestid,
    fld,
    fldVal
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}


export function event_in_lc(league, contestid, eventid, enrolled, success, error) {
  axios.post('/event_in_lc', {
    league,
    contestid,
    eventid,
    enrolled
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function save_lc_team_golfers(league, contestid, teamid, teamname, teams, success, error) {
  axios.post('/save_lc_team_golfers', {
    league,
    contestid,
    teamid,
    teamname,
    teams
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function remove_lc_team_golfers(league, contestid, teamid, success, error) {
  axios.post('/remove_lc_team_golfers', {
    league,
    contestid,
    teamid,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function cancel_lc_team_golfers_substitute(league, contestid, teamid, success, error) {
  axios.post('/cancel_lc_team_golfers_substitute', {
    league,
    contestid,
    teamid,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function set_lc_team_owner(league, contestid, teamid, memberid, action, success, error) {
  axios.post('/set_lc_team_owner', {
    league,
    contestid,
    teamid,
    memberid,
    action,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_golf_pools(season, success, error) {
  axios.post('/get_golf_pools', {
    season,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function join_public_pool_self(leagueid, success, error) {
  axios.post('/join_public_pool_self', {
    leagueid,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function save_lc_oad_entry(leagueid, contestEventid, teams, success, error) {
  axios.post('/save_lc_oad_entry', {
    leagueid, contestEventid, teams,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}

export function get_contest_memebers_email(leagueid, contesttype, success, error) {
  axios.post('/get_contest_memebers_email', {
    leagueid, contesttype,
  }).then((response)=>{
    success(response.data);
  }).catch(error);
}


export function get_pub_league_events(leagueSlug, leaguePwd, success, error) {
  axios.post('/get_pub_league_events', {
    leagueSlug, leaguePwd,
  }).then((response) => success(response.data)).catch(error);
}

export function get_pub_leaderboards(eventid, filter, pageNo, recPerPage, round, success, error) {
  axios.post('/get_pub_leaderboards', {
    eventid, filter, pageNo, recPerPage, round,
  }).then((response) => success(response.data)).catch(error);
}
