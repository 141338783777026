import React from 'react';
import {
  Row,Col,
} from 'reactstrap';

import FontAwesome from 'react-fontawesome';

import { get_bannerAds, add_user_clicks } from 'utils/api.js';

import 'common/Banner.css';

export default class RenderBannerAds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leagueGrpId: props.leagueGrpId?props.leagueGrpId: null,
      leagueId: props.leagueId||null,
      configGrp: props.configGrp,
      banner: {},
      bannerList: [],
      randomBannerIndx:0,
      loaded: false,
    };

    this.fetchBanngerConfig = this.fetchBanngerConfig.bind(this);
    this.setRandomBannerIndx = this.setRandomBannerIndx.bind(this);
    this.addUserClicks = this.addUserClicks.bind(this);

    this.interval = setInterval(this.setRandomBannerIndx, 10*1000);
  }

  componentDidMount() {
    this.fetchBanngerConfig();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      leagueGrpId: nextProps.leagueGrpId||null,
      leagueId: nextProps.leagueId||null,
      configGrp: nextProps.configGrp,
      appPageConfig: {},
      randomBannerIndx:0,
    });
    this.fetchBanngerConfig();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setRandomBannerIndx(){
    let banner = this.state.banner;
    let bannerActiveList = this.state.bannerList.filter(x => x.status === 'A');

    if (!banner || banner.show_banner === 'N'){return;}
    if (banner && banner.show_banner === 'Y' && bannerActiveList.length === 0){return;}


    //let indx = 0;
    if (bannerActiveList.length > 1) {
      /*let i = 0;
      do {
        indx = Math.floor(Math.random() * bannerActiveList.length);
        i++;
      }while (indx === this.state.randomBannerIndx || i<3); // try max 3 times to avoid infinte loop
      */
      let indx = this.state.randomBannerIndx + 1;
      if (indx === bannerActiveList.length ){indx = 0;}

      this.setState({randomBannerIndx: indx}) ;
    }


  }

  fetchBanngerConfig() {
    if (!this.state.configGrp) {
      return;
    }

    get_bannerAds(this.state.leagueGrpId, this.state.leagueId, this.state.configGrp, this.props.place,
    ({success, banner, bannerList}) => {
      this.setState({
        banner,
        bannerList,
        loaded: true,
      });
    }, (error) => {
      console.log(error);
    });

  }

  addUserClicks(bannerImgURL, bannerClickURL) {
    window.open(bannerClickURL.indexOf('http')<0?'//'+bannerClickURL:bannerClickURL, "_blank");
    add_user_clicks(this.state.leagueId, bannerImgURL, bannerClickURL,
    ({success}) => {

    }, (error) => {
      console.log(error);
    });
  }

  stillLoading() {
    return (
        <div className=" text-center py-2">
          <FontAwesome name="spinner" size="4x" spin />
        </div>
    )
  }

  errorOccured() {
    return (
        <div className=" text-center py-5">
          {this.state.error}
        </div>
    )
  }


  render() {
    let banner = this.state.banner;
    let bannerActiveList = this.state.bannerList.filter(x => x.status === 'A');

    if (!banner || banner.show_banner === 'N'){return '';}
    if (banner.show_banner === 'Y' && bannerActiveList.length === 0 ){
      return '';
      /*return (
        <Row noGutters className="d-flex justify-content-center mt-4">
          <Col lg="7" className="p-4 card text-center">
            No Active Banners
          </Col>
        </Row>
      );*/
    }
    if (bannerActiveList.length === 0 ){return '';}

    let bannerDetails = bannerActiveList[this.state.randomBannerIndx];
    let baseURL = 'https://media.majorschallenge.com/leaderboardAdvBanner/';
    let bannerSrcFileNmDesktop = baseURL + bannerDetails.image_url;
    let bannerSrcFileNmMobile = baseURL + bannerDetails.image_url;

    let tm = 0;//new Date().getTime();
    const bannerStyleDesktop = {backgroundImage: 'url(' + bannerSrcFileNmDesktop + '?timestamp='+tm+ ')'};
    const bannerStyleMobile = {backgroundImage: 'url(' + bannerSrcFileNmMobile + '?timestamp='+tm+ ')'};

    bannerStyleDesktop['height'] = (bannerDetails.height || 70) + 'px';
    bannerStyleMobile['height'] = (bannerDetails.height || 70) + 'px';

    bannerStyleDesktop['backgroundSize'] = 'cover';
    bannerStyleMobile['backgroundSize'] = 'contain';

    let bannerClickURL = '';
    if (bannerDetails.click_url){
      bannerClickURL = bannerDetails.click_url;
      bannerStyleDesktop['cursor'] = 'pointer';
      bannerStyleMobile['cursor'] = 'pointer';
    }

    const containerClass = "d-flex justify-content-center mt-0" + (this.props.containerClass || '');
    return (
      <Row noGutters className={containerClass} style={{cursor:'pointer'}}>
        <Col className="">
          {/*<div className="d-sm-none promoBannerMobile my-0"
            style={bannerStyleMobile}
            onClick={()=> (bannerClickURL.length>3?this.addUserClicks(bannerDetails.image_url, bannerClickURL):undefined)}>
          </div>
          <div className="d-none d-sm-block promoBannerDesktop my-0"
            style={bannerStyleDesktop}
            onClick={()=> (bannerClickURL.length>3?this.addUserClicks(bannerDetails.image_url, bannerClickURL):undefined)}>
          </div>*/}
          <img src={bannerSrcFileNmDesktop} width="100%" style={{maxHeight:'150px'}} alt="Banner"
          onClick={()=> (bannerClickURL.length>3?this.addUserClicks(bannerDetails.image_url, bannerClickURL):undefined)}/>
        </Col>
      </Row>
    )
    }


}
