import React from 'react';

import {
  Row, Col,
  Container,
  ListGroup, ListGroupItem
} from 'reactstrap';

import {
  Link,
  NavLink,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';


import FontAwesome from 'react-fontawesome';

import ProfileSettings from './ProfileSettings.js'
import ProfilePastEntries from './ProfilePastEntries.js';
import ProfileLegal from './ProfileLegal.js';
import ProfileChangePassword from './ProfileChangePassword.js';
import ProfileMyLeagues from './ProfileMyLeagues.js'

//import FeedbackModal from 'common/FeedbackModal.js';
import IntercomAIModal from 'common/IntercomAIModal.js';

export const DesktopProfile = (props) => {
  return (
    <Container fluid>
      <div className="p-lg-4">
        <div className="profileTitle">My Profile</div>
        <Row className="pt-3">
          <Col lg="3">
            <DesktopProfileNav {...props} />
          </Col>
          <Col>
            <Routes isDesktop={true} {...props} />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export const MobileProfile = (props) => {
  return (
    <Routes isDesktop={false} {...props} />
  )
}

const Routes = (props) => {
  return (
    <Switch>
      <Route path="/profile/settings" render={() => <ProfileSettings {...props} />} />
      <Route path="/profile/password" render={(params) => <ProfileChangePassword {...props} {...params} /> } />
      <Route path="/profile/results" render={() => <ProfilePastEntries {...props} /> } />
      {
        props.leaguesCnt > 0 &&
        <Route path="/profile/myleagues" render={() => <ProfileMyLeagues {...props} /> } />
      }
      <Route path="/profile/legal" render={() => <ProfileLegal {...props} /> } />
      {
        props.isDesktop === true ?
        <Redirect to="/profile/settings" /> :
        <Route path="/profile" render={() => <MobileProfileMenu {...props} /> } />
      }
    </Switch>
  )
}

const DesktopProfileNav = (props) => {

  const feedback = <div className="list-group-item-action list-group-item">{'Need Help?'}</div>

  return (
    <ListGroup>
      <DesktopProfileNavItem to="/profile/settings" title="Account Settings"/>
      <DesktopProfileNavItem to="/profile/password" title="Change Password"/>
      <DesktopProfileNavItem to="/profile/results" title="Completed Events"/>
      {
        props.leaguesCnt > 0 &&
        <DesktopProfileNavItem to="/profile/myleagues" title="My Leagues"/>
      }
      <DesktopProfileNavItem to="/profile/legal" title="Legal"/>
      {/*<FeedbackModal component={feedback} />*/}
      <IntercomAIModal component={feedback} />
      <ListGroupItem action tag="a" href="#" onClick={props.handleLogout}>
        <div style={{width: "100%"}} className="d-flex justify-content-between">
          Logout
          <FontAwesome name="sign-out" style={{fontSize: "1.5rem"}}/>
        </div>
      </ListGroupItem>
    </ListGroup>
  )
}

const DesktopProfileNavItem = (props) => {
  return (
    <NavLink
      to={props.to}
      exact={true}
      className="list-group-item-action list-group-item"
      activeClassName="active">
      {props.title}
    </NavLink>
  )
}


const MobileProfileMenu = (props) => {
  return (
    <Container fluid>
      <div className="p-3">
        <div className="profileTitle">My Profile</div>
        <div className="pt-3">
          <MobileProfileNav {...props} />
        </div>
      </div>
    </Container>
  )
}

const MobileProfileNav = (props) => {

  const feedback = <div className="list-group-item-action list-group-item">{'Need Help?'}</div>

  return (
    <ListGroup>
      <MobileProfileNavItem to="/profile/settings" title="Account Settings"/>
      <MobileProfileNavItem to="/profile/password" title="Change Password"/>
      <MobileProfileNavItem to="/profile/results" title="Completed Events"/>
      {
        props.leaguesCnt > 0 &&
        <MobileProfileNavItem to="/profile/myleagues" title="My Leagues"/>
      }
      <MobileProfileNavItem to="/profile/legal" title="Legal"/>
      <IntercomAIModal component={feedback} />
      <ListGroupItem action tag="a" href="#" onClick={props.handleLogout}>
        <div style={{width: "100%"}} className="d-flex justify-content-between">
          Logout
          <FontAwesome name="sign-out" style={{fontSize: "1.25rem"}}/>
        </div>
      </ListGroupItem>
    </ListGroup>
  )
}

const MobileProfileNavItem = (props) => {
  return (
    <Link
      to={props.to}
      className="list-group-item-action list-group-item">
      <div style={{width: "100%"}} className="d-flex justify-content-between">
        {props.title}
        <FontAwesome name="chevron-right" style={{fontSize: "1rem", lineHeight: "22px"}} />
      </div>
    </Link>
  )
}
