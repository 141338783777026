import React from 'react';

import {
  Input,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col,
  //UncontrolledTooltip,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';


import {
  MobileHeader,
  ContentWrapper
} from 'common/ProfileComponents.js';

import {
  get_account_info,
  set_account_info,
  email_change,
} from 'utils/api.js';

export default class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saveSuccess: null,
      error: null,
      stateDropdownOpen: false,
      emailChangeModal: false,
      fldNm: null,
      new_email:'',
      profile_pwd: '',
    }
    this.fetchAccountInfo = this.fetchAccountInfo.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleStateDropdown = this.toggleStateDropdown.bind(this);
    this.handleStateSelect = this.handleStateSelect.bind(this);
    this.handleResidentRadio = this.handleResidentRadio.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.fetchAccountInfo(this.props.activeLeague);
  }
  fetchAccountInfo(league) {
    get_account_info(
     (success, user) => {
      this.setState({loading: false, ...user});
    }, (error) => {
      console.log(error);
    });
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  handleStateSelect(state) {
    this.setState({state})
  }
  toggleStateDropdown() {
    this.setState({stateDropdownOpen: !this.state.stateDropdownOpen})
  }
  handleResidentRadio(event) {
    const resident = event.target.id === "residentYes";
    if (this.state.resident === resident) { return; }
    this.setState({resident});

  }
  handleSubmit(event) {
    set_account_info(
      { ...this.state },
      ({success, user, me, error}) => {
        this.setState({saveSuccess: success, ...user, error});
        if (success){
          this.props.updateMe(me);
        }
      }, (error) => {
        console.log(error);
        this.setState({saveSuccess: false});
      });
  }
  renderHeader() {
    return <MobileHeader to="Account Settings" />
  }
  renderLoading() {
    return (
      <div>
        {this.renderHeader()}
      </div>
    )
  }

  toggleEmailChangeModal = () =>{
    this.setState({emailChangeModal:!this.state.emailChangeModal});
  }

  saveEmailChange = () =>{
    this.setState({fldNm:null,errorMsg:null})
     email_change(
      this.state.new_email,
      this.state.profile_pwd,
      ({success, user, me, error, fldNm}) => {
        this.setState({fldNm,errorMsg:error, profile_pwd:''});
        if (success){
          this.setState({emailChangeModal:false,...user, new_email:'', profile_pwd:''});
          this.props.updateMe(me);
        }
      }, (error) => {
        console.log(error);
        //this.setState({saveSuccess: false});
      });
  }

  render() {
    if (this.state.loading) {
      return this.renderLoading();
    }

    const states = [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District Of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ]

    const stateDropdown = <Dropdown isOpen={this.state.stateDropdownOpen} toggle={this.toggleStateDropdown}>
        <DropdownToggle color="white" className="border profileFormRow_border" outline caret>
          {this.state.state || 'Select a State'}
        </DropdownToggle>
        <DropdownMenu style={{maxHeight: '200px', overflowY: 'scroll'}}>
          <DropdownItem name="state" onClick={()=>this.handleStateSelect('')} style={{color: 'gray'}}>
            (clear)
          </DropdownItem>
          {states.map((name) =>
            <DropdownItem key={name} name="state" onClick={()=>this.handleStateSelect(name)}>
              {name !== null ? name : '(none)'}
            </DropdownItem>)}
        </DropdownMenu>
      </Dropdown>

    return (
      <div>
        {this.renderHeader()}
        <ContentWrapper>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              First name:
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <input type="text" name="first_name" value={this.state.first_name} onChange={this.handleChange} className="profileFormRow_input form-control"/>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              Last name:
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <input type="text" name="last_name" value={this.state.last_name} onChange={this.handleChange} className="profileFormRow_input form-control"/>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              Date of birth:
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <input type="text" name="dob" value={this.state.dob} placeholder="MM/DD/YYYY" onChange={this.handleChange} className="profileFormRow_input form-control"/>
            </Col>
          </Row>
          {
            this.state.account_email !== '' &&
            <Row className="profileFormRow pb-3 pb-sm-0">
              <Col sm="4" md="3" className="profileFormRow_label">
                Login email:
              </Col>
              <Col sm="8" md="6" className="profileFormRow_content">
                <span style={{color: "#94a1ab"}}>{this.state.account_email} </span>
                <span onClick={this.toggleEmailChangeModal} className="ml-2 renderComponent" style={{fontSize:'0.9rem'}}>change</span>
                {/*<span onClick={this.toggleEmailChangeModal}><FontAwesome name="pencil" className="ml-3 pencil-edit" /></span>*/}

                {/*<a href="#emailinfo" id="emailInfo"><FontAwesome name="question-circle" className="ml-3" /></a></span>
                <UncontrolledTooltip placement="right" target="emailInfo">
                  If you need to change your Login Email, please send us feedback and we'll assist.
                </UncontrolledTooltip>*/}
              </Col>
            </Row>
          }
          {
            this.state.account_email === '' &&
            <>
            <Row className="profileFormRow pb-3 pb-sm-0">
              <Col sm="4" md="3" className="profileFormRow_label">
                User Name:
              </Col>
              <Col sm="8" md="6" className="profileFormRow_content">
                <span style={{color: "#94a1ab"}}>{this.state.username} </span>
              </Col>
            </Row>
            <Row className="profileFormRow pb-3 pb-sm-0">
              <Col sm="4" md="3" className="profileFormRow_label">
                Email:
              </Col>
              <Col sm="8" md="6" className="profileFormRow_content">
                <input type="text" name="email" value={this.state.email} placeholder="Enter email" onChange={this.handleChange} className="profileFormRow_input form-control"/>
              </Col>
            </Row>
            </>
          }
          {/*
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              Venmo account:
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <input type="text" name="venmo" value={this.state.venmo} onChange={this.handleChange}
              className="profileFormRow_input form-control" maxLength="35"/>
            </Col>
          </Row>
          */}
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              USA Resident:
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content d-flex" style={{paddingLeft: '2.25rem'}}>
              <div>
                <Input type="radio" name="resident" id="residentYes" className="mr-3" value={!!this.state.resident} checked={!!this.state.resident} onChange={this.handleResidentRadio} />
                <div style={{color:"#555"}}> Yes </div>
              </div>
              <div className="ml-5">
                <Input type="radio" name="resident" id="residentNo" className="mr-3" value={!this.state.resident} checked={!this.state.resident} onChange={this.handleResidentRadio} />
                <div style={{color:"#555"}}> No </div>
              </div>
            </Col>
          </Row>
          { this.state.resident &&
            <div>
              {/*<Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  Address Line 1:
                </Col>
                <Col sm="8" md="6" className="profileFormRow_content">
                  <input type="text" name="address_line1" value={this.state.address_line1} onChange={this.handleChange} className="profileFormRow_input form-control"/>
                </Col>
              </Row>
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  Address Line 2:
                </Col>
                <Col sm="8" md="6" className="profileFormRow_content">
                  <input type="text" name="address_line2" value={this.state.address_line2} onChange={this.handleChange} className="profileFormRow_input form-control"/>
                </Col>
              </Row>
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  City:
                </Col>
                <Col sm="8" md="6" className="profileFormRow_content">
                  <input type="text" name="city" value={this.state.city} onChange={this.handleChange} className="profileFormRow_input form-control"/>
                </Col>
              </Row>*/}
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  State:
                </Col>
                <Col sm="8" md="6" className="profileFormRow_content">
                  {stateDropdown}
                </Col>
              </Row>
              {/*<Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  ZIP Code:
                </Col>
                <Col sm="8" md="6" className="profileFormRow_content">
                  <input type="text" name="zip" value={this.state.zip} onChange={this.handleChange} className="profileFormRow_input form-control"/>
                </Col>
              </Row>*/}
            </div>
          }
          <Row className="profileFormRow pt-3">
            <Col sm="4" md="3">
              <button className="btn btn-primary" onClick={this.handleSubmit}>Save Changes</button>
            </Col>
            { this.state.saveSuccess === true &&
              <Col style={{color: "#94a1ab"}}>
                Changes saved.
              </Col>
            }
            { this.state.saveSuccess === false &&
              <Col className="text-danger">
                { this.state.error || 'Error occurred saving.' }
              </Col>
            }
          </Row>
          <Row className="profileFormRow pt-2">
            <Col>
              <div className='text-muted small d-none d-sm-block'>To be eligible for free contests you must provide your date of birth and state of residence.</div>
              <div className='text-muted small d-none d-sm-block'>Providing false or incomplete information will disqualify you from winning contest prizes.</div>

              <div className='text-muted small d-sm-none'>To be eligible for free contests you must provide your date of birth and state of residence. Providing false or incomplete information will disqualify you from winning contest prizes.</div>
            </Col>
          </Row>

          <Modal isOpen={this.state.emailChangeModal}  autoFocus={true} className="" style={{minWidth:'550px'}}>
            <ModalHeader toggle={this.toggleEmailChangeModal}>Email Change</ModalHeader>
            <ModalBody>
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  Current Email:
                </Col>
                <Col sm="8" md="9" className="profileFormRow_content">
                  {this.state.account_email}
                </Col>
              </Row>
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  New Email:
                </Col>
                <Col sm="8" md="9" className="profileFormRow_content">
                  <input type="text" name="new_email" value={this.state.new_email} onChange={this.handleChange} className="profileFormRow_input form-control"/>
                </Col>
              </Row>
              {(this.state.errorMsg && this.state.fldNm === 'new_email') &&
              <Row className="mt-n1 pb-sm-0">
                <Col sm="4" md="3" className=""></Col>
                <Col sm="8" md="9" className="">
                    <span className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
                      {this.state.errorMsg}
                    </span>
                </Col>
              </Row>
              }
              <Row className="profileFormRow pb-3 pb-sm-0">
                <Col sm="4" md="3" className="profileFormRow_label">
                  Password:
                </Col>
                <Col sm="8" md="9" className="profileFormRow_content">
                  <input type="password" name="profile_pwd" value={this.state.profile_pwd} onChange={this.handleChange} className="profileFormRow_input form-control"/>
                </Col>
              </Row>
              {(this.state.errorMsg && this.state.fldNm === 'profile_pwd') &&
              <Row className="mt-n1 pb-sm-0">
                <Col sm="4" md="3" className=""></Col>
                <Col sm="8" md="9" className="">
                    <span className="pt-2 pb-0" style={{fontSize: ".85rem", color: "red"}}>
                      {this.state.errorMsg}
                    </span>
                </Col>
              </Row>
              }
              <Row className="profileFormRow pt-3 justify-content-end">
                <Col>
                  <button className="btn btn-primary" onClick={this.saveEmailChange}>Save Changes</button>
                </Col>
              </Row>

            </ModalBody>
          </Modal>

        </ContentWrapper>
      </div>
    )
  }
}
